import React, { useState, useContext, useEffect } from "react";
import { FormControl } from "@mui/material";
import Controls from "../../../core/formControl/Controls";
import { Box } from "@material-ui/core";
import { makeStyles } from "@mui/styles";
import { createStyles } from "@material-ui/core";
import axios from "../../../../axios";
import useNotification from "../../../core/snakeBar";
import { useForm, Form } from "../../../core/formControl/useForm";
import FileUploader from "../../../core/formControl/fileUpload";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "../../../core/Button";
import moment from "moment";
import { AuthContext } from "../../../../state/Auth";
import CircularProgress from "@mui/material/CircularProgress";

const useStyles = makeStyles(() =>
  createStyles({
    ImageNamesView: {
      opacity: 0.4,
      paddingLeft: "38px",
    },
    selectedImages: {
      // color: " #000000",
      border: "1px solid #7070701A ",
      height: "44px",
      borderRadius: "32px",
      textTransform: "capitalize",
      width: "160px",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      paddingLeft: "20px",
    },
    DialogRoot: {
      // background: "red !important",
      padding: "3rem 4rem",
      width: "900px",
      // width:''
      background: "#FFFFFF 0% 0% no-repeat padding-box",
      boxShadow: "5px 5px 44px #00000029",
      borderRadius: "10px",
      opacity: "1",
    },
    TableColrow: {
      margin: " 0px 16px",
    },
    TableCol: {
      display: "flex",
      // flexDirection: "Column",
      margin: "0 auto",
      justifyContent: "space-between",
      padding: "0 20px",
    },
    TableCol2: {
      display: "flex",
      flexWrap: "wrap",
      margin: "0 auto",
      justifyContent: "space-around",
    },
    StartLotDiv: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    StartLot: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "162px",
      height: "32px",
      background: "#55815C 0% 0% no-repeat padding-box",
      borderRadius: "8px",
      cursor: "pointer",
      color: "#fff",
    },
    AccordionRoot: {
      backgroundColor: "#6CD3A0",
      borderRadius: "16px !important ",
      border: " 2px solid #d9e1e7 !important",
      boxShadow: "none !important",
      position: "inherit !important",
    },
    Para: {
      color: "#809FB8",
      letterSpacing: "0.26px",
      textAlign: "left",
      marginBottom: "-25px",
    },
    inputDoller: {
      top: "38px",
      left: "13px",
      position: "absolute",
      zIndex: 111,
      fontWeight: 900,
    },
  })
);
const biomassAmountType = [
  {
    id: "Kg",
    name: "Kg",
  },
  {
    id: "g",
    name: "g",
  },
];
const harvestTypeOption = [
  {
    id: "Wet/Dry",
    name: "Wet/Dry",
  },
  {
    id: "Dry Harvest",
    name: "Dry Harvest",
  },
];
const AmountType = [
  {
    id: "Kg",
    name: "Kg",
  },
  {
    id: "Lps",
    name: "Lps",
  },
  {
    id: "G",
    name: "G",
  },
];
const YesNo = [
  {
    id: true,
    name: "yes",
  },
  {
    id: false,
    name: "No",
  },
];
const CorF = [
  {
    id: "C",
    name: "°C",
  },
  {
    id: "F",
    name: "°F",
  },
];
const DryingTypeOption = [
  {
    id: "Dehydration",
    name: "Dehydration",
  },
  {
    id: "Temperature",
    name: "Temperature",
  },
];
// Dehydration

const LeafColour = [
  {
    id: "Green",
    name: "Green",
  },
  {
    id: "Light Green",
    name: "Light Green",
  },
  {
    id: "Yellow",
    name: "Yellow",
  },
];
export default function CustomForm(props) {
  const {
    week2only,
    detailIndex,
    DetailsData,
    summary,
    floweronly,
    harvest,
    vegitative,
    lotsId,
    status,
  } = props;
  const [, sendNotification] = useNotification();
  const classes = useStyles();
  const [user, dispatch] = useContext(AuthContext);
  const [HarvestId, setHarvestId] = useState("");
  const [TimerDiable, setTimerDiable] = useState(true);
  const [AddTextFeild, setAddTextFeild] = useState(false);
  const [AddTextFeild2, setAddTextFeild2] = useState(false);
  const [SelectedImage, setSelectedImage] = useState(null);
  const [SelectedImageSrc, setSelectedImageSrc] = useState("");
  const [SelectedPdf, setSelectedPdf] = useState(null);
  const [SelectedPdfSrc, setSelectedPdfSrc] = useState("");
  const [IsSubmitting, setIsSubmitting] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [NutritionArray, setNutritionArray] = useState(
    DetailsData ? DetailsData.NutritionArray : []
  );
  const [DailyHarvestArray, setDailyHarvestArray] = useState([]);
  const [Progress, setProgress] = useState("");

  const [StateDisable, setStateDisable] = useState(false);
  const [QRImage, setQRImage] = useState("");
  useEffect(() => {
    console.log("status", status, vegitative, floweronly);
    if (status == "Curing" && status == "Summary") {
      setStateDisable(true);
    }
    //
  }, [detailIndex, DetailsData]);
  useEffect(() => {
    // console.log("daily dailyHarvest");
    if (DetailsData && DetailsData.vegitativeId) {
      axios
        .get(`nutritions/${DetailsData ? DetailsData.vegitativeId : "no"}`)
        .then((res) => {
          setNutritionArray(res.data);
        })
        // .then(setDetailsDataLoading(true))
        .catch((err) =>
          // console.log(
          //   DetailsData,
          //   `dailyHarvest/${DetailsData ? DetailsData.id : "noid"}`
          // ),
          console.log(`nutritions/${DetailsData ? DetailsData.id : "noid"}`)
        );
    }
    // console.log(NutritionArray, "NutritionArrayNutritionArray");
  }, [detailIndex, DetailsData]);

  useEffect(() => {
    console.log("daily dailyHarvest");
    axios
      .get(`dailyHarvest/${DetailsData ? DetailsData.harvestId : "noid"}`)
      .then((res) => {
        const data = res.data;
        console.log(data, "daily harvestdd");
        setDailyHarvestArray(res.data);
        // alert("123");
        // setGerminationData(res.data);
        // setGDetailsDataLoading(true);
      })
      // .then(setDetailsDataLoading(true))
      .catch(
        (err) => (
          console.log(
            DetailsData,
            `dailyHarvest/${DetailsData ? DetailsData.harvestId : "noid"}`
          ),
          console.log("daily harvestdd error")
        )
      );
    axios
      .get(`nutritions/${DetailsData ? DetailsData.vegitativeId : "noid"}`)
      .then((res) => {
        const data = res.data;
        console.log(
          data,
          "nutritions/",
          `${DetailsData ? DetailsData.id : "noid"}`
        );
        setNutritionArray(res.data);
        // alert("123");
        // setGerminationData(res.data);
        // setGDetailsDataLoading(true);
      })
      // .then(setDetailsDataLoading(true))
      .catch(
        (err) => (
          console.log(
            DetailsData,
            `dailyHarvest/${DetailsData ? DetailsData.id : "noid"}`
          ),
          console.log(`nutritions/${DetailsData ? DetailsData.id : "noid"}`)
        )
      );
  }, [detailIndex]);
  useEffect(() => {
    console.log(convert(new Date()), "date format");
    // const date = convert(values.trackTypeEndDate);
    //
    if (DetailsData) {
      // setup time
      console.log(TimerDiable, "/TimerDiable deatil data true");

      if (new Date().getHours() == moment("12:00:00 PM", "h A").format("HH")) {
        setTimerDiable(true);
      } else {
        setTimerDiable(false);
      }
    } else {
      setTimerDiable(false);
      console.log(TimerDiable, "/TimerDiable !DetailsData");
    }
  }, [DetailsData]);
  useEffect(() => {
    console.log("one ");
    if (DetailsData && DetailsData.image) {
      console.log("one two", DetailsData.image);
      setSelectedImageSrc(DetailsData.image);
      // setSelectedPdfSrc(DetailsData.filenames);
    }
    if (DetailsData && DetailsData.filenames) {
      setSelectedPdfSrc(DetailsData.filenames);
    }
  }, [DetailsData]);

  DetailsData && console.log(DetailsData, "/=data");
  var initialFValues = {
    id: DetailsData ? DetailsData.lotId : "",
    trackType: DetailsData ? (DetailsData.onSchedule === 0 ? "No" : "yes") : "",
    correctAmount: DetailsData
      ? DetailsData.correctAmount === 0
        ? "No"
        : "yes"
      : "",
    newWeight: DetailsData ? DetailsData.amount : "",
    weight: DetailsData ? DetailsData.amount : "",
    rootColour: DetailsData ? DetailsData.rootColor : "",
    stalkOrStemHealth: DetailsData ? DetailsData.healthOfStem : "",
    mildew: DetailsData ? (DetailsData.mildew === 0 ? "No" : "yes") : "",
    phWater: DetailsData ? DetailsData.phWater : "",
    leafColor: DetailsData ? DetailsData.leavesColor : "",
    soil: DetailsData ? DetailsData.soil : "",
    deviatingSOP: DetailsData ? (DetailsData.sop === 0 ? "No" : "yes") : "",
    NutritionTypes: DetailsData ? DetailsData.nutrition : "",
    liquidValue: DetailsData ? DetailsData.nutritionMeasure : "",
    solidValue: DetailsData ? DetailsData.nutritionMeasure : "",
    trackTypeEndDate: DetailsData ? DetailsData.id : "",
    fileUpload: null,
    fileUpload2: null,
    // nutritionAmount:
    harvestType: DetailsData ? DetailsData.harvestType : "",
    biomassRemoved: DetailsData ? DetailsData.biomassAmount : "",
    biomassAmountTypeweight: DetailsData ? DetailsData.biomassMeasure : "",
    destroyBiomass: DetailsData ? DetailsData.biomassDestroy : "",
    dryingType: DetailsData ? DetailsData.dryingType : "",
    dehydration: "",
    temperature: "",
    humidity: "",
    processing: DetailsData
      ? DetailsData.sugarLeavingProcess === 0
        ? "No"
        : "yes"
      : "",
    harvestId: "",
    username: DetailsData ? DetailsData.userName : "",
    colouPistils: DetailsData ? DetailsData.pistilsColor : "",
    Tricholms: DetailsData ? DetailsData.tricholmsColor : "",
    densityOfNugs: DetailsData ? DetailsData.nugsDensity : "",
    colourOfFlower: DetailsData ? DetailsData.flowerColor : "",
    fanLeafHealth: DetailsData ? DetailsData.fanLeafHealth : "",
    noTracking: DetailsData ? DetailsData.scheduleReason : "",
    yesSop: DetailsData ? DetailsData.sopReason : "",
  };
  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("fullName" in fieldValues)
      temp.fullName = fieldValues.fullName ? "" : "This field is required.";
    if ("email" in fieldValues)
      temp.email = /$^|.+@.+..+/.test(fieldValues.email)
        ? ""
        : "Email is not valid.";
    if ("mobile" in fieldValues)
      temp.mobile =
        fieldValues.mobile.length > 9 ? "" : "Minimum 10 numbers required.";
    if ("departmentId" in fieldValues)
      temp.departmentId =
        fieldValues.departmentId.length !== 0 ? "" : "This field is required.";
    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange } = useForm(
    initialFValues,
    true,
    validate
  );
  // console.log(axios.defaults.baseURL, "===");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      Post3(values);
    }
    // console.log(values);
  };
  function convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }
  const data = new FormData();
  const data2 = new FormData();
  const data3 = new FormData();

  // harvest
  const TodayDate = convert(new Date());

  const Post3 = (values) => {
    const data = new FormData();

    data.append("name", values.pname);
    data.append("price", values.pprice);
    data.append("quantity", values.pquantity);
    data.append("lotId", detailIndex);
    // lotId
    if (SelectedImage != null) {
      data.append("image", SelectedImage);
      console.log(SelectedImage, "SelectedImage print");
    }

    axios
      // detailIndex
      .post(`/product`, data, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(
        async (res) => {
          console.log(res.data, axios.defaults.baseURL + res.data.qrImage);
          setQRImage(axios.defaults.baseURL + res.data.qrImage);
          setOpen(true);
          setIsSubmitting(true);
          // setOpen(true);
        }
        // resetForm()
      )
      .catch((error) => {
        console.error("There was an error!", error);
        sendNotification({ msg: "There was an error!", variant: "error" });
      });
  };
  // harvest dring Type
  const Post3dryingType = (values) => {
    console.log("Post3dryingType dailyHarvest");
    // console.log(convert(new Date()),"date format")
    // const date = convert(values.trackTypeEndDate);
    // console.log(values.trackTypeEndDate);
    const TodayDate = convert(new Date());
    date: new Date();
    // moment("8:00:00 AM", "h A").format("HH")
    const Details = JSON.stringify({
      week: "13",
      humidity: values.humidity,
      temperature: values.temperature,
      harvestId: HarvestId
        ? HarvestId
        : DetailsData
        ? DetailsData.harvestId
        : "",
      date: TodayDate,

      humidityUnit: values.Shumidity,
      temperatureUnit: values.Stemperature,
    });
    console.log(Details, "harvest details");
    axios
      .post(`/dailyHarvest`, Details, {
        headers: { "Content-Type": "application/json" },
      })
      .then(
        (res) => (
          setOpen(true),
          console.log(res, "harvest datares ponswe", user.user.id),
          setIsSubmitting(true),
          console.log(res.data, "flowerdata", Details, "flowerdata details")
        )
        // resetForm()
      )
      .catch((error) => {
        console.error("There was an error!", error);
        sendNotification({ msg: "There was an error!", variant: "error" });
      });
    console.log(Details, "flowerdata details");
  };
  const onImageClick = () => {
    // console.log(SelectedImageSrc);
    // console.log(axios.defaults.baseURL);
    window.open(
      SelectedImageSrc
        ? `${axios.defaults.baseURL}images/${SelectedImageSrc}`
        : ""
    );
  };

  const addTextfeildF = () => {
    console.log("rrr");
    setAddTextFeild(true);
  };
  const addTextfeildOff = () => {
    console.log("uuuu");

    setAddTextFeild(false);
  };
  const addTextfeildF2 = () => {
    console.log("rrr");
    setAddTextFeild2(true);
  };
  const addTextfeildOff2 = () => {
    console.log("uuuu");
    setAddTextFeild2(false);
  };
  const AddNutInput = () => {
    if (!DetailsData) {
      setNutritionArray([
        ...NutritionArray,
        {
          // newData: "",
          name: "",
          description: "",
          amount: "",
          date: "2002-02-22",
          time: "",
        },
      ]);
    }
  };
  const handleNutritionArrayChange = (e, index) => {
    // NutritionArray[index] = e.target.value;
    // setNutritionArray((...NutritionArray = e.target.value));
    // function FormDataMock() {
    //   this.append = jest.fn();
    // }

    // global.FormData = FormDataMock;
    const { name, value } = e.target;
    const list = [...NutritionArray];
    list[index][name] = value;
    setNutritionArray(list);
  };
  const handleClose = () => {
    setOpen(false);
  };
  console.log(1 < Progress && Progress != 100, Progress, "processs");
  const onPdfClick = () => {
    window.open(
      SelectedImageSrc ? `${axios.defaults.baseURL}files/${SelectedPdfSrc}` : ""
    );
  };
  const download = (e) => {
    console.log(e.target.href, QRImage);
    fetch(QRImage, {
      method: "GET",
      headers: {},
    })
      .then((response) => {
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "image.png"); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <Form onSubmit={handleSubmit}>
      <div className={classes.TableCol}>
        <div>
          <Controls.Input
            name="pname"
            label="Name"
            value={values.pname}
            onChange={handleInputChange}
            error={errors.pname}
            disabled={(DetailsData ? true : false) || IsSubmitting}
            maxWidth="186px !important"
            required="required"
          />
        </div>
        <div style={{ position: "relative" }}>
          <span className={classes.inputDoller}>$ </span>
          <Controls.Input
            name="pprice"
            label="MSRP"
            value={values.pprice}
            onChange={handleInputChange}
            error={errors.pprice}
            disabled={(DetailsData ? true : false) || IsSubmitting}
            maxWidth="186px !important"
            required="required"
            type="number"
          />
        </div>
        <div style={{ position: "relative" }}>
          <Controls.Input
            name="pquantity"
            label="Quantity"
            value={values.pquantity}
            onChange={handleInputChange}
            error={errors.pquantity}
            disabled={(DetailsData ? true : false) || IsSubmitting}
            maxWidth="186px !important"
            required="required"
          />
        </div>
      </div>
      <div className={classes.TableCol}>
        {
          <div>
            {/* {progress && <ProgressBar now={progress} label={`${progress}%`} />} */}
            <br />
            {/* {JSON.stringify(1 < Progress < 99)} */}
            {1 < Progress && Progress != 100 && (
              <p style={{ margin: "-5px 4px -70px 168px" }}>
                <CircularProgress color="success" value={Progress} />
              </p>
            )}{" "}
            <div style={{ marginTop: "22px" }}>
              {SelectedImageSrc === "" && (
                <FileUploader
                  // onChange={handleUploadChange}
                  onChange={(event) => {
                    console.log(event.target.files[0]);
                    setSelectedImage(event.target.files[0]);
                    data.append("images", event.target.files[0]);
                  }}
                  name="fileUpload"
                  id="fileUpload"
                  accept="image/*"
                  placeholder={
                    SelectedImage == null
                      ? Number(Progress) === 100
                        ? SelectedImage
                          ? SelectedImage.name
                          : "change image"
                        : " Upload Product Image"
                      : Number(Progress) === 100
                      ? SelectedImage
                        ? SelectedImage.name
                        : "change image"
                      : SelectedImage
                      ? SelectedImage.name
                      : " change images"
                  }
                />
              )}
            </div>
            {SelectedImage && SelectedImage.length > 0 && (
              <div>
                {/* {Object.entries(SelectedImage).map(([item, it], i) => (
                <div className={classes.ImageNamesView}>
                  {it.name}
                  {/* <p onClick={() => DeleteImages(item, it)}>delete</p> */}
                {/* <p>{JSON.stringify(it.name)}</p> */}
                {/* </div> */}
                {/* // ))} */}
              </div>
            )}
            {SelectedImageSrc != "" && (
              <div className={classes.selectedImages}>
                <a download onClick={() => onImageClick()}>
                  View Image
                </a>
              </div>
            )}
            {/* {setSelectedImageSrc} */}
            <div style={{ marginTop: "22px" }}>
              {Progress && SelectedImageSrc === "" && Progress != 100 && (
                <p style={{ margin: "-5px 4px -48px  168px" }}>
                  <CircularProgress color="success" value={Progress} />
                </p>
              )}
            </div>
          </div>
        }
      </div>
      <div className={classes.TableCol}>
        {/* QR IMAGE */}
        {/* <a
          download
          href="https://staketapi01.moodfor.codes/qrcodes/Product_RushFruit_1655270288102.png"
          target="_blank"
          title="QR Code"
          style={{ width: "300px" }}
          onClick={e => download(e)}
        >
          <img
            style={{ width: "100%" }}
            src="https://staketapi01.moodfor.codes/qrcodes/Product_RushFruit_1655270288102.png"
            alt="qr code"
          />
        </a> */}
        {QRImage && (
          <div style={{ width: "300px" }}>
            <a
              download
              href={QRImage}
              style={{ width: "300px" }}
              onClick={(e) => download(e)}
              target="_blank"
            >
              <img style={{ width: "100%" }} src={QRImage} alt="qr code" />
            </a>
            <br />
            <br />
            <br />
          </div>
        )}
      </div>
      <div className={classes.StartLotDiv}>
        <Controls.Button
          type="submit"
          text="Complete Week and Genarate QR code "
          className={classes.StartLot}
          color="#55815C"
          width="400px"
          height="88px"
          lineHeight="1.3"
          // disabled={(DetailsData ? false : false) || IsSubmitting}
        >
          {/* <div className={classes.StartLot}></div> */}
        </Controls.Button>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        classes={{ paper: classes.DialogRoot }}
      >
        <form
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <DialogTitle
            sx={{
              margin: "0 important",
              padding: "0 important",
              color: "#3C9061",
              fontSize: "40px",
              margin: "0 auto",
            }}
          >
            Week
          </DialogTitle>
          <DialogContent sx={{ margin: "0 important", padding: "0 important" }}>
            <DialogContentText>
              {/* To subscribe to this website, please enter your email address
                here. We will send updates occasionally. */}
            </DialogContentText>

            <DialogContentText
              sx={{
                opacity: "1",
                paddingTop: "20px",
                textTransform: "capitalize",
                fontWeight: 600,
                color: "#000000",
                fontSize: "20px",
              }}
            >
              {/* {summary && <p> {summary} Data Was Created Successfully!</p>} */}
              {harvest && <p> Week Data Was Created Successfully!</p>}
              {!harvest && <p> Week Data Was Created Successfully!</p>}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              text="Cancel"
              onClick={handleClose}
              height="78px"
              radius="34px"
            >
              Cancel
            </Button>
            <Button text="Okay" height="78px" onClick={handleClose}>
              Okay
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </Form>
  );
}
