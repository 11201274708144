import React from "react";
import { makeStyles } from "@mui/styles";
import { createStyles } from "@material-ui/core";
// import Accordions from "../../../../core/accordionCore";
import AccordionsGrowth from "../../../../core/accordionGrowth";
import InputTable1 from "../weekTable/week1table";
// import InputTable1 from "../weekTable/week1table";
import InputTable2 from "../weekTable/week2table";
import InputTable3 from "../weekTable/curingTable";
// import Summary1 from "../weekTable/summary/analyteTable";
import Summary2 from "../weekTable/summary/potancyTable";
// import Summary3 from "../weekTable/summary/terpenes";

// Summary1
const useStyles = makeStyles(() =>
  createStyles({
    TableCol: {
      display: "flex",
      margin: "0 auto",
      justifyContent: "space-between",
    },
    StartLotDiv: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
    },
    StartLot: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "162px",
      height: "32px",
      background: "#55815C 0% 0% no-repeat padding-box",
      borderRadius: "8px",
      cursor: "pointer",
      color: "#fff",
    },

    StartLot1: {
      color: "#fff",

      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "162px",
      height: "32px",
      background: "#55815C96 0% 0% no-repeat padding-box;",
      borderRadius: "8px",
      cursor: "pointer",
      margin: "0 10px 0 0px",
    },

    StartLot2: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "162px",
      height: "32px",
      background: "#68B174 0% 0% no-repeat padding-box",
      borderRadius: "8px",
      color: "#fff",
      cursor: "pointer",
    },
    AccordionRoot: {
      backgroundColor: "#6CD3A0",
      position: "inherit !important",
      borderRadius: "16px !important ",
      border: "none !important",
      boxShadow: "none !important",
    },
  })
);

export default function Germination({ detailIndex }) {
  console.log(detailIndex, "detailIndexdetailIndexdetailIndex");
  const classes = useStyles();
  const Germination = [
    {
      summary: "week 1",
      details: <InputTable1 />,
    },
  ];
  const Vegitative = [
    {
      summary: "week 2",
      details: <InputTable2 vegitative week2only />,
    },
    {
      summary: "week 3",
      details: <InputTable2 vegitative />,
    },
    {
      summary: "week 4",
      details: <InputTable2 vegitative />,
    },
    {
      summary: "week 5",
      details: <InputTable2 vegitative />,
    },
    {
      summary: "week 6",
      details: <InputTable2 vegitative />,
    },
  ];
  const Flower = [
    {
      summary: "week 7",
      details: <InputTable2 floweronly />,
    },
    {
      summary: "week 8",
      details: <InputTable2 floweronly />,
    },
    {
      summary: "week 9",
      details: <InputTable2 floweronly />,
    },
    {
      summary: "week 10",
      details: <InputTable2 floweronly />,
    },
    {
      summary: "week 11",
      details: <InputTable2 floweronly />,
    },
    {
      summary: "week 12",
      details: <InputTable2 floweronly />,
    },
  ];
  const Harvest = [
    {
      summary: "week 13",
      details: <InputTable2 harvest />,
    },
  ];
  const Curing = [
    {
      summary: "week 14",
      details: <InputTable3 detailIndex={detailIndex} />,
    },
  ];
  // const Package = [
  //   {
  //     summary: "week ",
  //     details: <InputTable2 />,
  //   },
  // ];
  // const Shipping = [
  //   {
  //     summary: "week ",
  //     details: <InputTable2 />,
  //   },
  // ];
  // const Retailer = [
  //   {
  //     summary: "week",
  //     details: <InputTable2 />,
  //   },
  // ];
  // const Consumer = [
  //   {
  //     summary: "week 2",
  //     details: <InputTable2 />,
  //   },
  //   {
  //     summary: "week 3",
  //     details: <InputTable2 />,
  //   },
  // ];

  const Summary = [
    // {
    //   summary: "Analyte",
    //   details: <Summary1 />,
    // },
    {
      summary: "Potency",
      details: <Summary2 />,
    },
    // {
    //   summary: "Terpene",
    //   details: <Summary3 />,
    // },
  ];
  return (
    <div classes={{ root: classes.AccordionRoot }}>
      <AccordionsGrowth
        summary="Germination"
        detailData={Germination}
        noAddWeek="true"
      />
      <AccordionsGrowth summary="Vegitative" detailData={Vegitative} open />
      <AccordionsGrowth summary="Flower" detailData={Flower} open />
      <AccordionsGrowth summary="Harvest" detailData={Harvest} open />
      <AccordionsGrowth summary="Curing" detailData={Curing} open />
      {/* <AccordionsGrowth summary="Package" detailData={Package} open />
      <AccordionsGrowth summary="Shipping" detailData={Shipping} open />
      <AccordionsGrowth summary="Retailer" detailData={Retailer} open />
      <AccordionsGrowth summary="Consumer" detailData={Consumer} open /> */}
      <AccordionsGrowth summary="Summary" detailData={Summary} noAddWeek open />
    </div>
  );
}
