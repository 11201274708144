import React, { useState, useContext, useEffect } from "react";
import { FormControl } from "@mui/material";
import Controls from "../../../../core/formControl/Controls";
import { Box } from "@material-ui/core";
import { makeStyles } from "@mui/styles";
import { createStyles } from "@material-ui/core";
import axios from "../../../../../axios";
import useNotification from "../../../../core/snakeBar";
import { useForm, Form } from "../../../../core/formControl/useForm";
import FileUploader from "../../../../core/formControl/fileUpload";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "../../../../core/Button";
import moment from "moment";
import { AuthContext } from "../../../../../state/Auth";
import CircularProgress from "@mui/material/CircularProgress";

const useStyles = makeStyles(() =>
  createStyles({
    ImageNamesView: {
      opacity: 0.4,
      paddingLeft: "38px",
    },
    selectedImages: {
      // color: " #000000",
      border: "1px solid #7070701A ",
      height: "44px",
      borderRadius: "32px",
      textTransform: "capitalize",
      width: "160px",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      paddingLeft: "20px",
    },
    DialogRoot: {
      // background: "red !important",
      padding: "3rem 4rem",
      width: "900px",
      // width:''
      background: "#FFFFFF 0% 0% no-repeat padding-box",
      boxShadow: "5px 5px 44px #00000029",
      borderRadius: "10px",
      opacity: "1",
    },
    TableColrow: {
      margin: " 0px 16px",
    },
    TableCol: {
      display: "flex",
      // flexDirection: "Column",
      margin: "0 auto",
      justifyContent: "space-between",
    },
    TableCol2: {
      display: "flex",
      flexWrap: "wrap",
      margin: "0 auto",
      justifyContent: "space-around",
    },
    StartLotDiv: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    StartLot: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "162px",
      height: "32px",
      background: "#55815C 0% 0% no-repeat padding-box",
      borderRadius: "8px",
      cursor: "pointer",
      color: "#fff",
    },
    AccordionRoot: {
      backgroundColor: "#6CD3A0",
      borderRadius: "16px !important ",
      border: " 2px solid #d9e1e7 !important",
      boxShadow: "none !important",
      position: "inherit !important",
    },
    Para: {
      color: "#809FB8",
      letterSpacing: "0.26px",
      textAlign: "left",
      marginBottom: "-25px",
    },
  })
);
const biomassAmountType = [
  {
    id: "Kg",
    name: "Kg",
  },
  {
    id: "g",
    name: "g",
  },
];
const harvestTypeOption = [
  {
    id: "Wet/Dry",
    name: "Wet/Dry",
  },
  {
    id: "Dry Harvest",
    name: "Dry Harvest",
  },
];
const AmountType = [
  {
    id: "Kg",
    name: "Kg",
  },
  {
    id: "Lps",
    name: "Lps",
  },
  {
    id: "G",
    name: "G",
  },
];
const YesNo = [
  {
    id: true,
    name: "yes",
  },
  {
    id: false,
    name: "No",
  },
];
const CorF = [
  {
    id: "C",
    name: "°C",
  },
  {
    id: "F",
    name: "°F",
  },
];
const DryingTypeOption = [
  {
    id: "Dehydration",
    name: "Dehydration",
  },
  {
    id: "Temperature",
    name: "Temperature",
  },
];
// Dehydration

const LeafColour = [
  {
    id: "Green",
    name: "Green",
  },
  {
    id: "Light Green",
    name: "Light Green",
  },
  {
    id: "Yellow",
    name: "Yellow",
  },
];
export default function CustomForm(props) {
  const {
    week2only,
    detailIndex,
    DetailsData,
    summary,
    floweronly,
    harvest,
    vegitative,
    lotsId,
    status,
  } = props;
  const [, sendNotification] = useNotification();
  const classes = useStyles();
  const [user, dispatch] = useContext(AuthContext);
  const [HarvestId, setHarvestId] = useState("");
  const [TimerDiable, setTimerDiable] = useState(true);
  const [AddTextFeild, setAddTextFeild] = useState(false);
  const [AddTextFeild2, setAddTextFeild2] = useState(false);
  const [SelectedImage, setSelectedImage] = useState(null);
  const [SelectedImageSrc, setSelectedImageSrc] = useState("");
  const [SelectedPdf, setSelectedPdf] = useState(null);
  const [SelectedPdfSrc, setSelectedPdfSrc] = useState("");
  const [IsSubmitting, setIsSubmitting] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [NutritionArray, setNutritionArray] = useState(
    DetailsData ? DetailsData.NutritionArray : []
  );
  const [DailyHarvestArray, setDailyHarvestArray] = useState([]);
  const [Progress, setProgress] = useState("");

  const [StateDisable, setStateDisable] = useState(false);

  useEffect(() => {
    console.log("status", status, vegitative, floweronly);
    if (status == "Curing" && status == "Summary") {
      setStateDisable(true);
    }
    //
  }, [detailIndex, DetailsData]);
  useEffect(() => {
    // console.log("daily dailyHarvest");
    if (DetailsData && DetailsData.vegitativeId) {
      axios
        .get(`nutritions/${DetailsData ? DetailsData.vegitativeId : "no"}`)
        .then((res) => {
          setNutritionArray(res.data);
        })
        // .then(setDetailsDataLoading(true))
        .catch((err) =>
          // console.log(
          //   DetailsData,
          //   `dailyHarvest/${DetailsData ? DetailsData.id : "noid"}`
          // ),
          console.log(`nutritions/${DetailsData ? DetailsData.id : "noid"}`)
        );
    }
    // console.log(NutritionArray, "NutritionArrayNutritionArray");
  }, [detailIndex, DetailsData]);

  useEffect(() => {
    console.log("daily dailyHarvest");
    axios
      .get(`dailyHarvest/${DetailsData ? DetailsData.harvestId : "noid"}`)
      .then((res) => {
        const data = res.data;
        console.log(data, "daily harvestdd");
        setDailyHarvestArray(res.data);
        // alert("123");
        // setGerminationData(res.data);
        // setGDetailsDataLoading(true);
      })
      // .then(setDetailsDataLoading(true))
      .catch(
        (err) => (
          console.log(
            DetailsData,
            `dailyHarvest/${DetailsData ? DetailsData.harvestId : "noid"}`
          ),
          console.log("daily harvestdd error")
        )
      );
    axios
      .get(`nutritions/${DetailsData ? DetailsData.vegitativeId : "noid"}`)
      .then((res) => {
        const data = res.data;
        console.log(
          data,
          "nutritions/",
          `${DetailsData ? DetailsData.id : "noid"}`
        );
        setNutritionArray(res.data);
        // alert("123");
        // setGerminationData(res.data);
        // setGDetailsDataLoading(true);
      })
      // .then(setDetailsDataLoading(true))
      .catch(
        (err) => (
          console.log(
            DetailsData,
            `dailyHarvest/${DetailsData ? DetailsData.id : "noid"}`
          ),
          console.log(`nutritions/${DetailsData ? DetailsData.id : "noid"}`)
        )
      );
  }, [detailIndex]);
  useEffect(() => {
    console.log(convert(new Date()), "date format");
    // const date = convert(values.trackTypeEndDate);
    //
    if (DetailsData) {
      // setup time
      console.log(TimerDiable, "/TimerDiable deatil data true");

      if (new Date().getHours() == moment("12:00:00 PM", "h A").format("HH")) {
        setTimerDiable(true);
      } else {
        setTimerDiable(false);
      }
    } else {
      setTimerDiable(false);
      console.log(TimerDiable, "/TimerDiable !DetailsData");
    }
  }, [DetailsData]);
  useEffect(() => {
    console.log("one ");
    if (DetailsData && DetailsData.image) {
      console.log("one two", DetailsData.image);
      setSelectedImageSrc(DetailsData.image);
      // setSelectedPdfSrc(DetailsData.filenames);
    }
    if (DetailsData && DetailsData.filenames) {
      setSelectedPdfSrc(DetailsData.filenames);
    }
  }, [DetailsData]);

  DetailsData && console.log(DetailsData, "/=data");
  var initialFValues = {
    id: DetailsData ? DetailsData.lotId : "",
    trackType: DetailsData ? (DetailsData.onSchedule === 0 ? "No" : "yes") : "",
    correctAmount: DetailsData
      ? DetailsData.correctAmount === 0
        ? "No"
        : "yes"
      : "",
    newWeight: DetailsData ? DetailsData.amount : "",
    weight: DetailsData ? DetailsData.amount : "",
    rootColour: DetailsData ? DetailsData.rootColor : "",
    stalkOrStemHealth: DetailsData ? DetailsData.healthOfStem : "",
    mildew: DetailsData ? (DetailsData.mildew === 0 ? "No" : "yes") : "",
    phWater: DetailsData ? DetailsData.phWater : "",
    leafColor: DetailsData ? DetailsData.leavesColor : "",
    soil: DetailsData ? DetailsData.soil : "",
    deviatingSOP: DetailsData ? (DetailsData.sop === 0 ? "No" : "yes") : "",
    NutritionTypes: DetailsData ? DetailsData.nutrition : "",
    liquidValue: DetailsData ? DetailsData.nutritionMeasure : "",
    solidValue: DetailsData ? DetailsData.nutritionMeasure : "",
    trackTypeEndDate: DetailsData ? DetailsData.id : "",
    fileUpload: null,
    fileUpload2: null,
    // nutritionAmount:
    harvestType: DetailsData ? DetailsData.harvestType : "",
    biomassRemoved: DetailsData ? DetailsData.biomassAmount : "",
    biomassAmountTypeweight: DetailsData ? DetailsData.biomassMeasure : "",
    destroyBiomass: DetailsData ? DetailsData.biomassDestroy : "",
    dryingType: DetailsData ? DetailsData.dryingType : "",
    dehydration: "",
    temperature: "",
    humidity: "",
    processing: DetailsData
      ? DetailsData.sugarLeavingProcess === 0
        ? "No"
        : "yes"
      : "",
    harvestId: "",
    username: DetailsData ? DetailsData.userName : "",
    colouPistils: DetailsData ? DetailsData.pistilsColor : "",
    Tricholms: DetailsData ? DetailsData.tricholmsColor : "",
    densityOfNugs: DetailsData ? DetailsData.nugsDensity : "",
    colourOfFlower: DetailsData ? DetailsData.flowerColor : "",
    fanLeafHealth: DetailsData ? DetailsData.fanLeafHealth : "",
    noTracking: DetailsData ? DetailsData.scheduleReason : "",
    yesSop: DetailsData ? DetailsData.sopReason : "",
  };
  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("fullName" in fieldValues)
      temp.fullName = fieldValues.fullName ? "" : "This field is required.";
    if ("email" in fieldValues)
      temp.email = /$^|.+@.+..+/.test(fieldValues.email)
        ? ""
        : "Email is not valid.";
    if ("mobile" in fieldValues)
      temp.mobile =
        fieldValues.mobile.length > 9 ? "" : "Minimum 10 numbers required.";
    if ("departmentId" in fieldValues)
      temp.departmentId =
        fieldValues.departmentId.length !== 0 ? "" : "This field is required.";
    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange } = useForm(
    initialFValues,
    true,
    validate
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      console.log(values, "values");
      if (harvest && !DetailsData) {
        Post3(values);
      } else if (harvest && DetailsData.id) {
        // DetailsData.id
        console.log("begine", TimerDiable);
        if (TimerDiable) {
          Post3dryingType(values);
        }
        Updating(values);
      }
      // employeeService.insertEmployee(values);
      // resetForm();
    }
    // console.log(values);
  };
  function convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }
  const data = new FormData();
  const data2 = new FormData();
  const data3 = new FormData();

  // harvest
  const TodayDate = convert(new Date());

  const Post3 = (values) => {
    console.log("post3");
    const date = convert(values.trackTypeEndDate);
    console.log(values.trackTypeEndDate);
    const Details = JSON.stringify({
      onSchedule: values.trackType,
      correctAmount: values.correctAmount,
      harvestType: values.harvestType,
      biomassMeasure: values.biomassAmountTypeweight,
      biomassAmount: values.biomassRemoved,
      biomassDestroy: values.destroyBiomass,
      dryingType: values.dryingType,
      lotId: `${detailIndex}`,
      sugarLeavingProcess: values.processing,
      dehydration: values.dehydration,
      userName: values.username,
      scheduleReason: values.noTracking,
// userId:
      // harvestType
    });
    const data = new FormData();
    data.append(
      "onSchedule",
      JSON.stringify(values.trackType) === "true" ? 1 : 0
    );
    data.append(
      "correctAmount",
      JSON.stringify(values.correctAmount) === "true" ? 1 : 0
    );
    data.append("harvestType", values.harvestType);
    data.append("biomassMeasure", values.biomassAmountTypeweight);
    data.append("biomassAmount", values.biomassRemoved);
    data.append("biomassDestroy", values.destroyBiomass);
    data.append("dryingType", values.dryingType);
    data.append("lotId", detailIndex);
    data.append(
      "sugarLeavingProcess",
      JSON.stringify(values.processing) === "true" ? 1 : 0
    );
    data.append("dehydration", values.dehydration);
    data.append("userName", values.username);
    data.append("scheduleReason", values.noTracking);
    if (SelectedImage != null) {
      data.append("image", SelectedImage);
      console.log(SelectedImage, "SelectedImage print");
    }

    const data2 = new FormData();
    if (SelectedPdf != null) {
      data2.append("filenames", SelectedPdf);
    }
    axios
      // detailIndex
      .post(`/harvest`, data, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(
        async (res) => {
          if (values.fileUpload != "" && res.data.id) {
            await axios
              // detailIndex
              .put(`/harvest/${res.data.id}`, data2, {
                headers: { "Content-Type": "multipart/form-data" },
              })
              .then((res) => {
                console.log(res, "ok done vegitative/update");
              })
              .then((res) => {
                // setOpen(true);
                // setIsSubmitting(true);
              })
              .catch(
                (err) => (
                  // sendNotification({
                  //   msg: "There was an error1!",
                  //   variant: "error",
                  // }),
                  console.log(err.response, "err"),
                  console.log("err"),
                  console.log("err", res.data.id)
                )
              );
          }
          // setOpen(true);
          axios
            // detailIndex
            .put(
              `/updateStatus/${lotsId}`,
              // status
              { status: "Harvest" },
              {
                headers: { "Content-Type": "application/json" },
              }
            )
            .then((res) => {
              console.log(res, "ok done");
            })
            .then((res) => {
              setOpen(true);
              setIsSubmitting(true);
            })
            .catch(
              (err) => (
                sendNotification({
                  msg: "There was an error!",
                  variant: "error",
                }),
                console.log(err.response, "err"),
                console.log("err")
              )
            );

          const Details2 = JSON.stringify({
            week: "13",
            humidity: values.humidity,
            temperature: values.temperature,
            harvestId: res.data
              ? res.data.id
              : DetailsData
              ? DetailsData.harvestId
              : "",
            date: TodayDate,
            humidityUnit: values.Shumidity,
            temperatureUnit: values.Stemperature,
          });
          axios
            .post(`/dailyHarvest`, Details2, {
              headers: { "Content-Type": "application/json" },
            })
            .then(
              (res) => (
                setOpen(true),
                console.log(res, "harvest datares ponswe", user.user.id),
                console.log(
                  res.data,
                  "flowerdata",
                  Details2,
                  "flo,werdata details2"
                ),
                setIsSubmitting(true)
              )
              // resetForm()
            )
            .catch((error) => {
              console.error("There was an error!", error);
              sendNotification({
                msg: "There was an error!",
                variant: "error",
              });
            });
          console.log(res, "harvest datares ponswe", user.user.id);
          console.log(res.data, "harvest data", Details2, "harvest details2");
          setHarvestId(res.data.harvestId);
          setTimerDiable(true);
        }
        // resetForm()
      )
      .catch((error) => {
        console.error("There was an error!", error);
        sendNotification({ msg: "There was an error!", variant: "error" });
      });
    console.log(Details, "flowerdata details");
  };
  // updateing
  const Updating = (values) => {
    console.log("Updating dailyHarvest");
    // console.log(convert(new Date()),"date format")
    // const date = convert(values.trackTypeEndDate);
    // console.log(values.trackTypeEndDate);
    const TodayDate = convert(new Date());
    date: new Date();
    // moment("8:00:00 AM", "h A").format("HH")
    const Details = {
      onSchedule: values.trackType === "true" ? 1 : 0,
      correctAmount: values.correctAmount === "true" ? 1 : 0,
      harvestType: values.harvestType,
      biomassMeasure: values.biomassAmountTypeweight,
      biomassAmount: values.biomassRemoved,
      biomassDestroy: values.destroyBiomass,
      dryingType: values.dryingType,
      lotId: `${detailIndex}`,
      sugarLeavingProcess: values.processing === "true" ? 1 : 0,
      dehydration: values.dehydration,
      userName: values.username,
      scheduleReason: values.noTracking,

      // harvestType
    };
    console.log(Details, "harvest details");
    axios
      .put(`/harvest/` + DetailsData.harvestId, Details, {
        headers: { "Content-Type": "application/json" },
      })
      .then(
        (res) => (
          setOpen(true),
          console.log(res, "harvest datares ponswe", user.user.id),
          setIsSubmitting(true),
          console.log(res.data, "flowerdata", Details, "flowerdata details")
        )
        // resetForm()
      )
      .catch((error) => {
        console.error("There was an error!", error);
        sendNotification({ msg: "There was an error!", variant: "error" });
      });
    console.log(Details, "flowerdata details");
  };

  // harvest dring Type

  const Post3dryingType = (values) => {
    console.log("Post3dryingType dailyHarvest");
    // console.log(convert(new Date()),"date format")
    // const date = convert(values.trackTypeEndDate);
    // console.log(values.trackTypeEndDate);
    const TodayDate = convert(new Date());
    date: new Date();
    // moment("8:00:00 AM", "h A").format("HH")
    const Details = JSON.stringify({
      week: "13",
      humidity: values.humidity,
      temperature: values.temperature,
      harvestId: HarvestId
        ? HarvestId
        : DetailsData
        ? DetailsData.harvestId
        : "",
      date: TodayDate,

      humidityUnit: values.Shumidity,
      temperatureUnit: values.Stemperature,
    });
    console.log(Details, "harvest details");
    axios
      .post(`/dailyHarvest`, Details, {
        headers: { "Content-Type": "application/json" },
      })
      .then(
        (res) => (
          setOpen(true),
          console.log(res, "harvest datares ponswe", user.user.id),
          setIsSubmitting(true),
          console.log(res.data, "flowerdata", Details, "flowerdata details")
        )
        // resetForm()
      )
      .catch((error) => {
        console.error("There was an error!", error);
        sendNotification({ msg: "There was an error!", variant: "error" });
      });
    console.log(Details, "flowerdata details");
  };
  const onImageClick = () => {
    // console.log(SelectedImageSrc);
    // console.log(axios.defaults.baseURL);
    window.open(
      SelectedImageSrc
        ? `${axios.defaults.baseURL}images/${SelectedImageSrc}`
        : ""
    );
  };

  const addTextfeildF = () => {
    console.log("rrr");
    setAddTextFeild(true);
  };
  const addTextfeildOff = () => {
    console.log("uuuu");

    setAddTextFeild(false);
  };
  const addTextfeildF2 = () => {
    console.log("rrr");
    setAddTextFeild2(true);
  };
  const addTextfeildOff2 = () => {
    console.log("uuuu");
    setAddTextFeild2(false);
  };
  const AddNutInput = () => {
    if (!DetailsData) {
      setNutritionArray([
        ...NutritionArray,
        {
          // newData: "",
          name: "",
          description: "",
          amount: "",
          date: "2002-02-22",
          time: "",
        },
      ]);
    }
  };
  const handleNutritionArrayChange = (e, index) => {
    // NutritionArray[index] = e.target.value;
    // setNutritionArray((...NutritionArray = e.target.value));
    // function FormDataMock() {
    //   this.append = jest.fn();
    // }

    // global.FormData = FormDataMock;
    const { name, value } = e.target;
    const list = [...NutritionArray];
    list[index][name] = value;
    setNutritionArray(list);
  };
  const handleClose = () => {
    setOpen(false);
  };
  console.log(1 < Progress && Progress != 100, Progress, "processs");
  const onPdfClick = () => {
    window.open(
      SelectedImageSrc ? `${axios.defaults.baseURL}files/${SelectedPdfSrc}` : ""
    );
  };
  return (
    <Form onSubmit={handleSubmit}>
      <div className={classes.TableCol}>
        <div>
          {/* {JSON.stringify(DetailsData)} */}
          <div>
            <Controls.Select
              name="trackType"
              //           Is Expected Weight Correct ?
              placeholder="Are You On Track ?           "
              value={values.trackType}
              onChange={handleInputChange}
              options={YesNo}
              error={errors.trackType}
              // disabled={(DetailsData ? true : false) || IsSubmitting}
              width={DetailsData && "266px "}
              // required="required"
            />
          </div>
          {/* values.trackType:: {JSON.stringify(values.trackType)} */}
          {values.trackType === false && (
            <div>
              <Controls.Input
                // disabled={DetailsData ? true : false}
                //  sooththa musuttu poda sunni
                name="noTracking"
                label="Reason ?"
                value={values.noTracking}
                onChange={handleInputChange}
                error={errors.noTracking}
                maxWidth="186px !important"
              />
            </div>
          )}
          {DetailsData && values.trackType == "No" && (
            <div>
              <Controls.Input
                // disabled={DetailsData ? true : false}
                name="noTracking"
                label="Reason ?"
                value={values.noTracking}
                onChange={handleInputChange}
                error={errors.noTracking}
                maxWidth="186px !important"
              />
            </div>
          )}
          <div>
            {/* {values.correctAmount} */}
            <Controls.Select
              placeholder="Is Expected Weight Correct ?"
              name="correctAmount"
              label="Is Expected Weight Correct ?"
              value={values.correctAmount}
              onChange={handleInputChange}
              options={YesNo}
              error={errors.correctAmount}
              // disabled={(DetailsData ? true : false) || IsSubmitting}
              // required="required"
            />
          </div>
          {values.correctAmount === "false" && (
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Box sx={{ display: "flex" }}>
                <Controls.Input
                  // disabled={(DetailsData ? true : false) || IsSubmitting}
                  width="20px"
                  name="newWeight"
                  // label="Amount"
                  value={values.newWeight}
                  onChange={handleInputChange}
                  error={errors.newWeight}
                  type="number"
                />
                <FormControl
                  sx={{
                    m: 1,
                    minWidth: "50%",
                    minHeight: 68,
                    margin: 0,
                    display: "flex",
                    justyContent: "center",
                  }}
                >
                  <div
                    style={{
                      marginTop: "23px",
                      minWidth: "50px",
                      padding: "2px",
                    }}
                  >
                    <Controls.Select
                      width="80px !important"
                      name="weight"
                      placeholder="Amount"
                      // label="Amount"
                      value={values.weight}
                      onChange={handleInputChange}
                      options={AmountType}
                      error={errors.weight}
                      // disabled={(DetailsData ? true : false) || IsSubmitting}
                      paddingTop
                    />
                  </div>
                </FormControl>
              </Box>
            </Box>
          )}

          <div>
            <div>
              <Controls.Select
                options={harvestTypeOption}
                // disabled={(DetailsData ? true : false) || IsSubmitting}
                name="harvestType"
                placeholder="Harvest Type"
                value={values.harvestType}
                onChange={handleInputChange}
                error={errors.harvestType}
                required="required"
              />
            </div>
            <div>
              {values.harvestType === "Wet/Dry" && (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Box sx={{ display: "flex" }}>
                      <Controls.Input
                        // disabled={DetailsData ? true : false}
                        name="biomassRemoved"
                        label="How much Biomass is removed"
                        value={values.biomassRemoved}
                        onChange={handleInputChange}
                        error={errors.biomassRemoved}
                        maxWidth="250px !important"
                      />
                      <FormControl
                        sx={{
                          m: 1,
                          // minWidth: "50%",
                          minHeight: 68,
                          margin: 0,
                          display: "flex",
                          justyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            marginTop: "23px",
                            minWidth: "50px",
                            padding: "2px 2px 2px 28px",
                          }}
                        >
                          <Controls.Select
                            width="80px !important"
                            name="biomassAmountTypeweight"
                            placeholder="Amount"
                            // label="Amount"
                            value={values.biomassAmountTypeweight}
                            onChange={handleInputChange}
                            options={biomassAmountType}
                            error={errors.biomassAmountTypeweight}
                            // disabled={
                            //   (DetailsData ? true : false) || IsSubmitting
                            // }
                            paddingTop
                          />
                        </div>
                      </FormControl>
                    </Box>
                  </Box>
                  <div>
                    <Controls.Input
                      // disabled={DetailsData ? true : false}
                      name="destroyBiomass"
                      label="How did you destroy biomass?"
                      value={values.destroyBiomass}
                      onChange={handleInputChange}
                      error={errors.destroyBiomass}
                      maxWidth="250px !important"
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* AddTextFeild */}
        {harvest && DailyHarvestArray && DailyHarvestArray.length > 0 && (
          <div>
            <div style={{ display: "flex" }}>
              <div>
                {!AddTextFeild && (
                  <Controls.Input
                    disabled={!TimerDiable}
                    name="humidity1"
                    label="humidity "
                    value={values.humidity_2}
                    onChange={handleInputChange}
                    error={errors.humidity}
                    maxWidth="120px !important"
                    type="number"
                  />
                )}
                {AddTextFeild && (
                  <div style={{ display: "flex" }}>
                    {/* testing  */}
                    <Controls.Input
                      disabled={!TimerDiable}
                      name="temperature"
                      label="Temperature testing "
                      value={values.temperature}
                      onChange={handleInputChange}
                      error={errors.temperature}
                      maxWidth="120px !important"
                      type="number"
                      required="required"
                    />
                    <span style={{ padding: "0px 0px 0px 36px" }}>
                      <Controls.Select
                        // CorF
                        options={CorF}
                        disabled={!TimerDiable}
                        name="Stemperature"
                        placeholder="Unit"
                        value={values.Stemperature}
                        onChange={handleInputChange}
                        error={errors.Stemperature}
                        width="100px"
                      />
                    </span>
                  </div>
                )}
              </div>
              <div style={{ margin: "24px 0px 0px 32px" }}>
                <Controls.SelectFun
                  placeholder1="Unit"
                  options={DailyHarvestArray}
                  // disabled={!TimerDiable}
                  editableText={AddTextFeild}
                  name="humidity_2"
                  placeholder="Select Date"
                  value={values.humidity_2}
                  onChange={handleInputChange}
                  error={errors.humidity_2}
                  width="180px"
                  paddingTop="24px"
                  addTextfeild={addTextfeildF}
                  addTextfeildOff={addTextfeildOff}
                />
                {/* <Controls.SelectFun2
                  options={DailyHarvestArray}
                  // disabled={!TimerDiable}
                  name="humidity_2"
                  placeholder="Select Date"
                  value={values.humidity_2}
                  onChange={handleInputChange}
                  error={errors.humidity_2}
                  width="180px"
                  paddingTop
                  addTextfeild={addTextfeildF2}
                  addTextfeildOff={addTextfeildOff2}
                /> */}
              </div>
            </div>
            {/* current working here */}
            <div style={{ display: "flex" }}>
              <div style={{ display: "flex" }}>
                {!AddTextFeild2 && (
                  <Controls.Input
                    disabled={!TimerDiable}
                    name="tempera1"
                    label="humidity "
                    value={values.temperature_2}
                    onChange={handleInputChange}
                    error={errors.humidity}
                    maxWidth="120px !important"
                    type="number"
                  />
                )}
                {AddTextFeild2 && (
                  <>
                    <Controls.Input
                      disabled={!TimerDiable}
                      name="humidity"
                      label="Humidity tesing"
                      value={values.humidity}
                      onChange={handleInputChange}
                      error={errors.humidity}
                      maxWidth="120px !important"
                      type="number"
                    />
                    <span style={{ padding: "0px 0px 0px 38px" }}>
                      <Controls.Select
                        // CorF
                        options={CorF}
                        disabled={!TimerDiable}
                        name="Shumidity"
                        placeholder="Unit"
                        value={values.Shumidity}
                        onChange={handleInputChange}
                        error={errors.Shumidity}
                        width="100px"
                      />
                    </span>
                  </>
                )}
              </div>
              <div style={{ margin: "24px 0px 0px 32px" }}>
                <Controls.SelectFun2
                  placeholder1="Unit"
                  options={DailyHarvestArray}
                  // disabled={!TimerDiable}
                  name="temperature_2"
                  placeholder="Select Date"
                  placeholderunit="unit"
                  value={values.temperature_2}
                  onChange={handleInputChange}
                  error={errors.temperature_2}
                  width="180px"
                  paddingTop
                  addTextfeild={addTextfeildF2}
                  addTextfeildOff={addTextfeildOff2}
                  editableText={AddTextFeild2}
                />
              </div>
            </div>

            {/*   */}
          </div>
        )}
        {harvest && DailyHarvestArray.length < 1 && (
          <div>
            <div>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Box sx={{ display: "flex" }}>
                  <Controls.Input
                    disabled={!(DailyHarvestArray.length < 1)}
                    name="temperature"
                    label="Temperature"
                    value={values.temperature}
                    onChange={handleInputChange}
                    error={errors.temperature}
                    maxWidth="auto"
                    type="number"
                  />
                  <FormControl
                    sx={{
                      m: 1,
                      // minWidth: "50%",
                      minHeight: 68,
                      margin: 0,
                      display: "flex",
                      justyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        marginTop: "0px",
                        minWidth: "120px",
                        padding: "2px 2px 2px 32px",
                      }}
                    >
                      <Controls.Select
                        // CorF
                        options={CorF}
                        disabled={!(DailyHarvestArray.length < 1)}
                        name="Stemperature"
                        placeholder="Unit"
                        value={values.Stemperature}
                        onChange={handleInputChange}
                        error={errors.Stemperature}
                        width="100px"
                      />
                    </div>
                  </FormControl>
                </Box>
              </Box>
            </div>
            <div>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Box sx={{ display: "flex" }}>
                  <Controls.Input
                    disabled={!(DailyHarvestArray.length < 1)}
                    name="humidity"
                    label="Humidity "
                    value={values.humidity}
                    onChange={handleInputChange}
                    error={errors.humidity}
                    maxWidth="auto"
                    type="number"
                    // humidity
                  />
                  <FormControl
                    sx={{
                      m: 1,
                      // minWidth: "50%",
                      minHeight: 68,
                      margin: 0,
                      display: "flex",
                      justyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        marginTop: "0px",
                        minWidth: "120px",
                        padding: "2px 2px 2px 32px",
                      }}
                    >
                      <Controls.Select
                        // CorF
                        options={CorF}
                        disabled={!(DailyHarvestArray.length < 1)}
                        name="Shumidity"
                        placeholder="Unit"
                        value={values.Shumidity}
                        onChange={handleInputChange}
                        error={errors.Shumidity}
                        width="100px"
                      />
                    </div>
                  </FormControl>
                </Box>
              </Box>
            </div>
            <div>
              <Controls.Select
                options={YesNo}
                // disabled={(DetailsData ? true : false) || IsSubmitting}
                name="processing"
                placeholder=" Sugar leave processing"
                value={values.processing}
                onChange={handleInputChange}
                error={errors.processing}
              />
            </div>
            {/*   */}
          </div>
        )}

        <div>
          <div>
            <div>
              <div>
                <Controls.Select
                  options={DryingTypeOption}
                  // disabled={DetailsData ? true : false}
                  name="dryingType"
                  placeholder="Drying Type"
                  value={values.dryingType}
                  onChange={handleInputChange}
                  error={errors.dryingType}
                  required="required"
                />
              </div>
              {/* <div> 
              <Controls.Select
                options={YesNo}
                disabled={DetailsData ? true : false}
                name="dehydration"
                // placeholder="Dehydration"
                value={values.dehydration}
                onChange={handleInputChange}
                error={errors.dehydration}
              />
            </div> */}
            </div>

            <Controls.Input
              name="username"
              label="username"
              value={values.username}
              onChange={handleInputChange}
              error={errors.username}
              // disabled={(DetailsData ? true : false) || IsSubmitting}
              maxWidth="186px !important"
              required="required"
            />
          </div>{" "}
        </div>
      </div>
      <div className={classes.TableCol}>
        {
          <div>
            {/* {progress && <ProgressBar now={progress} label={`${progress}%`} />} */}
            <br />
            {/* {JSON.stringify(1 < Progress < 99)} */}
            {1 < Progress && Progress != 100 && (
              <p style={{ margin: "-5px 4px -70px 168px" }}>
                <CircularProgress color="success" value={Progress} />
              </p>
            )}{" "}
            <div style={{ marginTop: "22px" }}>
              {SelectedImageSrc === "" && (
                <FileUploader
                  // onChange={handleUploadChange}
                  onChange={(event) => {
                    console.log(event.target.files[0]);
                    setSelectedImage(event.target.files[0]);
                    data.append("images", event.target.files[0]);
                  }}
                  name="fileUpload"
                  id="fileUpload"
                  accept="image/*"
                  placeholder={
                    SelectedImage == null
                      ? Number(Progress) === 100
                        ? SelectedImage
                          ? SelectedImage.name
                          : "change image"
                        : " images"
                      : Number(Progress) === 100
                      ? SelectedImage
                        ? SelectedImage.name
                        : "change image"
                      : SelectedImage
                      ? SelectedImage.name
                      : " change images"
                  }
                />
              )}
            </div>
            {SelectedImage && SelectedImage.length > 0 && (
              <div>
                {/* {Object.entries(SelectedImage).map(([item, it], i) => (
                <div className={classes.ImageNamesView}>
                  {it.name}
                  {/* <p onClick={() => DeleteImages(item, it)}>delete</p> */}
                {/* <p>{JSON.stringify(it.name)}</p> */}
                {/* </div> */}
                {/* // ))} */}
              </div>
            )}
            {SelectedImageSrc != "" && (
              <div className={classes.selectedImages}>
                <a download onClick={() => onImageClick()}>
                  View Image
                </a>
              </div>
            )}
            {/* {setSelectedImageSrc} */}
            <div style={{ marginTop: "22px" }}>
              {Progress && SelectedImageSrc === "" && Progress != 100 && (
                <p style={{ margin: "-5px 4px -48px  168px" }}>
                  <CircularProgress color="success" value={Progress} />
                </p>
              )}
              {SelectedPdfSrc === "" && (
                <FileUploader
                  // onChange={handleUploadChange}
                  onChange={(event) => {
                    console.log(event.target.files[0]);
                    setSelectedPdf(event.target.files[0]);
                    data2.append("filenames", event.target.files[0]);
                  }}
                  name="fileUpload2"
                  id="fileUpload2"
                  accept="application/pdf,application/vnd.ms-excel"
                  placeholder={
                    SelectedPdf == null
                      ? Number(Progress) === 100
                        ? SelectedPdf
                          ? SelectedPdf.name
                          : "change pdf"
                        : " pdfs"
                      : Number(Progress) === 100
                      ? "change pdfs "
                      : SelectedPdf
                      ? SelectedPdf.name
                      : " change pdfs"
                  }
                />
              )}
            </div>
            {SelectedPdf && SelectedPdf.length > 0 && (
              <div>
                {Object.entries(SelectedPdf).map(([item, it], i) => (
                  <div className={classes.ImageNamesView}>
                    {it.name}
                    {/* <p>{JSON.stringify(it.name)}</p> */}
                  </div>
                ))}
              </div>
            )}
            {SelectedPdfSrc != "" && (
              <div className={classes.selectedImages}>
                <a onClick={() => onPdfClick()}>View PDF </a>
              </div>
            )}
          </div>
        }
      </div>
      <div className={classes.StartLotDiv}>
        <Controls.Button
          type="submit"
          text={IsSubmitting || DetailsData ? " Update" : "Complete Week"}
          className={classes.StartLot}
          color="#55815C"
          // disabled={
          //   ((!TimerDiable)
          // }
        >
          {/* <div className={classes.StartLot}></div> */}
        </Controls.Button>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        classes={{ paper: classes.DialogRoot }}
      >
        <form
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <DialogTitle
            sx={{
              margin: "0 important",
              padding: "0 important",
              color: "#3C9061",
              fontSize: "40px",
              margin: "0 auto",
            }}
          >
            Week
          </DialogTitle>
          <DialogContent sx={{ margin: "0 important", padding: "0 important" }}>
            <DialogContentText>
              {/* To subscribe to this website, please enter your email address
                here. We will send updates occasionally. */}
            </DialogContentText>

            <DialogContentText
              sx={{
                opacity: "1",
                paddingTop: "20px",
                textTransform: "capitalize",
                fontWeight: 600,
                color: "#000000",
                fontSize: "20px",
              }}
            >
              {/* {summary && <p> {summary} Data Was Created Successfully!</p>} */}
              {harvest && (
                <p>
                  Week Data Was
                  {DetailsData ? " Updated " : "Created Successfully!"}
                </p>
              )}
              {!harvest && (
                <p>
                  Week Data Was
                  {DetailsData ? " Updated " : "Created Successfully!"}
                </p>
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              text="Cancel"
              onClick={handleClose}
              height="78px"
              radius="34px"
            >
              Cancel
            </Button>
            <Button text="Okay" height="78px" onClick={handleClose}>
              Okay
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </Form>
  );
}
