import React, { useState, useContext } from "react";
import { FormControl } from "@mui/material";
import Controls from "../../../../core/formControl/Controls";
import { Box } from "@material-ui/core";
import { makeStyles } from "@mui/styles";
import { createStyles } from "@material-ui/core";
import axios from "../../../../../axios";
import useNotification from "../../../../core/snakeBar";
import { useForm, Form } from "../../../../core/formControl/useForm";
import { AuthContext } from "../../../../../state/Auth";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "../../../../core/Button";
import moment from "moment";

const useStyles = makeStyles(() =>
  createStyles({
    DialogRoot: {
      // background: "red !important",
      padding: "3rem 4rem",
      width: "900px",
      // width:''
      background: "#FFFFFF 0% 0% no-repeat padding-box",
      boxShadow: "5px 5px 44px #00000029",
      borderRadius: "10px",
      opacity: "1",
    },
    TableCol: {
      display: "flex",
      // flexDirection: "Column",
      margin: "0 auto",
      justifyContent: "space-between",
    },
    StartLotDiv: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    StartLot: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "162px",
      height: "32px",
      background: "#55815C 0% 0% no-repeat padding-box",
      borderRadius: "8px",
      cursor: "pointer",
      color: "#fff",
    },
    AccordionRoot: {
      backgroundColor: "#6CD3A0",
      borderRadius: "16px !important ",
      border: " 2px solid #d9e1e7 !important",
      boxShadow: "none !important",
      position: "inherit !important",
    },
    Para: {
      color: "#809FB8",
      letterSpacing: "0.26px",
      textAlign: "left",
      marginBottom: "-25px",
    },
  })
);
const CannabisType = [
  {
    id: "Hybrid",
    name: "Hybrid",
  },
  {
    id: "Indica",
    name: "Indica",
  },
  {
    id: "Sativa",
    name: "Sativa",
  },
];
const growingMethod = [
  {
    id: "Airoponics",
    name: "Airoponics",
  },
  {
    id: "Hydroponics",
    name: "Hydroponics",
  },
  {
    id: "G",
    name: "Soil",
  },
  {
    id: "Other",
    name: "Other",
  },
];
const Amount = [
  {
    id: "Kg",
    name: "Kg",
  },
  {
    id: "Lps",
    name: "Lps",
  },
  {
    id: "G",
    name: "G",
  },
];
const organicNutritionOptions = [
  {
    id: "Rockwool",
    name: "Rockwool",
  },
  {
    id: "Worm Casting",
    name: "Worm Casting",
  },
  {
    id: "Good Bac/Fungi",
    name: "Good Bac/Fungi",
  },
  {
    id: "Forest Humus",
    name: "Forest Humus",
  },
  {
    id: "Fish Meal",
    name: "Fish Meal",
  },
  {
    id: "Blood Meal",
    name: "Blood Meal",
  },
  {
    id: "Compost Tea",
    name: "Compost Tea",
  },
  {
    id: "Biochar",
    name: "Biochar",
  },
  {
    id: "Other",
    name: "Other",
  },
];
const seedOrClone = [
  {
    id: "seed",
    name: "seeds",
  },
  {
    id: "clones",
    name: "Clones",
  },
];

export default function CustomForm({
  GerminationData,
  detailIndex,
  GerminationDatasInfos,
  GerminationDatesInfos,
  lotsId,
}) {
  const [open, setOpen] = React.useState(false);
  // const [GerminationDatasInfo, setGerminationDatasInfo] = useState([]);
  const [IsSubmitting, setIsSubmitting] = useState(false);
  console.log(
    GerminationDatasInfos,
    GerminationDatesInfos,
    " GerminationDatesInfos22"
  );
  // useEffect(() => {
  //   console.log(GerminationDatesInfos, "/GerminationDatesInfos/data");
  //   console.log(GerminationDatasInfos, "/GerminationDatesInfos/data");
  //   axios
  //     .get(`germination/${GerminationData ? GerminationData.id : "noid"}`)
  //     .then((res) => {
  //       const data = res.data;
  //       console.log(
  //         data[0],
  //         `germination/${GerminationData ? GerminationData.id : ""}`
  //       );
  //       setGerminationDatasInfo(res.data);
  //     })
  //     // .then(setDetailsDataLoading(true))
  //     .catch((err) => console.log("/=error"));
  // }, [detailIndex, GerminationData]);

  const handleClose = () => {
    setOpen(false);
  };
  // useEffect(() => {
  //   console.log(GerminationDatasInfo[0], "type");
  //   console.log("001GerminationDatasInfo");
  //   if (GerminationDatasInfo[0]) {
  //     console.log(GerminationDatasInfo[0].type, "type in use effect");
  //     initialFValues.type = GerminationDatasInfo[0].type
  //       ? GerminationDatasInfo[0].type
  //       : "nooo type";
  //   }
  // }, [GerminationDatasInfo]);

  console.log(open, "/open");
  const [user, dispatch] = useContext(AuthContext);
  console.log(GerminationData, "GerminationData");
  // console.log(GerminationDatasInfo[0], "GerminationDatasInfo");
  const initialFValues = {
    id: GerminationData ? GerminationData.id : "",
    lotId: GerminationData ? GerminationData.lotId : "",
    batchId: GerminationData ? GerminationData.batchId : "",
    grower: GerminationData ? GerminationData.grower : "",
    type: GerminationData
      ? GerminationDatasInfos[0]
        ? GerminationDatasInfos[0].type
        : ""
      : "",
    seed: GerminationData
      ? GerminationDatasInfos[0]
        ? GerminationDatasInfos[0].seed
        : ""
      : "",
    username: GerminationData
      ? GerminationDatasInfos[0]
        ? GerminationDatasInfos[0].userName
        : ""
      : "",
    // username b
    strain: GerminationData ? GerminationData.strainName : "",
    growingMethod: GerminationData
      ? GerminationDatasInfos[0]
        ? GerminationDatasInfos[0].growingMethod
        : ""
      : "",
    organicNutrition: GerminationData
      ? GerminationDatasInfos[0]
        ? GerminationDatasInfos[0].organicNutrition
        : ""
      : "",
    amount: GerminationData ? GerminationData.amount : "",
    weight: GerminationData ? GerminationData.measure : "",
    seedOrCloneName: GerminationData
      ? GerminationDatasInfos[0]
        ? GerminationDatasInfos[0].seedType
        : ""
      : "",
    usingSeed: GerminationData
      ? GerminationDatasInfos[0]
        ? GerminationDatasInfos[0].seedNumbers
        : ""
      : "",
    motherPlantId: GerminationData
      ? GerminationDatasInfos[0]
        ? GerminationDatasInfos[0].motherPlantId
        : ""
      : "",
    numberOfClones: GerminationData
      ? GerminationDatasInfos[0]
        ? GerminationDatasInfos[0].cloneNumbers
        : ""
      : "",
    newnumberOfClones: "",
    //               {moment(item.date).format("YYYY-MM-DD")}

    vegDate: GerminationData
      ? GerminationDatasInfos[0]
        ? moment(GerminationDatasInfos[0].vegDate).format("YYYY-MM-DD")
        : ""
      : convert(new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000)),

    flowerDate: GerminationData
      ? GerminationDatasInfos[0]
        ? moment(GerminationDatasInfos[0].flowerDate).format("YYYY-MM-DD")
        : ""
      : convert(new Date(new Date().getTime() + 6 * 7 * 24 * 60 * 60 * 1000)),
    harvestDate: GerminationData
      ? GerminationDatasInfos[0]
        ? moment(GerminationDatasInfos[0].harvestDate)
        : ""
      : convert(new Date(new Date().getTime() + 12 * 7 * 24 * 60 * 60 * 1000)),
    curingDate: GerminationData
      ? GerminationDatasInfos[0]
        ? moment(GerminationDatasInfos[0].curingDate)
        : ""
      : convert(new Date(new Date().getTime() + 14 * 7 * 24 * 60 * 60 * 1000)),
    packageDate: GerminationData
      ? GerminationDatasInfos[0]
        ? moment(GerminationDatasInfos[0].packageDate)
        : ""
      : convert(new Date(new Date().getTime() + 14 * 7 * 24 * 60 * 60 * 1000)),
    shippingDate: GerminationData
      ? GerminationDatasInfos[0]
        ? moment(GerminationDatasInfos[0].shippingDate)
        : ""
      : convert(new Date(new Date().getTime() + 14 * 7 * 24 * 60 * 60 * 1000)),
    exitDate: GerminationData
      ? GerminationDatasInfos[0]
        ? moment(GerminationDatasInfos[0].curingDate)
        : ""
      : convert(new Date(new Date().getTime() + 14 * 7 * 24 * 60 * 60 * 1000)),
  };
  console.log(initialFValues, "initialFValues");
  const [, sendNotification] = useNotification();
  const classes = useStyles();
  console.log(GerminationData, "GerminationData");
  // const classes = useStyles();
  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("fullName" in fieldValues)
      temp.fullName = fieldValues.fullName ? "" : "This field is required.";
    if ("email" in fieldValues)
      temp.email = /$^|.+@.+..+/.test(fieldValues.email)
        ? ""
        : "Email is not valid.";
    if ("mobile" in fieldValues)
      temp.mobile =
        fieldValues.mobile.length > 9 ? "" : "Minimum 10 numbers required.";
    if ("departmentId" in fieldValues)
      temp.departmentId =
        fieldValues.departmentId.length !== 0 ? "" : "This field is required.";
    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, errors, setErrors, handleInputChange } = useForm(
    initialFValues,
    true,
    validate
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate() && !GerminationData && !IsSubmitting) {
      console.log(values, "values post1");
      Post(values);
      // employeeService.insertEmployee(values);
      // resetForm();
    }
    if (validate() && (GerminationData || IsSubmitting)) {
      console.log(values, "values post2 ");
      Post2(values);
    }
    // console.log(values);
  };
  // function padTo2Digits(num) {
  //   return num.toString().padStart(2, "0");
  // }
  // function formatDate(date) {
  //   return [
  //     date.getFullYear(),
  //     padTo2Digits(date.getMonth() + 1),
  //     padTo2Digits(date.getDate()),
  //   ].join("-");
  // }
  function convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }
  const Post = (values) => {
    var date1 = convert(values.vegDate);
    var date2 = convert(values.flowerDate);
    var date3 = convert(values.harvestDate);
    var date4 = convert(values.curingDate);
    var date5 = convert(values.packageDate);
    var date6 = convert(values.shippingDate);
    const Details = JSON.stringify({
      lotId: values.lotId,
      strainName: values.strain,
      amount: values.amount,
      measure: values.weight === "" ? "kg" : values.weight,
      grower: values.grower,
      batchId: values.batchId,
      status: "Germination",
      type: values.type,
      seed: values.seed,
      growingMethod:
        values.growingMethod === "Other"
          ? values.growingMethodOther
          : values.growingMethod,
      organicNutrition:
        values.organicNutrition === "Other"
          ? values.organicNutritionOther
          : values.organicNutrition,
      vegDate: date1,
      flowerDate: date2,
      harvestDate: date3,
      curingDate: date4,
      packageDate: date5,
      shippingDate: date6,
      seedType: values.seedOrCloneName,
      seedNumbers: values.usingSeed,
      motherPlantId: values.motherPlantId,
      cloneNumbers: values.numberOfClones,
      exitDate: date4,
      userId: `${user.user.id}`,
      userName: values.username,
    });
    console.log(Details, "germinationData post 1");
    axios
      .post(`/lots`, Details, {
        headers: { "Content-Type": "application/json" },
      })
      .then(
        (res) => (
          // setIsSubmitting(true),
          setOpen(true), console.log(res.data, "germinationData")
        )
      )
      .catch((error) => {
        console.error("There was an error!", "error", "error");
        sendNotification({ msg: "There was an error!", variant: "error" });
        if (!error?.response) {
          console.log("No Server Response");
        } else if (error.response?.status === 400) {
          console.log("Missing Username or Password");
        } else if (error.response?.status === 401) {
          console.log("Unauthorized");
        } else {
          console.log("Login Failed");
        }
      });
    console.log(Details);
  };
  const Post2 = (values) => {
    if (GerminationDatasInfos[0]) {
    }
    // var newNumber =
    const Details1 = JSON.stringify({
      cloneNumbers:
        GerminationDatasInfos[0].cloneNumbers + values.numberOfClones,
    });
    console.log(Details1, "germinationData");
    console.log(
      Number(GerminationDatasInfos[0].cloneNumbers) +
        Number(values.newnumberOfClones),
      "clone count"
    );
    // put germination week 1
    var date1 = convert(values.vegDate);
    var date2 = convert(values.flowerDate);
    var date3 = convert(values.harvestDate);
    var date4 = convert(values.curingDate);
    var date5 = convert(values.packageDate);
    var date6 = convert(values.shippingDate);
    const Details = JSON.stringify({
      lotId: values.lotId,
      strainName: values.strain,
      amount: values.amount,
      measure: values.weight === "" ? "kg" : values.weight,
      grower: values.grower,
      batchId: values.batchId,
      status: "Germination",
      type: values.type,
      seed: values.seed,
      growingMethod:
        values.growingMethod === "Other"
          ? values.growingMethodOther
          : values.growingMethod,
      organicNutrition:
        values.organicNutrition === "Other"
          ? values.organicNutritionOther
          : values.organicNutrition,
      vegDate: date1,
      flowerDate: date2,
      harvestDate: date3,
      curingDate: date4,
      packageDate: date5,
      shippingDate: date6,
      seedType: values.seedOrCloneName,
      seedNumbers: values.usingSeed,
      motherPlantId: values.motherPlantId,
      cloneNumbers: values.numberOfClones,
      exitDate: date4,
      userId: `${user.user.id}`,
      userName: values.username,
    });
    console.log(Details, GerminationData, "germinationData post 1");
    axios
      .put(`/lots/` + GerminationData.id, Details, {
        headers: { "Content-Type": "application/json" },
      })
      .then(
        (res) => (
          setIsSubmitting(true),
          setOpen(true),
          console.log(res.data, "germinationData")
        )
      )
      .catch((error) => {
        console.error("There was an error!", "error", "error");
        sendNotification({ msg: "There was an error!", variant: "error" });
        if (!error?.response) {
          console.log("No Server Response");
        } else if (error.response?.status === 400) {
          console.log("Missing Username or Password");
        } else if (error.response?.status === 401) {
          console.log("Unauthorized");
        } else {
          console.log("Login Failed");
        }
      });
    axios
      .put(
        `/updateClones/${GerminationDatasInfos[0].id}`,
        {
          cloneNumbers:
            Number(GerminationDatasInfos[0].cloneNumbers) +
            Number(values.newnumberOfClones),
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      )
      .then(
        (res) => (
          // setIsSubmitting(true),
          setOpen(true), console.log(res.data, "germinationData")
        )
      )
      .catch((error) => {
        console.error("There was an error!", "error", "error");

        if (!error?.response) {
          sendNotification({ msg: "Server Error", variant: "error" });
        } else {
          sendNotification({ msg: "There was an error!", variant: "error" });
        }
      });
    console.log("Details");
  };
  return (
    <Form onSubmit={handleSubmit}>
      <div className={classes.TableCol}>
        <div>
          <div>
            <Controls.Input
              // disabled={(GerminationData ? true : false) || IsSubmitting}
              name="lotId"
              label="Lot ID"
              value={values.lotId}
              onChange={handleInputChange}
              error={errors.lotId}
              required="required"

              // type="number"
            />
          </div>
          <div>
            <Controls.Input
              // disabled={(GerminationData ? true : false) || IsSubmitting}
              name="batchId"
              label="Batch ID"
              value={values.batchId}
              onChange={handleInputChange}
              error={errors.batchId}
              required="required"

              // type="number"
            />
          </div>
          <div>
            <Controls.Input
              name="grower"
              label="Grower"
              value={values.grower}
              onChange={handleInputChange}
              error={errors.grower}
              // disabled={(GerminationData ? true : false) || IsSubmitting}
              required="required"
            />
          </div>
          <div>
            <Controls.Input
              name="username"
              label="username"
              value={values.username}
              onChange={handleInputChange}
              error={errors.username}
              // disabled={(GerminationData ? true : false) || IsSubmitting}
              required="required"
            />
          </div>
        </div>
        <div>
          <div>
            <Controls.Select
              placeholder="Select Type"
              name="type"
              label="Type"
              value={values.type}
              onChange={handleInputChange}
              options={CannabisType}
              error={errors.type}
              // disabled={(GerminationData ? true : false) || IsSubmitting}
              width="236px"
              required="required"
            />
          </div>
          <div>
            <Controls.Input
              name="seed"
              label="Seed"
              value={values.seed}
              onChange={handleInputChange}
              error={errors.seed}
              // disabled={(GerminationData ? true : false) || IsSubmitting}
              required="required"
            />
          </div>
          <div>
            <Controls.Input
              name="strain"
              label="Strain"
              value={values.strain}
              onChange={handleInputChange}
              error={errors.strain}
              // disabled={(GerminationData ? true : false) || IsSubmitting}
              required="required"
            />
          </div>
        </div>
        <div>
          <div>
            <Controls.Select
              placeholder="Select Growing Method"
              name="growingMethod"
              label="Growing Method"
              value={values.growingMethod}
              onChange={handleInputChange}
              options={growingMethod}
              error={errors.growingMethod}
              width="250px"
              // disabled={(GerminationData ? true : false) || IsSubmitting}
              required="required"
            />
          </div>
          {/* {values.growingMethod} */}
          <div>
            {values.growingMethod === "Other" && (
              <Controls.Input
                name="growingMethodOther"
                label="Other"
                value={values.growingMethodOther}
                onChange={handleInputChange}
                error={errors.strain}
                // disabled={(GerminationData ? true : false) || IsSubmitting}
                required="required"
                maxWidth="228px !important"
              />
            )}
          </div>
          <div>
            <Controls.Select
              placeholder="Select Base Material"
              name="organicNutrition"
              label="Organic Nutrition"
              value={values.organicNutrition}
              onChange={handleInputChange}
              options={organicNutritionOptions}
              error={errors.organicNutrition}
              // disabled={(GerminationData ? true : false) || IsSubmitting}
              width="250px"
              required="required"
            />
          </div>
          <div>
            {values.organicNutrition === "Other" && (
              <Controls.Input
                name="organicNutritionOther"
                label="Other"
                value={values.organicNutritionOther}
                onChange={handleInputChange}
                error={errors.strain}
                // disabled={(GerminationData ? true : false) || IsSubmitting}
                required="required"
                maxWidth="228px !important"
              />
            )}
          </div>
          <div>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              {/* <span className={classes.Para}>Amount</span> */}
              <Box sx={{ display: "flex" }}>
                <Controls.Input
                  width="20px"
                  name="amount"
                  label="Amount"
                  value={values.amount}
                  onChange={handleInputChange}
                  error={errors.amount}
                  type="number"
                  // disabled={(GerminationData ? true : false) || IsSubmitting}
                  maxWidth="84px !important"
                  required="required"
                />
                <FormControl
                  sx={{
                    m: 1,
                    minWidth: "50%",
                    minHeight: 68,
                    margin: 0,
                    display: "flex",
                    justyContent: "center",
                  }}
                >
                  <div
                    style={{
                      marginTop: "23px",
                      minWidth: "50px",
                      padding: "2px 2px 2px 32px",
                    }}
                  >
                    <Controls.Select
                      placeholder="Kg"
                      width="80px !important"
                      name="weight"
                      // label="Amount"
                      paddingTop
                      value={values.weight}
                      onChange={handleInputChange}
                      options={Amount}
                      error={errors.weight}
                      // disabled={
                      //   (GerminationData ? true : false) || IsSubmitting
                      // }
                      required="required"
                    />
                  </div>
                </FormControl>
              </Box>
            </Box>
          </div>
        </div>
        <div>
          <div>
            <Controls.DatePicker
              name="vegDate"
              label="Veg Date"
              value={values.vegDate}
              onChange={handleInputChange}
              // disabled={(GerminationData ? true : false) || IsSubmitting}
            />
          </div>

          <div>
            <Controls.DatePicker
              name="flowerDate"
              label="Flower Date"
              value={values.flowerDate}
              onChange={handleInputChange}
              // disabled={(GerminationData ? true : false) || IsSubmitting}
            />
          </div>
          <div>
            <Controls.DatePicker
              name="harvestDate"
              label="Harvest Date"
              value={values.harvestDate}
              onChange={handleInputChange}
              // disabled={(GerminationData ? true : false) || IsSubmitting}
            />
          </div>
        </div>

        <div>
          <div>
            <Controls.DatePicker
              name="curingDate"
              label="Curing Date"
              value={values.curingDate}
              onChange={handleInputChange}
              // disabled={(GerminationData ? true : false) || IsSubmitting}
            />
          </div>
          <div>
            <Controls.DatePicker
              name="packageDate"
              label="Package Date"
              value={values.packageDate}
              onChange={handleInputChange}
              // disabled={(GerminationData ? true : false) || IsSubmitting}
            />
          </div>
          <div>
            <Controls.DatePicker
              name="shippingDate"
              label="Shipping Date"
              value={values.shippingDate}
              onChange={handleInputChange}
              // disabled={(GerminationData ? true : false) || IsSubmitting}
            />
          </div>
        </div>
      </div>
      <div>
        <Controls.Select
          name="seedOrCloneName"
          label="Are you using seed or clone ?"
          value={values.seedOrCloneName}
          onChange={handleInputChange}
          options={seedOrClone}
          error={errors.seedOrCloneName}
          placeholder="seed or clone"
          // disabled={(GerminationData ? true : false) || IsSubmitting}
          required="required"
        />
      </div>
      {/* {values.seedOrCloneName} */}
      {values.seedOrCloneName === "seed" && (
        <div>
          <Controls.Input
            name="usingSeed"
            label="number of seeds"
            value={values.usingSeed}
            onChange={handleInputChange}
            error={errors.usingSeed}
            type="number"
            // disabled={(GerminationData ? true : false) || IsSubmitting}
            required="required"
          />
        </div>
      )}
      {values.seedOrCloneName === "clones" && (
        <>
          <div>
            <Controls.Input
              name="motherPlantId"
              label="Mother Plant Id"
              value={values.motherPlantId}
              onChange={handleInputChange}
              error={errors.motherPlantId}
              // type="number"
              // disabled={(GerminationData ? true : false) || IsSubmitting}
              required="required"
            />
          </div>
          <div>
            <Controls.Input
              name="numberOfClones"
              label="Number of clones"
              value={values.numberOfClones}
              onChange={handleInputChange}
              error={errors.numberOfClones}
              // disabled={(GerminationData ? true : false) || IsSubmitting}
              type="number"
              required="required"
            />
          </div>
          {GerminationData && (
            <div>
              <Controls.Input
                name="newnumberOfClones"
                label=" Add number of clones"
                value={values.newnumberOfClones}
                onChange={handleInputChange}
                error={errors.newnumberOfClones}
                // disabled={IsSubmitting}
                type="number"
              />
            </div>
          )}
          {/* GerminationData */}
        </>
      )}

      <div className={classes.StartLotDiv}>
        <Controls.Button
          type="submit"
          text={GerminationData ? "Update Lot" : "Start Lot"}
          className={classes.StartLot}
          color="#55815C"
          // disabled={IsSubmitting}
        >
          <div className={classes.StartLot}></div>
        </Controls.Button>
        <Dialog
          open={open}
          onClose={handleClose}
          classes={{ paper: classes.DialogRoot }}
        >
          <form
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <DialogTitle
              sx={{
                // margin: "0 important",
                padding: "0 important",
                color: "#3C9061",
                fontSize: "40px",
                margin: "0 auto",
              }}
            >
              Week 1
            </DialogTitle>
            <DialogContent
              sx={{ margin: "0 important", padding: "0 important" }}
            >
              <DialogContentText>
                {/* To subscribe to this website, please enter your email address
                here. We will send updates occasionally. */}
              </DialogContentText>

              <DialogContentText
                sx={{
                  opacity: "1",
                  paddingTop: "20px",
                  textTransform: "capitalize",
                  fontWeight: 600,
                  color: "#000000",
                  fontSize: "20px",
                }}
              >
                Week 1 Data Was
                {IsSubmitting ? " Updated " : "Created Successfully!"}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                text="Cancel"
                onClick={handleClose}
                height="78px"
                radius="34px"
                // type="submit"
              >
                Cancel
              </Button>
              <Button
                // type="submit"
                text="Okay"
                height="78px"
                onClick={handleClose}
              >
                Okay
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </div>
    </Form>
  );
}
