import React, { useContext, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { makeStyles } from "@mui/styles";
import { createStyles } from "@material-ui/core";
import { Box, MenuItem } from "@material-ui/core";
import Button from "@mui/material/Button";
import Chips from "../../../core/chip";
// import AddIcon from "@mui/icons-material/Add";
import { FormControl, Select } from "@mui/material";
import NewLotCreation from "../newLotCreation";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import { ReactComponent as Icon } from "../../../../assect/svg/ICON.svg";
import { AuthContext } from "../../../../state/Auth";
import axios from "../../../../axios";
import Moment from "react-moment";
import LinearProgress from "@mui/material/LinearProgress";
// import CurrentDetail from "";

import PreviousLotDetails from "./previosDetailsTable";
// import { PDFDownloadLink, Document, Page } from "@react-pdf/renderer";
// import MyDoc from "../../../page/reportPage";
// import Report from "../../page/"
const FindColor = (color) => {
  var co = color.toUpperCase();
  console.log(co);
  if (co == "GERMINATION") {
    return "#C6CB8F";
  } else if (co == "VEGITATIVE") {
    return "#46B9C3";
  } else if (co == "FLOWER") {
    return "#CB8FC6";
  } else if (co == "HARVEST") {
    return "#8FADCB";
  } else if (co == "CURING") {
    return "#FFA3A3";
  } else if (co == "SUMMARY") {
    return "#2DA263";
  } else {
    console.log(co, "backgroundColor");
    return "#000000";
  }
};
const useStyles = makeStyles(() =>
  createStyles({
    CurrentHead: {
      fontSize: "22px",
      color: "#06152B",
      margin: "0 !important",
      padding: "10px",
      fontWeight: 500,
    },
    CurrentRed: {
      color: "#F00000",
      maxWidth: "324px",
      fontSize: "12px",
      paddingLeft: "20px",
    },
    HeadRowRoot: {
      "& .MuiTableCell-head": {
        // color: "#809FB8",
        fontWeight: "700",
      },
    },
    BodyRowRoot: {
      "& .MuiTableCell-body": {
        color: "#06152B !important",
      },
    },
    More: {
      backgroundColor: "#CECDD3",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      margin: "0px 10px",
      width: "94px",
      height: "52px",
      borderRadius: "16px",
    },
    DialogRoot: {
      // maxWidth: "1500px !important",
      margin: "0 auto !important",
      // marginTop:'5rem !important',
      // background:'#7f7f7f !important',
      // height: '70%',
      boxShadow: "none !important",
      border: "none !important",
    },
    root: {
      borderRadius: "16px !important",
      color: "#809FB8",
      height: "52px",
    },
    Select: {
      padding: "9.5px 14px !important",
      minHeight: "32px !important",
      border: "2px solid #d9e1e7 !important",
      background: "#F1F4F9 0% 0% no-repeat padding-box !important",
      display: "flex !important",
      justifyContent: "flex-start",
      alignItems: "center",
      borderRadius: "16px !important",
    },
    TableRoot: {
      border: "1px solid #d9e1e7cc !important",
      background: "#FFFFFF 0% 0% no-repeat padding-box !important",
      borderRadius: "16px !important",
      boxShadow: "none !important",
    },
  })
);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export default function PreviousCultivationTable() {
  const classes = useStyles();
  const [year, setYear] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [DeatilsOpen, setDeatilsOpen] = React.useState(false);
  const [CurrentTableData, setCurrentTableData] = useState([]);
  const [DetailIndexs, setDetailIndexs] = React.useState("");

  const [Rows, setRows] = useState([]);
  const [user, dispatch] = useContext(AuthContext);
  const [Loarding, setLoarding] = React.useState(true);
  const DeatilsOpenHandleOpen = (row, i) => {
    setDetailIndexs(row.id);
    setCurrentTableData(i);
    setDeatilsOpen(true);
  };
  React.useEffect(() => {
    axios
      .get(`/previousCultivations/${user.user ? user.user.id : ""}`)
      .then((res) => {
        console.log(res.data, "ALL lots data from '/lots");
        setRows(res.data);
        setLoarding(false);
        // enqueueSnackbar('I love hooks');
        // sendNotification({ msg: "yourmessage", variant: "error" });
      })
      .catch((error) => {
        if (error.response?.status === 404) {
          setLoarding(false);
          setRows([]);
          console.log("errors current table fetching");
        }
        // console.log(error, "error in lots");
      });
    // console.log("useEffect in lots");
  }, [user]);
  const handleChange = (event) => {
    setYear(event.target.value);
  };
  // const handleClickOpen = () => {
  //   setOpen(true);
  // };
  const DeatilsOpenHandleClose = () => {
    setDeatilsOpen(false);
  };
  // const DeatilsOpenHandleOpen = () => {
  //   setDeatilsOpen(true);
  // };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <TableContainer
      component={Paper}
      sx={{ marginTop: "2rem", padding: "1rem" }}
      classes={{ root: classes.TableRoot }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <p className={classes.CurrentHead}>Previous Cultivation</p>
        </Box>
        <Box sx={{ display: "flex", alignItems: "flex-start" }}>
          <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            fullScreen
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
            sx={{ maxWidth: "1500px", boxShadow: "none !important" }}
            classes={{ root: classes.DialogRoot }}
          >
            {open && <NewLotCreation handleClose={handleClose} />}
          </Dialog>
          <Dialog
            open={DeatilsOpen}
            TransitionComponent={Transition}
            keepMounted
            fullScreen
            onClose={DeatilsOpenHandleClose}
            aria-describedby="alert-dialog-slide-description"
            sx={{ boxShadow: "none !important" }}
            classes={{ root: classes.DialogRoot }}
          >
            <PreviousLotDetails
              DeatilsOpenHandleClose={DeatilsOpenHandleClose}
              detailIndex={DetailIndexs}
              CurrentTableData={CurrentTableData}
            />
            {/* sdsdsd */}
          </Dialog>
          {/* <Button
            variant="contained"
            sx={{
              height: "52px !important",
              borderRadius: "16px",
              backgroundColor: "#3A36DB",
              fontSize: "14px",
            }}
            startIcon={<AddIcon />}
            onClick={handleClickOpen}
          >
            Start New Lot
          </Button> */}
          {/* <Button
            variant="contained"
            startIcon={<Icon style={{ fill: "#ffff" }} />}
            sx={{
              backgroundColor: "#3A36DB",
              marginRight: "1rem",
              height: "52px !important",
              borderRadius: "16px",
            }}
            // onClick={PdfOPen}
          >
        

            <div>
            Download now!
            
            </div>
          </Button> */}
          {/* <div className={classes.More}>More</div> */}
          <FormControl
            sx={{
              m: 1,
              minWidth: 120,
              minHeight: 68,
              margin: 0,
            }}
          >
            <Select
              value={year}
              onChange={handleChange}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              classes={{
                select: classes.Select,
                iconOutlined: classes.iconOutlined,
              }}
              className={classes.root}
            >
              <MenuItem value="">
                <span>Newest</span>
              </MenuItem>
              <MenuItem value={2020}>Oldest</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box>
      <Table
        size="small"
        aria-label="a dense table"
        sx={{
          [`& .${tableCellClasses.root}`]: {
            borderBottom: "none",
          },
        }}
      >
        <TableHead>
          <TableRow classes={{ root: classes.HeadRowRoot }}>
            <TableCell>Lot No.</TableCell>
            <TableCell>Batch ID</TableCell>
            <TableCell>Strain Name</TableCell>
            <TableCell>Exit Date</TableCell>
            <TableCell>Next Gate</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell>Grower</TableCell>

            <TableCell>Status</TableCell>
            <TableCell>Options</TableCell>
            <Dialog
              open={DeatilsOpen}
              TransitionComponent={Transition}
              keepMounted
              fullScreen
              onClose={DeatilsOpenHandleClose}
              aria-describedby="alert-dialog-slide-description"
              sx={{ boxShadow: "none !important" }}
              classes={{ root: classes.DialogRoot }}
            >
              {DetailIndexs !== "" && (
                <PreviousLotDetails
                  DeatilsOpenHandleClose={DeatilsOpenHandleClose}
                  detailIndex={DetailIndexs}
                  CurrentTableData={CurrentTableData}
                />
              )}
            </Dialog>
            <Dialog
              open={open}
              TransitionComponent={Transition}
              keepMounted
              fullScreen
              onClose={handleClose}
              aria-describedby="alert-dialog-slide-description"
              // sx={{ maxWidth: "1500px", boxShadow: "none !important" }}
              classes={{ root: classes.DialogRoot }}
            >
              {open && (
                <NewLotCreation
                  detailIndex={setDetailIndexs}
                  handleClose={handleClose}
                  summary="week 14"
                />
              )}
            </Dialog>
          </TableRow>
        </TableHead>

        <TableBody>
          <TableRow style={{ width: "100%" }}>
            {Loarding && (
              <TableCell align="center" colSpan={12}>
                <LinearProgress />
              </TableCell>
            )}
          </TableRow>
          {/* <TableRow style={{ width: "100%" }}> */}
          {!Loarding && Rows.length === 0 && (
            <TableCell align="center" colSpan={12}>
              No Data
            </TableCell>
          )}
          {/* </TableRow> */}
          {/* {JSON.stringify(Rows)} */}
          {Rows.length > 0 &&
            Rows &&
            Rows.map((row, i) => (
              <TableRow
                classes={{ root: classes.BodyRowRoot }}
                key={i}
                sx={{ border: 0 }}
              >
                <TableCell component="th" scope="row">
                  {row.lotId}
                </TableCell>
                <TableCell>{row.batchId}</TableCell>
                <TableCell>{row.strainName}</TableCell>
                <TableCell>
                  <Moment format="YYYY-MM-DD">{row.exitDate}</Moment>
                </TableCell>
                <TableCell>
                  <Moment format="YYYY-MM-DD">{row.nextGate}</Moment>{" "}
                </TableCell>
                <TableCell>
                  {row.amount} ({row.measure}){" "}
                </TableCell>
                <TableCell>{row.grower}</TableCell>

                <TableCell>
                  <Chips background={FindColor(row.status)} data={row.status} />
                </TableCell>
                <TableCell>
                  <Button
                    variant="outlined"
                    sx={{
                      border: "2px solid #D9E1E7",
                      borderRadius: "10px",
                      height: "40px !important",
                      width: "72px !important",
                      padding: "10px 14px !important",
                      fontSize: "12px !important",
                      color: "#06152B !important",
                      fontWeight: "300 !important",
                      ":hover": {
                        border: "2px solid #D9E1E7",
                      },
                    }}
                    onClick={() => DeatilsOpenHandleOpen(row, i)}
                  >
                    Details
                  </Button>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
