import Home from "../page/home";
import My404Component from "../page/404";
import Construction from "../page/construction";
import SignUp from "../page/signup";
import Login from "../page/login";
import Report from "../page/reportPage";
import Test from "../components/layout/cultivationTracking/newLotCreation/weekTable/week2table";


export const publicRoutes = [
  {
    path: "/",
    component: Home,
    exact: true,
  },
  {
    path: "/test",
    component: Test,
    exact: true,
  },
  {
    path: "/report",
    component: Report,
    exact: true,
  },
  {
    path: "/signup",
    component: SignUp,
    exact: true,
  },
  {
    path: "/login",
    component: Login,
    exact: true,
  },
  {
    path: "/dashboard",
    component: Construction,
    exact: true,
  },
  {
    path: "/product",
    component: Construction,
    exact: true,
  },
  {
    path: "/products",
    component: Construction,
    exact: true,
  },
  {
    path: "/customer",
    component: Construction,
    exact: true,
  },
  {
    path: "/profile",
    component: Construction,
    exact: true,
  },
  {
    path: "/inbox",
    component: Construction,
    exact: true,
  },
  {
    path: "/setting",
    component: Construction,
    exact: true,
  },
  {
    path: "*",
    component: My404Component,
    exact: true,
  },
];

export const AuthenticationRoutes = [
  {
    path: "/signup",
    component: SignUp,
    exact: false,
  },
  {
    path: "/login",
    component: Login,
    exact: true,
  },
  {
    path: "*",
    component: Login,
    exact: true,
  },
];
