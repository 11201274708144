import Input from "./inputFeild";
import Select from "./select";
import SelectFun from "./selectFun";
import SelectFun2 from "./selectFun2";
import DatePicker from "./datePicker";
import Button from "./Button";

const Controls = {
  Input,
  Select,
  DatePicker,
  Button,
  SelectFun,
  SelectFun2,

};

export default Controls;
