import React, { useState, useContext, useEffect } from "react";
import Controls from "../../../../core/formControl/Controls";
import { makeStyles } from "@mui/styles";
import { createStyles } from "@material-ui/core";
import axios from "../../../../../axios";
import useNotification from "../../../../core/snakeBar";
import { useForm, Form } from "../../../../core/formControl/useForm";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "../../../../core/Button";
import { AuthContext } from "../../../../../state/Auth";

const useStyles = makeStyles(() =>
  createStyles({
    Paras: {
      color: "#000000",
      letterSpacing: "0.26px",
      textAlign: "left",
      // fontWeight: "600",
      textTransform: "capitalize",
      marginLeft: "22px",
    },
    TableCol: {
      display: "flex",
      // flexDirection: "Column",
      margin: "0 auto",
      justifyContent: "space-around",
      "& > :nth-child(n+1)": {
        // margin: "8px",
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "flex-end",
        flexDirection: "column",
      },
    },
    TableCol2: {
      display: "flex",
      flexWrap: "wrap",
      margin: "0 auto",
      justifyContent: "space-around",
    },
    StartLotDiv: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    StartLot: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "162px",
      height: "32px",
      background: "#55815C 0% 0% no-repeat padding-box",
      borderRadius: "8px",
      cursor: "pointer",
      color: "#fff",
    },
    AccordionRoot: {
      backgroundColor: "#6CD3A0",
      borderRadius: "16px !important ",
      border: " 2px solid #d9e1e7 !important",
      boxShadow: "none !important",
      position: "inherit !important",
    },
    Para: {
      color: "#809FB8",
      letterSpacing: "0.26px",
      textAlign: "left",
      marginBottom: "-25px",
    },
    DialogRoot: {
      // background: "red !important",
      padding: "3rem 4rem",
      width: "900px",
      // width:''
      background: "#FFFFFF 0% 0% no-repeat padding-box",
      boxShadow: "5px 5px 44px #00000029",
      borderRadius: "10px",
      opacity: "1",
    },
  })
);
const curingContainer = [
  {
    id: "Paper Bag",
    name: "Paper Bag",
  },
  {
    id: "Plastic Tote",
    name: "Plastic Tote ",
  },
  {
    id: "Garbage Bin",
    name: "Garbage Bin ",
  },
  {
    id: "Other",
    name: "Other",
  },
];
export default function CustomForm({
  detailIndex,
  DetailsData,
  summary,
  lotsId,
  status,
}) {
  // useEffect(() => {
  const [user, dispatch] = useContext(AuthContext);
  const [IsSubmitting, setIsSubmitting] = useState(false);

  console.log(detailIndex, DetailsData, "DetailsDataDetailsData");
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const [StateDisable, setStateDisable] = useState(false);

  useEffect(() => {
    console.log("status", status);
    if (status == "Summary") {
      setStateDisable(true);
    }
    //
  }, [detailIndex, DetailsData]);

  var initialFValues = {
    humidityAid: DetailsData ? DetailsData.humidityAid : "",
    curingContainer: DetailsData ? DetailsData.curingContainer : "",
    containerText: DetailsData ? DetailsData.curingContainer : "",
    finishDate: DetailsData ? DetailsData.finishDate : new Date(),
  };

  const [, sendNotification] = useNotification();
  const classes = useStyles();
  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("fullName" in fieldValues)
      temp.fullName = fieldValues.fullName ? "" : "This field is required.";
    if ("email" in fieldValues)
      temp.email = /$^|.+@.+..+/.test(fieldValues.email)
        ? ""
        : "Email is not valid.";
    if ("mobile" in fieldValues)
      temp.mobile =
        fieldValues.mobile.length > 9 ? "" : "Minimum 10 numbers required.";
    if ("departmentId" in fieldValues)
      temp.departmentId =
        fieldValues.departmentId.length !== 0 ? "" : "This field is required.";
    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, errors, setErrors, handleInputChange } = useForm(
    initialFValues,
    true,
    validate
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      console.log(values.finishDate, "values");
      if (DetailsData) {
        if (DetailsData.id) {
          Updating(values);
        } else {
          sendNotification({
            msg: "Refresh the page!",
            variant: "error",
          });
        }
      } else {
        Post(values);
      }
    }
    console.log(values);
  };
  function convert(str) {
    console.log(str);
    var date = new Date(str),
      //   console.log(date);

      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  const Post = (values) => {
    const date = convert(values.finishDate);
    console.log(values.finishDate, "date");
    if (values.curingContainer === "Other") {
      values.curingContainer = values.containerText;
    }
    console.log(date);
    const Details = JSON.stringify({
      humidityAid: values.humidityAid,
      curingContainer: values.curingContainer,
      finishDate: date,
      lotId: `${detailIndex ? detailIndex : "2"}`,
      userId: `${user.user.id}`,
    });

    axios
      // detailIndex
      .post(`/curing`, Details, {
        headers: { "Content-Type": "application/json" },
      })
      .then(
        (res) => {
          // setOpen(true);
          // update status
          axios
            // detailIndex
            .put(
              `/updateStatus/${lotsId}`,
              // status
              { status: "Curing" },
              {
                headers: { "Content-Type": "application/json" },
              }
            )
            .then((res) => {
              console.log(res, "ok done");
            })
            .then((res) => {
              setOpen(true);
              setIsSubmitting(true);
            })
            .catch(
              (err) => (
                sendNotification({
                  msg: "There was an error!",
                  variant: "error",
                }),
                console.log(err.response, "err"),
                console.log("err")
              )
            );
          console.log(res.data, "abputdata");
        }
        // resetForm()
      )
      .catch((error) => {
        console.error("There was an error!", error);
        sendNotification({ msg: "There was an error!", variant: "error" });
      });
    console.log(Details, "/details");
  };

  // updating curing
  const Updating = (values) => {
    const date = convert(values.finishDate);
    if (values.curingContainer === "Other") {
      values.curingContainer = values.containerText;
    }
    const Details = JSON.stringify({
      humidityAid: values.humidityAid,
      curingContainer: values.curingContainer,
      finishDate: date,
      lotId: `${detailIndex ? detailIndex : "2"}`,
      userId: `${user.user.id}`,
    });

    axios
      // detailIndex
      .put(`/curing/` + DetailsData.curingId, Details, {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        setOpen(true);
        setIsSubmitting(true);
      })
      .catch((error) => {
        sendNotification({ msg: "There was an error!", variant: "error" });
      });
  };
  return (
    <Form onSubmit={handleSubmit}>
      <div className={classes.TableCol}>
        <div>
          <Controls.Input
            disabled={DetailsData ? false : IsSubmitting}
            name="humidityAid"
            label="What is your Humidity Aid?"
            value={values.humidityAid}
            onChange={handleInputChange}
            error={errors.humidityAid}
            maxWidth="250px !important"
          />
        </div>
        <div>
          <div>
            <Controls.Select
              name="curingContainer"
              placeholder="What is your Curing Container? "
              value={values.curingContainer}
              onChange={handleInputChange}
              options={curingContainer}
              error={errors.curingContainer}
              disabled={IsSubmitting}
            />
          </div>
          {/* {values.curingContainer && <p> {values.curingContainer} </p>} */}
          {values.curingContainer === "Other" && (
            // <div>
            <Controls.Input
              disabled={IsSubmitting}
              name="containerText"
              label="What is your Curing Container?"
              value={values.containerText}
              onChange={handleInputChange}
              error={errors.containerText}
              maxWidth="250px !important"
            />
            // {/* </div> */}
          )}
        </div>
        <div>
          <p className={classes.Paras}>Finish Date</p>
          <div style={{ margin: "0px 0px -23px 0px" }}>
            <Controls.DatePicker
              name="finishDate"
              //   label="When Will Curing Finish?"
              value={values.finishDate}
              onChange={handleInputChange}
              disabled={IsSubmitting}
            />
          </div>
        </div>
      </div>

      <div className={classes.StartLotDiv}>
        <Controls.Button
          type="submit"
          text="Complete Week"
          className={classes.StartLot}
          color="#55815C"
          // disabled={IsSubmitting}
        ></Controls.Button>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        classes={{ paper: classes.DialogRoot }}
      >
        <form
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <DialogTitle
            sx={{
              padding: "0 important",
              color: "#3C9061",
              fontSize: "40px",
              margin: "0 auto",
            }}
          >
            Week 14
          </DialogTitle>
          <DialogContent sx={{ margin: "0 important", padding: "0 important" }}>
            <DialogContentText>
              {/* To subscribe to this website, please enter your email address
                here. We will send updates occasionally. */}
            </DialogContentText>

            <DialogContentText
              sx={{
                opacity: "1",
                paddingTop: "20px",
                textTransform: "capitalize",
                fontWeight: 600,
                color: "#000000",
                fontSize: "20px",
              }}
            >
              <p>
                {" "}
                week14 Data Was{" "}
                {DetailsData ? " Updated " : "Created Successfully!"}
              </p>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              text="Cancel"
              onClick={handleClose}
              height="78px"
              radius="34px"
            >
              Cancel
            </Button>
            <Button text="Okay" height="78px" onClick={handleClose}>
              Okay
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </Form>
  );
}
