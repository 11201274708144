import React, { useContext } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { makeStyles } from "@mui/styles";
import { createStyles } from "@material-ui/core";
import { Box } from "@material-ui/core";
import Button from "@mui/material/Button";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Chips from "../../core/chip";
import axios from "../../../axios";
import { useState } from "react";
import Moment from "react-moment";
import Dialog from "@mui/material/Dialog";
import CurrentDetail from "./newLotCreation/growth/currentDetails";
import Slide from "@mui/material/Slide";
import LinearProgress from "@mui/material/LinearProgress";
import AddIcon from "@mui/icons-material/Add";
import NewLotCreation from "./newLotCreation";

import { AuthContext } from "../../../state/Auth";
// import useNotification from "../../core/snakeBar";
// import { useSnackbar } from 'notistack';

// import { ReactComponent as Icon } from "../../../assect/svg/ICON.svg";
// import PreviousLotDetails from "./previousLotDetails";
// import { PDFDownloadLink, Document, Page } from "@react-pdf/renderer";
// import MyDoc from "../../../page/reportPage";

const useStyles = makeStyles(() =>
  createStyles({
    DialogRoot: {
      // maxWidth: "1500px !important",
      margin: "0 auto !important",
      // marginTop:'5rem !important',
      // background:'#7f7f7f !important',
      // height: '70%',
      boxShadow: "none !important",
      border: "none !important",
    },
    CurrentHead: {
      fontSize: "22px",
      color: "#06152B",
      margin: "0 !important",
      padding: "10px",
      fontWeight: 500,
    },
    CurrentRed: {
      color: "#F00000",
      maxWidth: "324px",
      fontSize: "12px",
      //   display: "flex",
      //   alignItems: "flex-end",
      //   margin: "1.5rem 0 0 0",
      paddingLeft: "20px",
    },
    HeadRowRoot: {
      "& .MuiTableCell-head": {
        color: "#809FB8",
        fontWeight: "700",
      },
    },
    BodyRowRoot: {
      "& .MuiTableCell-body": {
        color: "#06152B !important",
      },
    },
    TableRoot: {
      background: "#FFFFFF 0% 0% no-repeat padding-box !important",
      border: "1px solid #D9E1E7CC !important",
      borderRadius: "16px !important",
      boxShadow: "none !important",
    },
  })
);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CurrentTable() {
  const classes = useStyles();
  const [CurrentTableData, setCurrentTableData] = useState([]);
  const [DeatilsOpen, setDeatilsOpen] = React.useState(false);
  const [detailIndex, setDetailIndex] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [Rows, setRows] = useState([]);
  const [user, dispatch] = useContext(AuthContext);
  const [Loarding, setLoarding] = React.useState(true);

  // console.log(
  //   user.user ? user.user.id : `/lots/${user.user ? user.user.id : "12"}`,
  //   "//////"
  // );
  //       .get(`lots/${user.user ? user.user.id : ""}`)

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    // setOpen(false);
    window.location.href = "/";
  };

  React.useEffect(() => {
    if (user.user) {
      axios
        .get(`/lots/${user.user ? user.user.id : ""}`)
        .then((res) => {
          console.log(res.data, "ALL lots data from '/lots");
          setRows(res.data);
          setLoarding(false);
          // enqueueSnackbar('I love hooks');
          // sendNotification({ msg: "yourmessage", variant: "error" });
        })
        .catch((error) => {
          if (error.response?.status === 404) {
            setLoarding(false);
            setRows([]);
            console.log("errors current table fetching");
          }
          // console.log(error, "error in lots");
        });
    }
    // console.log("useEffect in lots");
  }, [user]);
  // console.log(new Date(), "///");
  const DeatilsOpenHandleClose = () => {
    // setDeatilsOpen(false);
    window.location.href = "/";
  };
  const DeatilsOpenHandleOpen = (row, i) => {
    setDetailIndex(row.id);
    setCurrentTableData(i);
    setDeatilsOpen(true);
  };
  // Online Javascript Editor for free
  // Write, Edit and Run your Javascript code using JS Online Compiler

  const FindColor = (color) => {
    var co = color.toUpperCase();
    console.log(co);
    if (co == "GERMINATION") {
      return "#C6CB8F";
    } else if (co == "VEGITATIVE") {
      return "#46B9C3";
    } else if (co == "FLOWER") {
      return "#CB8FC6";
    } else if (co == "HARVEST") {
      return "#8FADCB";
    } else if (co == "CURING") {
      return "#FFA3A3";
    } else if (co == "SUMMARY") {
      return "#2DA263";
    } else {
      console.log(co, "backgroundColor");
      return "#000000";
    }
  };

  // console.log(FindColor("harvest"));

  return (
    <TableContainer
      component={Paper}
      sx={{ marginTop: "2rem", padding: "1rem" }}
      classes={{ root: classes.TableRoot }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box sx={{ display: "flex" }}>
          <p className={classes.CurrentHead}>Current Cultivation</p>
        </Box>
        <div>
          {/* <Button
            variant="contained"
            startIcon={<Icon style={{ fill: "#ffff" }} />}
            sx={{
              backgroundColor: "#3A36DB",
              marginRight: "1rem",
              height: "52px !important",
              borderRadius: "16px",
            }}
          >
            Generate Report
          </Button> */}
          {/* {Rows && Rows.length > 0 && ( 
            {/* // <Button
            //   variant="contained"
            //   startIcon={<Icon style={{ fill: "#ffff" }} />}
            //   sx={{
            //     backgroundColor: "#3A36DB",
            //     marginRight: "1rem",
            //     height: "52px !important",
            //     borderRadius: "16px",
            //   }}
            //   // onClick={PdfOPen}
            // >
            //   {/* Generate Report */}
          {/* <div>
            //     <PDFDownloadLink
            //       document={<MyDoc text="hiii" Row={R} />}
            //       fileName="somename.pdf"
            //       style={{ color: "#fff" }}
            //     >
            //       {({ blob, url, loading, error }) =>
            //         loading ? "Loading document..." : "Download now!"
            //       }
            //     </PDFDownloadLink>
            //   </div> */}
          {/* // </Button> */}
          {/* // )} */}
          <Button
            variant="contained"
            sx={{
              height: "52px !important",
              borderRadius: "16px",
              backgroundColor: "#3A36DB",
              fontSize: "14px",
              marginRight: "1rem",
            }}
            startIcon={<AddIcon />}
            onClick={handleClickOpen}
          >
            Start New Lot
          </Button>
          <MoreHorizIcon style={{ color: "#D9E1E7" }} />
        </div>
      </Box>
      <Table
        size="small"
        aria-label="a dense table"
        sx={{
          [`& .${tableCellClasses.root}`]: {
            borderBottom: "none",
          },
        }}
      >
        <TableHead>
          <TableRow classes={{ root: classes.HeadRowRoot }}>
            <TableCell>Lot No.</TableCell>
            <TableCell>Batch ID</TableCell>
            <TableCell>Strain Name</TableCell>
            <TableCell>Exit Date</TableCell>
            <TableCell>Next Gate</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell>Grower</TableCell>

            <TableCell>Status</TableCell>
            <TableCell>Options</TableCell>
            <Dialog
              open={DeatilsOpen}
              TransitionComponent={Transition}
              keepMounted
              fullScreen
              onClose={DeatilsOpenHandleClose}
              aria-describedby="alert-dialog-slide-description"
              sx={{ boxShadow: "none !important" }}
              classes={{ root: classes.DialogRoot }}
            >
              {detailIndex !== "" && (
                <CurrentDetail
                  DeatilsOpenHandleClose={DeatilsOpenHandleClose}
                  detailIndex={detailIndex}
                  CurrentTableData={CurrentTableData}
                />
              )}
            </Dialog>
            <Dialog
              open={open}
              TransitionComponent={Transition}
              keepMounted
              fullScreen
              onClose={handleClose}
              aria-describedby="alert-dialog-slide-description"
              // sx={{ maxWidth: "1500px", boxShadow: "none !important" }}
              classes={{ root: classes.DialogRoot }}
            >
              {open && (
                <NewLotCreation
                  detailIndex={detailIndex}
                  handleClose={handleClose}
                  summary="week 14"
                />
              )}
            </Dialog>
          </TableRow>
        </TableHead>

        <TableBody>
          <TableRow style={{ width: "100%" }}>
            {Loarding && (
              <TableCell align="center" colSpan={12}>
                <LinearProgress />
              </TableCell>
            )}
          </TableRow>
          {/* <TableRow style={{ width: "100%" }}> */}
          {!Loarding && Rows.length === 0 && (
            <TableCell align="center" colSpan={12}>
              No Data
            </TableCell>
          )}
          {/* </TableRow> */}
          {/* {JSON.stringify(Rows)} */}
          {Rows.length > 0 &&
            Rows &&
            Rows.map((row, i) => (
              <TableRow
                classes={{ root: classes.BodyRowRoot }}
                key={i}
                sx={{ border: 0 }}
              >
                <TableCell component="th" scope="row">
                  {row.lotId}
                </TableCell>
                <TableCell>{row.batchId}</TableCell>
                <TableCell>{row.strainName}</TableCell>
                <TableCell>
                  <Moment format="YYYY-MM-DD">{row.exitDate}</Moment>
                </TableCell>
                <TableCell>
                  <Moment format="YYYY-MM-DD">{row.nextGate}</Moment>{" "}
                </TableCell>
                <TableCell>
                  {row.amount} ({row.measure}){" "}
                </TableCell>
                <TableCell>{row.grower}</TableCell>

                <TableCell>
                  <Chips background={FindColor(row.status)} data={row.status} />
                </TableCell>
                <TableCell>
                  <Button
                    variant="outlined"
                    sx={{
                      border: "2px solid #D9E1E7",
                      borderRadius: "10px",
                      height: "40px !important",
                      width: "72px !important",
                      padding: "10px 14px !important",
                      fontSize: "12px !important",
                      color: "#06152B !important",
                      fontWeight: "300 !important",
                      ":hover": {
                        border: "2px solid #D9E1E7",
                      },
                    }}
                    onClick={() => DeatilsOpenHandleOpen(row, i)}
                  >
                    Details
                  </Button>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
