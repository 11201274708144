import React, { useState, useContext, useEffect } from "react";
import { FormControl } from "@mui/material";
import Controls from "../../../../core/formControl/Controls";
import { Box } from "@material-ui/core";
import { makeStyles } from "@mui/styles";
import { createStyles } from "@material-ui/core";
import axios from "../../../../../axios";
import useNotification from "../../../../core/snakeBar";
import { useForm, Form } from "../../../../core/formControl/useForm";
import FileUploader from "../../../../core/formControl/fileUpload";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "../../../../core/Button";
// import HarvestDiv from "../growth/harvest";
import moment from "moment";
// import ReactPDF from "react-pdf";
// import { Document } from "react-pdf";
import { AuthContext } from "../../../../../state/Auth";
// import FormData from "form-data";
// import fs from "fs";
// import { actionType } from "../../../../../state/Authreducer";
import CircularProgress from "@mui/material/CircularProgress";

const useStyles = makeStyles(() =>
  createStyles({
    ImageNamesView: {
      opacity: 0.4,
      paddingLeft: "38px",
    },
    selectedImages: {
      // color: " #000000",
      border: "1px solid #7070701A ",
      height: "44px",
      borderRadius: "32px",
      textTransform: "capitalize",
      width: "160px",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      paddingLeft: "20px",
    },
    DialogRoot: {
      // background: "red !important",
      padding: "3rem 4rem",
      width: "900px",
      // width:''
      background: "#FFFFFF 0% 0% no-repeat padding-box",
      boxShadow: "5px 5px 44px #00000029",
      borderRadius: "10px",
      opacity: "1",
    },
    TableColrow: {
      margin: " 0px 16px",
    },
    TableCol: {
      display: "flex",
      // flexDirection: "Column",
      margin: "0 auto",
      justifyContent: "space-between",
    },
    TableCol2: {
      display: "flex",
      flexWrap: "wrap",
      margin: "0 auto",
      justifyContent: "space-around",
    },
    StartLotDiv: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    StartLot: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "162px",
      height: "32px",
      background: "#55815C 0% 0% no-repeat padding-box",
      borderRadius: "8px",
      cursor: "pointer",
      color: "#fff",
    },
    AccordionRoot: {
      backgroundColor: "#6CD3A0",
      borderRadius: "16px !important ",
      border: " 2px solid #d9e1e7 !important",
      boxShadow: "none !important",
      position: "inherit !important",
    },
    Para: {
      color: "#809FB8",
      letterSpacing: "0.26px",
      textAlign: "left",
      marginBottom: "-25px",
    },
  })
);

const AmountType = [
  {
    id: "Kg",
    name: "Kg",
  },
  {
    id: "Lps",
    name: "Lps",
  },
  {
    id: "G",
    name: "G",
  },
];
const YesNo = [
  {
    id: true,
    name: "yes",
  },
  {
    id: false,
    name: "No",
  },
];

const LeafColour = [
  {
    id: "Green",
    name: "Green",
  },
  {
    id: "Light Green",
    name: "Light Green",
  },
  {
    id: "Yellow",
    name: "Yellow",
  },
];
export default function CustomForm({
  week2only,
  detailIndex,
  DetailsData,
  summary,
  floweronly,
  harvest,
  vegitative,
  lotsId,
  status,
}) {
  const [, sendNotification] = useNotification();
  const classes = useStyles();
  const [user, dispatch] = useContext(AuthContext);
  const [HarvestId, setHarvestId] = useState("");
  const [TimerDiable, setTimerDiable] = useState(true);
  const [AddTextFeild, setAddTextFeild] = useState(false);
  const [AddTextFeild2, setAddTextFeild2] = useState(false);
  const [SelectedImage, setSelectedImage] = useState(null);
  const [SelectedImageSrc, setSelectedImageSrc] = useState("");
  const [SelectedPdf, setSelectedPdf] = useState(null);
  const [SelectedPdfSrc, setSelectedPdfSrc] = useState("");
  const [IsSubmitting, setIsSubmitting] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [NutritionArray, setNutritionArray] = useState([]);
  const [DailyHarvestArray, setDailyHarvestArray] = useState([
    // {
    //   date: "3-3-3",
    //   id: 68,
    //   onSchedule: 0,
    //   correctAmount: 0,
    //   temperature: "3333",
    //   humidity: "3333",
    //   userId: 23,
    // },
  ]);
  const [Progress, setProgress] = useState("");
  const [StateDisable, setStateDisable] = useState(false);

  useEffect(() => {
    console.log("status", status, vegitative, floweronly);
    if (
      (status == "Flower" ||
        status == "Harvest" ||
        status == "Curing" ||
        status == "Summary") &&
      vegitative
    ) {
      setStateDisable(true);
    }
    if (
      (status == "Harvest" || status == "Curing" || status == "Summary") &&
      floweronly
    ) {
      setStateDisable(true);
    }
    //
  }, [detailIndex, DetailsData]);

  useEffect(() => {
    // console.log("daily dailyHarvest");
    if (DetailsData && DetailsData.harvestId) {
      axios
        .get(
          `vegitative/update/${DetailsData ? DetailsData.harvestId : "noid"}`
        )
        .then((res) => {
          setDailyHarvestArray(res.data);
        })
        .catch((err) => console.log("daily harvestdd error"));
    }

    // console.log(NutritionArray, "NutritionArrayNutritionArray");
  }, [detailIndex, DetailsData]);

  useEffect(() => {
    if (DetailsData && DetailsData.vegId) {
      console.log(DetailsData, "okokokok2");

      axios
        .get(`nutritions/${DetailsData ? DetailsData.vegId : "novegId"}`)
        .then((res) => {
          const data = res.data;
          console.log(res.data, "okokokok33");

          console.log(
            data,
            "okokokok/nutritions/",
            `${DetailsData ? DetailsData.vegId : "novegId"}`
          );
          setNutritionArray(res.data);
          // alert("123");
          // setGerminationData(res.data);
          // setGDetailsDataLoading(true);
        })
        // .then(setDetailsDataLoading(true))
        .catch(
          (err) => (
            console.log(
              `nutritions/${DetailsData ? DetailsData.vegId : "noid"}`
            ),
            console.log("okokokok4")
          )
        );
    } else {
      console.log("no vegitative id");
    }
  }, [detailIndex, DetailsData]);
  useEffect(() => {
    console.log("one ");
    if (DetailsData && DetailsData.image) {
      console.log("one two", DetailsData.image);
      setSelectedImageSrc(DetailsData.image);
      // setSelectedPdfSrc(DetailsData.filenames);
    }
    if (DetailsData && DetailsData.filenames) {
      setSelectedPdfSrc(DetailsData.filenames);
    }
  }, [DetailsData]);
  // console.log(SelectedPdfSrc, "loging images");

  useEffect(() => {
    console.log("daily dailyHarvest");
    if (DetailsData && DetailsData.harvestId) {
      axios
        .get(`dailyHarvest/${DetailsData ? DetailsData.harvestId : "noid"}`)
        .then((res) => {
          const data = res.data;
          console.log(data, "daily harvestdd");
          setDailyHarvestArray(res.data);
          // alert("123");
          // setGerminationData(res.data);
          // setGDetailsDataLoading(true);
        })
        // .then(setDetailsDataLoading(true))
        .catch(
          (err) => (
            console.log(
              DetailsData,
              `dailyHarvest/${DetailsData ? DetailsData.harvestId : "noid"}`
            ),
            console.log("daily harvestdd error")
          )
        );
    }
  }, [detailIndex]);
  useEffect(() => {
    console.log(convert(new Date()), "date format");
    // const date = convert(values.trackTypeEndDate);
    //
    if (DetailsData) {
      // setup time
      console.log(TimerDiable, "/TimerDiable deatil data true");

      if (new Date().getHours() == moment("9:00:00 AM", "h A").format("HH")) {
        setTimerDiable(true);
      } else {
        setTimerDiable(false);
      }
    } else {
      setTimerDiable(false);
      console.log(TimerDiable, "/TimerDiable !DetailsData");
    }
  }, [DetailsData]);

  DetailsData && console.log(DetailsData, "/=data");
  var initialFValues = {
    id: DetailsData ? DetailsData.lotId : "",
    trackType: DetailsData ? (DetailsData.onSchedule === 0 ? "No" : "yes") : "",
    correctAmount: DetailsData
      ? DetailsData.correctAmount === 0
        ? "No"
        : "yes"
      : "",
    newWeight: DetailsData ? DetailsData.amount : "",
    weight: DetailsData ? DetailsData.amount : "",
    rootColour: DetailsData ? DetailsData.rootColor : "",
    stalkOrStemHealth: DetailsData ? DetailsData.healthOfStem : "",
    mildew: DetailsData ? (DetailsData.mildew === 0 ? "No" : "yes") : "",
    phWater: DetailsData ? DetailsData.phWater : "",
    leafColor: DetailsData ? DetailsData.leavesColor : "",
    soil: DetailsData ? DetailsData.soil : "",
    deviatingSOP: DetailsData ? (DetailsData.sop === 0 ? "No" : "yes") : "",
    NutritionTypes: DetailsData ? DetailsData.nutrition : "",
    liquidValue: DetailsData ? DetailsData.nutritionMeasure : "",
    solidValue: DetailsData ? DetailsData.nutritionMeasure : "",
    trackTypeEndDate: DetailsData ? DetailsData.id : "",
    fileUpload: null,
    fileUpload2: null,
    // nutritionAmount:
    harvestType: DetailsData ? DetailsData.harvestType : "",
    biomassRemoved: DetailsData ? DetailsData.biomassMeasure : "",
    biomassAmountTypeweight: DetailsData ? DetailsData.biomassAmount : "",
    destroyBiomass: DetailsData ? DetailsData.biomassDestroy : "",
    dryingType: DetailsData ? DetailsData.dryingType : "",
    dehydration: "",
    temperature: "",
    humidity: "",
    processing: DetailsData
      ? DetailsData.sugarLeavingProcess === 0
        ? "No"
        : "yes"
      : "",
    harvestId: "",
    username: DetailsData ? DetailsData.userName : "",
    colouPistils: DetailsData ? DetailsData.pistilsColor : "",
    Tricholms: DetailsData ? DetailsData.tricholmsColor : "",
    densityOfNugs: DetailsData ? DetailsData.nugsDensity : "",
    colourOfFlower: DetailsData ? DetailsData.flowerColor : "",
    fanLeafHealth: DetailsData ? DetailsData.fanLeafHealth : "",
    noTracking: DetailsData ? DetailsData.scheduleReason : "",
    yesSop: DetailsData ? DetailsData.sopReason : "",
  };
  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("fullName" in fieldValues)
      temp.fullName = fieldValues.fullName ? "" : "This field is required.";
    if ("email" in fieldValues)
      temp.email = /$^|.+@.+..+/.test(fieldValues.email)
        ? ""
        : "Email is not valid.";
    if ("mobile" in fieldValues)
      temp.mobile =
        fieldValues.mobile.length > 9 ? "" : "Minimum 10 numbers required.";
    if ("departmentId" in fieldValues)
      temp.departmentId =
        fieldValues.departmentId.length !== 0 ? "" : "This field is required.";
    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange } = useForm(
    initialFValues,
    true,
    validate
  );

  // console.log(values.fileUpload, "/////week2");
  // console.log(values.fileUpload2, "/////week2");
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      if (!harvest && floweronly && detailIndex !== undefined) {
        if (DetailsData) {
          Updating2(values);
        } else {
          Post2(values);
        }
      } else if (!harvest && !floweronly) {
        if (DetailsData) {
          // console.log("DetailsData", DetailsData);
          // DetailsData.vegId
          if (DetailsData.vegId) {
            Updating(values);
          } else {
            sendNotification({
              msg: "Refresh the page!",
              variant: "error",
            });
          }
        } else {
          Post(values);
        }
      }
      // employeeService.insertEmployee(values);
      // resetForm();
    }
    // console.log(values);
  };
  function convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }
  const data = new FormData();
  const data2 = new FormData();
  const data3 = new FormData();
  const Post = (values) => {
    // status
    data3.append("status", JSON.stringify("vegitative"));
    console.log(lotsId, JSON.stringify("vegitative"));
    console.log(values, "//");
    console.log("post1");
    console.log(NutritionArray, "//");
    // NutritionArray
    const date = convert(values.trackTypeEndDate);
    console.log(values.trackTypeEndDate);
    console.log("post");
    // data.append("image", SelectedImage);
    // data2.append("filenames", SelectedPdf);
    if (SelectedImage != null) {
      data.append("image", SelectedImage);
      console.log(SelectedImage, "SelectedImage print");
    }
    data.append("userName", values.username);

    data.append(
      "onSchedule",
      JSON.stringify(values.trackType) === "true" ? 1 : 0
    );
    data.append(
      "trackType",
      JSON.stringify(values.trackType) === "true" ? 1 : 0
    );
    data.append(
      "correctAmount",
      JSON.stringify(values.correctAmount) === "true" ? 1 : 0
    );
    data.append("amount", values.weight);
    data.append("rootColor", values.rootColour);
    data.append("healthOfStem", values.stalkOrStemHealth);
    data.append("mildew", JSON.stringify(values.mildew) === "true" ? 1 : 0);
    data.append("phWater", values.phWater);
    data.append("leavesColor", values.leafColor);
    data.append("soil", values.soil);

    data.append("scheduleReason", values.noTracking);
    data.append("sopReason", values.yesSop);
    data.append("sop", JSON.stringify(values.deviatingSOP) === "true" ? 1 : 0);
    data.append("scheduleDate", date);
    data.append("lotId", `${detailIndex}`);
    // data.append("NutritionArray", NutritionArray);

    // data 2
    if (SelectedPdf != null) {
      data2.append("filenames", SelectedPdf);
    }
    console.warn(data);
    console.log("start looping");
    for (var key of data.entries()) {
      console.log(key[0] + ", " + key[1]);
    }
    console.log(data);
    console.log("data");
    // const Details = JSON.stringify({
    //   onSchedule: values.trackType,
    //   correctAmount: values.correctAmount,
    //   amount: values.weight,
    //   rootColor: values.valuesrootColour,
    //   healthOfStem: values.stalkOrStemHealth,
    //   mildew: values.mildew,
    //   phWater: values.phWater,
    //   leavesColor: values.leafColor,
    //   soil: values.soil,
    //   sop: values.deviatingSOP,
    //   scheduleDate: date,
    //   lotId: `${detailIndex}`,
    //   NutritionArray: NutritionArray,
    //   image: values.fileUpload,
    // });
    axios
      // detailIndex
      .post(`/vegitative`, data, {
        headers: { "Content-Type": "multipart/form-data" },
        onUploadProgress: (data) => {
          //Set the progress value to show the progress bar
          setProgress(Math.round((100 * data.loaded) / data.total));
        },
      })
      .then(
        async (res) => {
          var id = res.id;
          console.log(id, "iiiiiiiiiiiiiiiid");
          setProgress("");
          // updating status
          axios
            // detailIndex
            .put(
              `/updateStatus/${lotsId}`,
              // status
              { status: "Vegitative" },
              {
                headers: { "Content-Type": "application/json" },
              }
            )
            .then((res) => {
              console.log(res, "ok done");
              setOpen(true);
              setIsSubmitting(true);
            })
            .then((res) => {
              // setOpen(true);
              // setIsSubmitting(true);
            })
            .catch(
              (err) => (
                // sendNotification({
                //   msg: "There was an error!",
                //   variant: "error",
                // }),
                console.log(err.response, "err"), console.log("err")
              )
            );

          for (var key of data.entries()) {
            console.log(key[0] + ", " + key[1]);
          }
          console.log(res.data, "res.data");
          // setOpen(true);

          data2.append("id", res.data.id);
          console.log(values.fileUpload, "2222");
          // if (values.fileUpload2 != null) {
          //   data2.append("filenames", values.fileUpload2);
          // }
          for (var key of data2.entries()) {
            console.log(key[0] + ", " + key[1]);
          }
          console.log(data2, " posting data2");
          console.log(data2, " posting data2");
          if (values.fileUpload != "" && res.data.id) {
            await axios
              // detailIndex
              .put(`/vegitative/update/${res.data.id}`, data2, {
                headers: { "Content-Type": "multipart/form-data" },
              })
              .then((res) => {
                console.log(res, "ok done");
              })
              .then((res) => {
                // setOpen(true);
                // setIsSubmitting(true);
              })
              .catch(
                (err) => (
                  // sendNotification({
                  //   msg: "There was an error1!",
                  //   variant: "error",
                  // }),
                  console.log(err.response, "err"), console.log("err")
                )
              );
          }
          // nutritions
          if (NutritionArray.length > 0) {
            let sample = NutritionArray;
            sample.forEach((element) => {
              console.log(element, res.data.id, "iiiiiiiiires.id");
              element.vegId = res.data.id;
              // element.date = element.name;
              // convert
            });
            for (let index = 0; index < sample.length; index++) {
              const element = sample[index];
              console.log("iiiiiiiiiiiii", element);
              await axios
                // detailIndex
                .post(`/nutritions`, element, {
                  headers: { "Content-Type": "application/json" },
                })
                .then((res) => {
                  console.log(res, "nutritions data sent", index);
                })
                .then((res) => {
                  setOpen(true);
                  setIsSubmitting(true);
                })
                .catch(
                  (err) => (
                    sendNotification({
                      msg: "There was an error!",
                      variant: "error",
                    }),
                    console.log(err.response, "err")
                  )
                );
            }
          }
        }
        // resetForm()
      )
      .catch((error) => {
        console.log("There was an error!", error);
        sendNotification({ msg: "There was an error!", variant: "error" });
      });
    console.log(data);
  };
  // flower
  const Post2 = (values) => {
    const data = new FormData();
    data.append("lotId", detailIndex);
    data.append("id", detailIndex);
    data.append(
      "onSchedule",
      JSON.stringify(values.trackType) === "true" ? 1 : 0
    );
    data.append(
      "correctAmount",
      JSON.stringify(values.correctAmount) === "true" ? 1 : 0
    );
    data.append("healthOfStem", values.stalkOrStemHealth);
    data.append("rootColor", values.rootColour);
    data.append("mildew", JSON.stringify(values.mildew) === "true" ? 1 : 0);
    data.append("leavesColor", values.leafColor);
    data.append("phWater", values.phWater);
    data.append("soil", values.soil);
    data.append("sop", JSON.stringify(values.deviatingSOP) === "true" ? 1 : 0);
    data.append("pistilsColor", values.colouPistils);
    data.append("tricholmsColor", values.Tricholms);
    data.append("nugsDensity", values.densityOfNugs);
    data.append("fanLeafHealth", values.fanLeafHealth);
    data.append("flowerColor", values.colourOfFlower);
    data.append("userName", values.username);
    data.append("scheduleReason", values.noTracking);
    data.append("sopReason", values.yesSop);

    if (SelectedImage != null) {
      data.append("image", SelectedImage);
      console.log(SelectedImage, "SelectedImage print");
    }
    const data2 = new FormData();
    if (SelectedPdf != null) {
      data2.append("filenames", SelectedPdf);
    }
    axios
      // detailIndex
      .post(`/flower`, data, {
        headers: { "Content-Type": "multipart/form-data" },
        onUploadProgress: (data) => {
          //Set the progress value to show the progress bar
          setProgress(Math.round((100 * data.loaded) / data.total));
        },
      })
      .then(
        async (res) => {
          console.log("sucess");
          if (values.fileUpload != "" && res.data.id) {
            await axios
              // detailIndex
              .put(`/flower/update/${res.data.id}`, data2, {
                headers: { "Content-Type": "multipart/form-data" },
              })
              .then((res) => {
                console.log(res, "ok done vegitative/update");
              })
              .then((res) => {
                // setOpen(true);
                // setIsSubmitting(true);
              })
              .catch(
                (err) => (
                  // sendNotification({
                  //   msg: "There was an error1!",
                  //   variant: "error",
                  // }),
                  console.log(err.response, "err"),
                  console.log("err"),
                  console.log("err", res.data.id)
                )
              );
          }
          axios
            // detailIndex
            .put(
              `/updateStatus/${lotsId}`,
              // status
              { status: "Flower" },
              {
                headers: { "Content-Type": "application/json" },
              }
            )
            .then((res) => {
              console.log(res, "ok done");
            })
            .then((res) => {
              setOpen(true);
              setIsSubmitting(true);
            })
            .catch(
              (err) => (
                sendNotification({
                  msg: "There was an error!",
                  variant: "error",
                }),
                console.log(err.response, "err"),
                console.log("err")
              )
            );
        }
        // resetForm()
      )
      .catch((error) => {
        console.log("There was an error!", error);
        sendNotification({ msg: "There was an error!", variant: "error" });

        for (var key of data2.entries()) {
          console.log(key[0] + ", " + key[1]);
        }
      });
    for (var key of data2.entries()) {
      console.log(key[0] + ", " + key[1]);
    }
  };

  // vegitative updating
  const Updating = (values) => {
    console.log("Updating");
    // const date = convert(values.trackTypeEndDate);
    // console.log(values.trackTypeEndDate);
    const Details = JSON.stringify({
      lotId: `${detailIndex}`,
      id: `${detailIndex}`,
      onSchedule: values.trackType === "true" ? 1 : 0,
      correctAmount: values.correctAmount === "true" ? 1 : 0,
      rootColor: values.rootColour,
      healthOfStem: values.stalkOrStemHealth,
      mildew: values.mildew === "true" ? 1 : 0,
      leavesColor: values.leafColor,
      phWater: values.phWater,
      soil: values.soil,
      sop: values.deviatingSOP === "true" ? 1 : 0,
      pistilsColor: values.colouPistils,
      tricholmsColor: values.Tricholms,
      nugsDensity: values.densityOfNugs,
      fanLeafHealth: values.fanLeafHealth,
      flowerColor: values.colourOfFlower,
      userName: values.username,
      userId: `${user.user.id}`,
      scheduleReason: values.noTracking,
      sopReason: values.yesSop,
      // filename: "image onnu",
    });
    console.log(Details, "DetailsDetailsDetails");

    axios
      // detailIndex
      .put(`/vegitative/` + DetailsData.vegId, Details, {
        headers: { "Content-Type": "application/json" },
        onUploadProgress: (data) => {
          //Set the progress value to show the progress bar
          setProgress(Math.round((100 * data.loaded) / data.total));
        },
      })
      .then(
        (res) => (
          setIsSubmitting(true),
          setOpen(true),
          console.log(res.data, "germinationData")
        )
      )
      .catch((error) => {
        console.log("There was an error!", error);
        sendNotification({ msg: "There was an error!", variant: "error" });

        for (var key of data2.entries()) {
          console.log(key[0] + ", " + key[1]);
        }
      });
    for (var key of data2.entries()) {
      console.log(key[0] + ", " + key[1]);
    }
  };
  // vegitative updating
  const Updating2 = (values) => {
    console.log("Updating");
    // const date = convert(values.trackTypeEndDate);
    // console.log(values.trackTypeEndDate);
    const Details = JSON.stringify({
      lotId: `${detailIndex}`,
      id: `${detailIndex}`,
      onSchedule: values.trackType === "true" ? 1 : 0,
      correctAmount: values.correctAmount === "true" ? 1 : 0,
      rootColor: values.rootColour,
      healthOfStem: values.stalkOrStemHealth,
      mildew: values.mildew === "true" ? 1 : 0,
      leavesColor: values.leafColor,
      phWater: values.phWater,
      soil: values.soil,
      sop: values.deviatingSOP === "true" ? 1 : 0,
      pistilsColor: values.colouPistils,
      tricholmsColor: values.Tricholms,
      nugsDensity: values.densityOfNugs,
      fanLeafHealth: values.fanLeafHealth,
      flowerColor: values.colourOfFlower,
      userName: values.username,
      userId: `${user.user.id}`,
      scheduleReason: values.noTracking,
      sopReason: values.yesSop,
      // filename: "image onnu",
    });
    console.log(Details, "DetailsDetailsDetails");

    axios
      // detailIndex

      .put(`/flower/` + DetailsData.flowerId, Details, {
        headers: { "Content-Type": "application/json" },
        onUploadProgress: (data) => {
          //Set the progress value to show the progress bar
          setProgress(Math.round((100 * data.loaded) / data.total));
        },
      })
      .then(
        (res) => (
          setIsSubmitting(true),
          setOpen(true),
          console.log(res.data, "germinationData")
        )
      )
      .catch((error) => {
        console.log("There was an error!", error);
        sendNotification({ msg: "There was an error!", variant: "error" });

        for (var key of data2.entries()) {
          console.log(key[0] + ", " + key[1]);
        }
      });
    for (var key of data2.entries()) {
      console.log(key[0] + ", " + key[1]);
    }
  };
  const onImageClick = () => {
    // console.log(SelectedImageSrc);
    // console.log(axios.defaults.baseURL);
    window.open(
      SelectedImageSrc
        ? `${axios.defaults.baseURL}images/${SelectedImageSrc}`
        : ""
    );
  };
  const onPdfClick = () => {
    // console.log(SelectedImageSrc);
    // console.log(axios.defaults.baseURL);
    window.open(
      SelectedImageSrc ? `${axios.defaults.baseURL}files/${SelectedPdfSrc}` : ""
    );
  };
  const DeleteImages = (e) => {
    console.log(e);
  };

  const addTextfeildF = () => {
    console.log("rrr");
    setAddTextFeild(true);
  };
  const addTextfeildOff = () => {
    console.log("uuuu");

    setAddTextFeild(false);
  };
  const addTextfeildF2 = () => {
    console.log("rrr");
    setAddTextFeild2(true);
  };
  const addTextfeildOff2 = () => {
    console.log("uuuu");
    setAddTextFeild2(false);
  };
  const AddNutInput = () => {
    if (!DetailsData) {
      setNutritionArray([
        ...NutritionArray,
        {
          // newData: "",
          name: "",
          description: "",
          amount: "",
          date: "2002-02-22",
          time: "",
        },
      ]);
    }
  };
  const handleNutritionArrayChange = (e, index) => {
    // NutritionArray[index] = e.target.value;
    // setNutritionArray((...NutritionArray = e.target.value));
    // function FormDataMock() {
    //   this.append = jest.fn();
    // }

    // global.FormData = FormDataMock;
    const { name, value } = e.target;
    const list = [...NutritionArray];
    list[index][name] = value;
    setNutritionArray(list);
  };
  const handleClose = () => {
    setOpen(false);
  };
  console.log(1 < Progress && Progress != 100, Progress, "processs");
  return (
    <Form onSubmit={handleSubmit}>
      <div className={classes.TableCol}>
        <div>
          {/* {JSON.stringify(DetailsData)} */}
          <div>
            <Controls.Select
              name="trackType"
              placeholder="Are You On Track?"
              value={values.trackType}
              onChange={handleInputChange}
              options={YesNo}
              error={errors.trackType}
              // disabled={(DetailsData ? true : false) || IsSubmitting}
              // required="required"
            />
          </div>
          {/* values.trackType:: {JSON.stringify(values.trackType)} */}
          {values.trackType === false && (
            <div>
              <Controls.Input
                // disabled={(DetailsData ? true : false) || IsSubmitting}
                name="noTracking"
                label="Reason ?"
                value={values.noTracking}
                onChange={handleInputChange}
                error={errors.noTracking}
                maxWidth="186px !important"
              />
            </div>
          )}
          {DetailsData && values.trackType == "No" && (
            <div>
              <Controls.Input
                // disabled={DetailsData ? true : false}
                name="noTracking"
                label="Reason ?"
                value={values.noTracking}
                onChange={handleInputChange}
                error={errors.noTracking}
                maxWidth="186px !important"
              />
            </div>
          )}
          <div>
            {/* {values.correctAmount} */}
            <Controls.Select
              placeholder="Is Expected Weight Correct ?"
              name="correctAmount"
              label="Is Expected Weight Correct ?"
              value={values.correctAmount}
              onChange={handleInputChange}
              options={YesNo}
              error={errors.correctAmount}
              // disabled={(DetailsData ? true : false) || IsSubmitting}
              // required="required"
            />
          </div>
          {values.correctAmount === "false" && (
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Box sx={{ display: "flex" }}>
                <Controls.Input
                  // disabled={(DetailsData ? true : false) || IsSubmitting}
                  width="20px"
                  name="newWeight"
                  // label="Amount"
                  value={values.newWeight}
                  onChange={handleInputChange}
                  error={errors.newWeight}
                  type="number"
                />
                <FormControl
                  sx={{
                    m: 1,
                    minWidth: "50%",
                    minHeight: 68,
                    margin: 0,
                    display: "flex",
                    justyContent: "center",
                  }}
                >
                  <div
                    style={{
                      marginTop: "23px",
                      minWidth: "50px",
                      padding: "2px",
                    }}
                  >
                    <Controls.Select
                      width="80px !important"
                      name="weight"
                      placeholder="Amount"
                      // label="Amount"
                      value={values.weight}
                      onChange={handleInputChange}
                      options={AmountType}
                      error={errors.weight}
                      // disabled={(DetailsData ? true : false) || IsSubmitting}
                      paddingTop
                    />
                  </div>
                </FormControl>
              </Box>
            </Box>
          )}
        </div>
        {/* AddTextFeild */}
        {!harvest && (
          <div>
            <div>
              <Controls.Input
                // disabled={(DetailsData ? true : false) || IsSubmitting}
                name="rootColour"
                label="Root Health"
                value={values.rootColour}
                onChange={handleInputChange}
                error={errors.rootColour}
                required="required"
              />
            </div>
            <div>
              <Controls.Input
                // disabled={(DetailsData ? true : false) || IsSubmitting}
                name="stalkOrStemHealth"
                label="Stalk/Stem Health"
                value={values.stalkOrStemHealth}
                onChange={handleInputChange}
                error={errors.stalkOrStemHealth}
                required="required"
              />
            </div>
            <div>
              <Controls.Select
                name="mildew"
                placeholder="Mildew"
                value={values.mildew}
                onChange={handleInputChange}
                options={YesNo}
                error={errors.mildew}
                // disabled={(DetailsData ? true : false) || IsSubmitting}
                // required="required"
              />
            </div>
          </div>
        )}{" "}
        {!harvest && (
          <div>
            <div>
              <Controls.Select
                name="leafColor"
                placeholder="Leaf Colour"
                value={values.leafColor}
                onChange={handleInputChange}
                options={LeafColour}
                error={errors.leafColor}
                required="required"
                // disabled={(DetailsData ? true : false) || IsSubmitting}
              />
            </div>
            <div>
              <Controls.Input
                // disabled={(DetailsData ? true : false) || IsSubmitting}
                name="phWater"
                label="Ph Water"
                value={values.phWater}
                onChange={handleInputChange}
                error={errors.phWater}
                required="required"
              />
            </div>
            <div>
              <Controls.Input
                // disabled={(DetailsData ? true : false) || IsSubmitting}
                name="soil"
                label="Soil"
                value={values.soil}
                onChange={handleInputChange}
                error={errors.soil}
                required="required"
              />
            </div>
          </div>
        )}
        <div>
          {!harvest && (
            <div>
              <Controls.Select
                name="deviatingSOP"
                placeholder="Are You deviating from SOP?"
                value={values.deviatingSOP}
                onChange={handleInputChange}
                options={YesNo}
                error={errors.deviatingSOP}
                // disabled={(DetailsData ? true : false) || IsSubmitting}
                // required="required"
              />

              {values.deviatingSOP === true && (
                <div>
                  <Controls.Input
                    // disabled={(DetailsData ? true : false) || IsSubmitting}
                    name="yesSop"
                    label="Reason ?"
                    value={values.yesSop}
                    onChange={handleInputChange}
                    error={errors.yesSop}
                    maxWidth="186px !important"
                  />
                </div>
              )}
              {values.deviatingSOP === "yes" && (
                <div>
                  <Controls.Input
                    // disabled={DetailsData ? true : false}
                    name="yesSop"
                    label="Reason ?"
                    value={values.yesSop}
                    onChange={handleInputChange}
                    error={errors.yesSop}
                    maxWidth="186px !important"
                  />
                </div>
              )}
            </div>
          )}
          <div>
            <Controls.Input
              name="username"
              label="username"
              value={values.username}
              onChange={handleInputChange}
              error={errors.username}
              // disabled={(DetailsData ? true : false) || IsSubmitting}
              maxWidth="186px !important"
              required="required"
            />
          </div>{" "}
        </div>
      </div>
      {!harvest && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            fontWeight: 500,
          }}
        >
          <p
            style={{
              width: "230px",
              height: "44px",
              fontWeight: 700,
              margin: "0px",
              backgroundColor: "#0000000D",
              borderRadius: "32px",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              border: "1px solid #d9e1e7cc",
              margin: "12px",
              cursor: "pointer",
              padding: "12px",
            }}
            onClick={AddNutInput}
          >
            Add Another Nutrient
          </p>
        </div>
      )}
      {
        <div>
          {/* {progress && <ProgressBar now={progress} label={`${progress}%`} />} */}
          <br />
          {/* {JSON.stringify(1 < Progress < 99)} */}
          {1 < Progress && Progress != 100 && (
            <p style={{ margin: "-5px 4px -70px 168px" }}>
              <CircularProgress color="success" value={Progress} />
            </p>
          )}
          <div style={{ marginTop: "22px" }}>
            {SelectedImageSrc === "" && (
              <FileUploader
                // onChange={handleUploadChange}
                onChange={(event) => {
                  console.log(event.target.files[0]);
                  setSelectedImage(event.target.files[0]);
                  data.append("images", event.target.files[0]);
                }}
                name="fileUpload"
                id="fileUpload"
                accept="image/*"
                placeholder={
                  SelectedImage == null
                    ? Number(Progress) === 100
                      ? SelectedImage
                        ? SelectedImage.name
                        : "change image"
                      : " images"
                    : Number(Progress) === 100
                    ? SelectedImage
                      ? SelectedImage.name
                      : "change image"
                    : SelectedImage
                    ? SelectedImage.name
                    : " change images"
                }
              />
            )}
          </div>
          {SelectedImage && SelectedImage.length > 0 && (
            <div>
              {/* {Object.entries(SelectedImage).map(([item, it], i) => (
                <div className={classes.ImageNamesView}>
                  {it.name}
                  {/* <p onClick={() => DeleteImages(item, it)}>delete</p> */}
              {/* <p>{JSON.stringify(it.name)}</p> */}
              {/* </div> */}
              {/* // ))} */}
            </div>
          )}

          {SelectedImageSrc != "" && (
            <div className={classes.selectedImages}>
              <a download onClick={() => onImageClick()}>
                View Image
              </a>
            </div>
          )}
          {/* {setSelectedImageSrc} */}
          <div style={{ marginTop: "22px" }}>
            {Progress && SelectedImageSrc === "" && Progress != 100 && (
              <p style={{ margin: "-5px 4px -48px  168px" }}>
                <CircularProgress color="success" value={Progress} />
              </p>
            )}
            {SelectedPdfSrc === "" && (
              <FileUploader
                // onChange={handleUploadChange}
                onChange={(event) => {
                  console.log(event.target.files[0]);
                  setSelectedPdf(event.target.files[0]);
                  data2.append("filenames", event.target.files[0]);
                }}
                name="fileUpload2"
                id="fileUpload2"
                accept="application/pdf,application/vnd.ms-excel"
                placeholder={
                  SelectedPdf == null
                    ? Number(Progress) === 100
                      ? SelectedPdf
                        ? SelectedPdf.name
                        : "change pdf"
                      : " pdfs"
                    : Number(Progress) === 100
                    ? "change pdfs "
                    : SelectedPdf
                    ? SelectedPdf.name
                    : " change pdfs"
                }
              />
            )}
          </div>
          {SelectedPdf && SelectedPdf.length > 0 && (
            <div>
              {Object.entries(SelectedPdf).map(([item, it], i) => (
                <div className={classes.ImageNamesView}>
                  {it.name}
                  {/* <p>{JSON.stringify(it.name)}</p> */}
                </div>
              ))}
            </div>
          )}
          {SelectedPdfSrc != "" && (
            <div className={classes.selectedImages}>
              <a onClick={() => onPdfClick()}>View PDF </a>
            </div>
          )}
        </div>
      }
      {!harvest && (
        <div className={classes.TleCol2}>
          {NutritionArray &&
            NutritionArray.length > 0 &&
            NutritionArray.map((item, i) => (
              <div
                key={i}
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                  // width: "782px",
                }}
              >
                <div>
                  <Controls.Input
                    // disabled={(DetailsData ? true : false) || IsSubmitting}
                    // width="60px"
                    name="name"
                    id="NAName"
                    // label="Amount"
                    value={item.name}
                    onChange={(e) => handleNutritionArrayChange(e, i)}
                    // placeholder="new data"
                    label="Name"
                    maxWidth="130px !important"
                    // error={errors.newWeight}
                    // type="number"
                  />
                </div>
                <div>
                  <Controls.Input
                    maxWidth="130px !important"
                    // disabled={(DetailsData ? true : false) || IsSubmitting}
                    // width="60px"
                    name="description"
                    id="NADescription"
                    // label="Amount"
                    value={item.description}
                    onChange={(e) => handleNutritionArrayChange(e, i)}
                    // placeholder="new data"
                    label="Description"
                    // error={errors.newWeight}
                    // type="number"
                  />
                </div>
                <div>
                  <Controls.Input
                    maxWidth="130px !important"
                    // disabled={(DetailsData ? true : false) || IsSubmitting}
                    // width="60px"
                    name="amount"
                    id="NAAmount"
                    // label="Amount"
                    value={item.amount}
                    onChange={(e) => handleNutritionArrayChange(e, i)}
                    // placeholder="new data"
                    label="Amount"
                    // error={errors.newWeight}
                    // type="number"
                  />
                </div>
                <div>
                  <Controls.Input
                    maxWidth="130px !important"
                    // disabled={(DetailsData ? true : false) || IsSubmitting}
                    // width="60px"
                    name="time"
                    id="NATime"
                    // label="Amount"
                    value={item.time}
                    onChange={(e) => handleNutritionArrayChange(e, i)}
                    // placeholder="new data"
                    label="Time"
                    // error={errors.newWeight}
                    // type="number"
                  />
                </div>
                <div>
                  <Controls.DatePicker
                    maxWidth="130px !important"
                    name="date"
                    id="NADate"
                    label="Date"
                    value={item.date}
                    onChange={(e) => handleNutritionArrayChange(e, i)}
                    // disabled={(DetailsData ? true : false) || IsSubmitting}
                  />
                </div>
              </div>
            ))}
          {/* </div> */}
        </div>
      )}
      {/* floweronly */}
      <br />
      <br />
      <br />
      {floweronly && (
        <div className={classes.TableCol2}>
          <div className={classes.TableColrow}>
            <Controls.Input
              // disabled={DetailsData ? true : false}
              name="colouPistils"
              label="Colour of Pistils"
              value={values.colouPistils}
              onChange={handleInputChange}
              error={errors.colouPistils}
              required="required"
            />
          </div>
          <div className={classes.TableColrow}>
            <Controls.Input
              // disabled={DetailsData ? true : false}
              name="Tricholms"
              label="Colour of Tricholms"
              value={values.Tricholms}
              onChange={handleInputChange}
              error={errors.Tricholms}
              required="required"
            />
          </div>
          <div className={classes.TableColrow}>
            <Controls.Input
              // disabled={IsSubmitting || (DetailsData ? true : false)}
              name="densityOfNugs"
              label="Density of Nugs"
              value={values.densityOfNugs}
              onChange={handleInputChange}
              error={errors.densityOfNugs}
              required="required"
            />
          </div>
          <div className={classes.TableColrow}>
            <Controls.Input
              // disabled={IsSubmitting || (DetailsData ? true : false)}
              name="colourOfFlower"
              label="Colour of Flower"
              value={values.colourOfFlower}
              onChange={handleInputChange}
              error={errors.colourOfFlower}
              required="required"
            />
          </div>
          <div className={classes.TableColrow}>
            <Controls.Input
              // disabled={DetailsData ? true : false}
              name="fanLeafHealth"
              label="Fan Leaf Health"
              value={values.fanLeafHealth}
              onChange={handleInputChange}
              error={errors.fanLeafHealth}
              required="required"
            />
          </div>
        </div>
      )}
      <div className={classes.StartLotDiv}>
        <Controls.Button
          type="submit"
          text={DetailsData ? "Update Week" : "Complete Week"}
          className={classes.StartLot}
          color="#55815C"
          // disabled={
          //   ((DetailsData ? true : false) && !TimerDiable) || StateDisable
          // }
        >
          {/* <div className={classes.StartLot}></div> */}
        </Controls.Button>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        classes={{ paper: classes.DialogRoot }}
      >
        <form
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <DialogTitle
            sx={{
              margin: "0 important",
              padding: "0 important",
              color: "#3C9061",
              fontSize: "40px",
              margin: "0 auto",
            }}
          >
            Week
          </DialogTitle>
          <DialogContent sx={{ margin: "0 important", padding: "0 important" }}>
            <DialogContentText>
              {/* To subscribe to this website, please enter your email address
                here. We will send updates occasionally. */}
            </DialogContentText>

            <DialogContentText
              sx={{
                opacity: "1",
                paddingTop: "20px",
                textTransform: "capitalize",
                fontWeight: 600,
                color: "#000000",
                fontSize: "20px",
              }}
            >
              <p>
                {" "}
                Week Data Was
                {DetailsData ? " Updated " : "Created Successfully!"}
              </p>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              text="Cancel"
              onClick={handleClose}
              height="78px"
              radius="34px"
            >
              Cancel
            </Button>
            <Button text="Okay" height="78px" onClick={handleClose}>
              Okay
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </Form>
  );
}
