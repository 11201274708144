import React, { useState } from "react";
import Controls from "../../../../../core/formControl/Controls";
import { makeStyles } from "@mui/styles";
import { createStyles } from "@material-ui/core";
import axios from "../../../../../../axios";
import useNotification from "../../../../../core/snakeBar";
import { useForm, Form } from "../../../../../core/formControl/useForm";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "../../../../../core/Button";
import TwoInput from "../../../../../core/twoInput";

const useStyles = makeStyles(() =>
  createStyles({
    inputBox: {
      width: "278px",
    },
    selectAndInput: {
      //   backgroundColor: "blue",
      display: "flex",
      width: "300px",
    },
    bigBox: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-between",
      maxWidth: "1000px",
      margin: "0 auto",
    },
    Paras: {
      color: "#000000",
      letterSpacing: "0.26px",
      textAlign: "left",
      textTransform: "capitalize",
      marginLeft: "22px",
    },
    TableCol: {
      display: "flex",
      margin: "0 auto",
      justifyContent: "space-around",
      "& > :nth-child(n+1)": {
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "flex-end",
        flexDirection: "column",
      },
    },
    TableCol2: {
      display: "flex",
      flexWrap: "wrap",
      margin: "0 auto",
      justifyContent: "space-around",
    },
    StartLotDiv: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    StartLot: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "162px",
      height: "32px",
      background: "#55815C 0% 0% no-repeat padding-box",
      borderRadius: "8px",
      cursor: "pointer",
      color: "#fff",
    },
    AccordionRoot: {
      backgroundColor: "#6CD3A0",
      borderRadius: "16px !important ",
      border: " 2px solid #d9e1e7 !important",
      boxShadow: "none !important",
      position: "inherit !important",
    },
    Para: {
      color: "#809FB8",
      letterSpacing: "0.26px",
      textAlign: "left",
      marginBottom: "-25px",
    },
    DialogRoot: {
      padding: "3rem 4rem",
      width: "900px",
      background: "#FFFFFF 0% 0% no-repeat padding-box",
      boxShadow: "5px 5px 44px #00000029",
      borderRadius: "10px",
      opacity: "1",
    },
  })
);
const YesNo = [
  {
    id: "true",
    name: "yes",
  },
  {
    id: "false",
    name: "No",
  },
];
export default function CustomForm({
  detailIndex,
  DetailsData,
  summary,
  lotsId,
}) {
  // const [IsSubmitting, setIsSubmitting] = useState(false);

  console.log(detailIndex, DetailsData, "summary potancy 1");
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  var initialFValues = {
    Tthc: DetailsData ? DetailsData.TotalTHC_mg : "",
    Tcbd: DetailsData ? DetailsData.TotalCBD_mg : "",
    Tcbg: DetailsData ? DetailsData.TotalCBG_mg : "",
    Tcannabinoids: DetailsData ? DetailsData.TotalCannabinoids_mg : "",
    Tterpines: DetailsData ? DetailsData.TotalTerpines_mg : "",
    type: DetailsData ? DetailsData.Irradiated : "",

    Tthc_percentage: DetailsData ? DetailsData.TotalTHC_percentage : "",
    Tcbd_percentage: DetailsData ? DetailsData.TotalCBD_percentage : "",
    Tcbg_percentage: DetailsData ? DetailsData.TotalCBG_percentage : "",
    Tcannabinoids_percentage: DetailsData
      ? DetailsData.TotalCannabinoids_percentage
      : "",
    Tterpines_percentage: DetailsData
      ? DetailsData.TotalTerpines_percentage
      : "",
  };
  const [IsSubmitting, setIsSubmitting] = useState(false);

  const [, sendNotification] = useNotification();
  const classes = useStyles();
  console.log(DetailsData, detailIndex, "/=DetailsData");
  // const classes = useStyles();
  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("fullName" in fieldValues)
      temp.fullName = fieldValues.fullName ? "" : "This field is required.";
    if ("email" in fieldValues)
      temp.email = /$^|.+@.+..+/.test(fieldValues.email)
        ? ""
        : "Email is not valid.";
    if ("mobile" in fieldValues)
      temp.mobile =
        fieldValues.mobile.length > 9 ? "" : "Minimum 10 numbers required.";
    if ("departmentId" in fieldValues)
      temp.departmentId =
        fieldValues.departmentId.length !== 0 ? "" : "This field is required.";
    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, errors, setErrors, handleInputChange } = useForm(
    initialFValues,
    true,
    validate
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      console.log(values.finishDate, "values");
      if (DetailsData) {
        if (DetailsData.id) {
          Updating(values);
        } else {
          sendNotification({ msg: "There was an error!", variant: "error" });
        }
      } else {
        Post(values);
      }
    }
    console.log(values);
  };
  const Post = (values) => {
    const Details = JSON.stringify({
      lotId: `${detailIndex ? detailIndex : "2"}`,
      //   userId: `${user.user.id}`,
      // lotId: `${detailIndex ? detailIndex : "nolotid"}`,
      Irradiated: values.type,
      TotalTHC_mg: values.Tthc,
      TotalCBD_mg: values.Tcbd,
      TotalCBG_mg: values.Tcbg,
      TotalCannabinoids_mg: values.Tcannabinoids,
      TotalTerpines_mg: values.Tterpines,
      // TotalTHC_percentage: null,
      // TotalCBD_percentage: null,
      // TotalCBG_percentage: null,
      // TotalCannabinoids_percentage: null,
      // TotalTerpines_percentage: null,
      // lotId: 1,
      // Irradiated: null,
      // TotalTHC_mg: null,
      // TotalCBD_mg: null,
      // TotalCBG_mg: null,
      // TotalCannabinoids_mg: null,
      // TotalTerpines_mg: null,
      TotalTHC_percentage: values.Tthc_percentage,
      TotalCBD_percentage: values.Tcbd_percentage,
      TotalCBG_percentage: values.Tcbg_percentage,
      TotalCannabinoids_percentage: values.Tcannabinoids_percentage,
      TotalTerpines_percentage: values.Tterpines_percentage,
    });
    console.log(Details, "DetailsDetailsDetails");
    axios
      // detailIndex
      .post(`/potencySummary`, Details, {
        headers: { "Content-Type": "application/json" },
      })
      .then(
        (res) => {
          // setIsSubmitting(true),
          // setOpen(true),
          axios
            // detailIndex
            .put(
              `/updateStatus/${lotsId}`,
              // status
              { status: "Summary" },
              {
                headers: { "Content-Type": "application/json" },
              }
            )
            .then((res) => {
              console.log(res, "ok done");
            })
            .then((res) => {
              setOpen(true);
              setIsSubmitting(true);
            })
            .catch(
              (err) => (
                sendNotification({
                  msg: "There was an error!",
                  variant: "error",
                }),
                console.log(err.response, "err"),
                console.log("err")
              )
            );
          console.log(res.data, "response data of potencySummary");
        }
        // resetForm()
      )
      .catch((error) => {
        console.error("There was an error!", error);
        sendNotification({ msg: "There was an error!", variant: "error" });
      });
    console.log(Details, "/details");
  };
  const Updating = (values) => {
    const Details = JSON.stringify({
      lotId: `${detailIndex ? detailIndex : "0"}`,
      Irradiated: values.type,
      TotalTHC_mg: values.Tthc,
      TotalCBD_mg: values.Tcbd,
      TotalCBG_mg: values.Tcbg,
      TotalCannabinoids_mg: values.Tcannabinoids,
      TotalTerpines_mg: values.Tterpines,
      TotalTHC_percentage: values.Tthc_percentage,
      TotalCBD_percentage: values.Tcbd_percentage,
      TotalCBG_percentage: values.Tcbg_percentage,
      TotalCannabinoids_percentage: values.Tcannabinoids_percentage,
      TotalTerpines_percentage: values.Tterpines_percentage,
    });
    console.log(Details, "DetailsDetailsDetails");
    axios
      // detailIndex
      .put(`/potencySummary/` + DetailsData.id, Details, {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        setOpen(true);
        setIsSubmitting(true);
      })
      .catch((error) => {
        console.error("There was an error!", error);
        sendNotification({ msg: "There was an error!", variant: "error" });
      });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <div className={classes.bigBox}>
        <TwoInput
          hlabel="Total THC"
          name1="Tthc"
          label1="mg/g"
          value1={values.Tthc}
          name2="Tthc_percentage"
          // label2="%"
          value2={values.Tthc_percentage}
          onChange1={handleInputChange}
          onChange2={handleInputChange}
          // disabled1={(DetailsData ? true : false) || IsSubmitting}
        />

        <TwoInput
          hlabel="Total CBD"
          name1="Tcbd"
          label1="mg/g"
          value1={values.Tcbd}
          name2="Tcbd_percentage"
          // label2="%"
          value2={values.Tcbd_percentage}
          onChange1={handleInputChange}
          onChange2={handleInputChange}
        />
        <TwoInput
          hlabel="Total CBG"
          name1="Tcbg"
          label1="mg/g"
          value1={values.Tcbg}
          name2="Tcbg_percentage"
          // label2="%"
          value2={values.Tcbg_percentage}
          onChange1={handleInputChange}
          onChange2={handleInputChange}
        />

        <TwoInput
          hlabel="Total Cannabinoids"
          name1="Tcannabinoids"
          label1="mg/g"
          value1={values.Tcannabinoids}
          name2="Tcannabinoids_percentage"
          // label2="%"
          value2={values.Tcannabinoids_percentage}
          onChange1={handleInputChange}
          onChange2={handleInputChange}
        />
        <TwoInput
          hlabel="Total Terpines"
          name1="Tterpines"
          label1="mg/g"
          value1={values.Tterpines}
          name2="Tterpines_percentage"
          // label2="%"
          value2={values.Tterpines_percentage}
          onChange1={handleInputChange}
          onChange2={handleInputChange}
        />

        <div style={{ marginTop: "10px" }}>
          <Controls.Select
            placeholder="Irradiated ?"
            name="type"
            label="Type"
            value={values.type}
            onChange={handleInputChange}
            options={YesNo}
            error={errors.type}
            // disabled={(DetailsData ? true : false) || IsSubmitting}
            width="278px"
            // paddingTop="32px !important"
          />
        </div>
      </div>

      <div className={classes.StartLotDiv}>
        <Controls.Button
          type="submit"
          text="Complete Week"
          className={classes.StartLot}
          color="#55815C"
          // disabled={(DetailsData ? true : false) || IsSubmitting}
        ></Controls.Button>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        classes={{ paper: classes.DialogRoot }}
      >
        <form
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <DialogTitle
            sx={{
              padding: "0 important",
              color: "#3C9061",
              fontSize: "40px",
              margin: "0 auto",
            }}
          >
            Potancy
          </DialogTitle>
          <DialogContent sx={{ margin: "0 important", padding: "0 important" }}>
            <DialogContentText>
              {/* To subscribe to this website, please enter your email address
                here. We will send updates occasionally. */}
            </DialogContentText>

            <DialogContentText
              sx={{
                opacity: "1",
                paddingTop: "20px",
                textTransform: "capitalize",
                fontWeight: 600,
                color: "#000000",
                fontSize: "20px",
              }}
            >
              <p>
                Potancy Data Was
                {DetailsData ? " Updated " : "Created Successfully!"}
              </p>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              text="Cancel"
              onClick={handleClose}
              height="78px"
              radius="34px"
            >
              Cancel
            </Button>
            <Button text="Okay" height="78px" onClick={handleClose}>
              Okay
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </Form>
  );
}
