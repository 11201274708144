import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { createStyles } from "@material-ui/core";
// import Accordions from "../../../../core/accordionCore";
import AccordionsGrowth from "../../../../core/accordionGrowth";
import InputTable from "../weekTable/week1table";
import InputTable2 from "../weekTable/week2table";
import InputHarvestTable from "../weekTable/harvestTable";
import DialogComponenet from "../../../../core/dialog";
import InputTable3 from "../weekTable/curingTable";
// import PreviousLotDetails from "./previousLotDetails";
// import { PDFViewer } from "@react-pdf/renderer";

// import { PDFDownloadLink, Document, Page } from "@react-pdf/renderer";
// import PdfDocument from "../../../../../page/reportPage";
import ReactExport from "react-data-export";

// import Summary1 from "../weekTable/summary/analyteTable"; //future plan
import Summary2 from "../weekTable/summary/potancyTable";
// import Summary3 from "../weekTable/summary/terpenes"; //future plan
// import { CSVLink } from "react-csv";
import Button from "@mui/material/Button";
import { ReactComponent as Icon } from "../../../../../assect/svg/ICON.svg";
import moment from "moment";

const useStyles = makeStyles(() =>
  createStyles({
    TableCol: {
      display: "flex",
      margin: "0 auto",
      justifyContent: "space-between",
    },
    StartLotDiv: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
    },
    StartLot: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "162px",
      height: "32px",
      background: "#55815C 0% 0% no-repeat padding-box",
      borderRadius: "8px",
      cursor: "pointer",
      color: "#fff",
    },

    StartLot1: {
      color: "#fff",

      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "162px",
      height: "32px",
      background: "#55815C96 0% 0% no-repeat padding-box;",
      borderRadius: "8px",
      cursor: "pointer",
      margin: "0 10px 0 0px",
    },

    StartLot2: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "162px",
      height: "32px",
      background: "#68B174 0% 0% no-repeat padding-box",
      borderRadius: "8px",
      color: "#fff",
      cursor: "pointer",
    },
    AccordionRoot: {
      backgroundColor: "#6CD3A0",
      position: "inherit !important",
      borderRadius: "16px !important ",
      border: "none !important",
      boxShadow: "none !important",
    },
  })
);
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default function Germination(props) {
  const {
    DeatilsOpenHandleClose,
    detailIndex,
    DetailsData,
    DetailsDataLoading,
    GerminationData,
    CuringData,
    FloweringData,
    HarvestData,
    CurrentTableData,
    GerminationDatasInfos, //index of CurrentTableData,
    GerminationDatesInfos, // Germination Dates Infos
    PotancySum,
    AnalyteSum,
    TerpeneSum,
    TerpenePercentageSum,
    VegitativeNutArray2,
    VegitativeNutArray3,
    VegitativeNutArray4,
    VegitativeNutArray5,
    VegitativeNutArray6,
  } = props;
  console.log(
    VegitativeNutArray2,
    "VegitativeNutArray2",
    VegitativeNutArray3,
    " VegitativeNutArray3",
    VegitativeNutArray4,
    "VegitativeNutArray4",
    VegitativeNutArray5,
    "VegitativeNutArray5",
    VegitativeNutArray6,
    "VegitativeNutArray6"
  );

  // const [Length, setLength] = useState("");
  // console.log(
  //   detailIndex,
  //   FloweringData[0],
  //   CurrentTableData,
  //   "/CurrentTableDataCurrentTableData"
  // );
  console.log(
    PotancySum,
    "PotancySum",
    AnalyteSum,
    "AnalyteSum",
    TerpeneSum,
    "TerpeneSum",
    TerpenePercentageSum,
    "TerpenePercentageSum",
    "all summary"
  );

  const [Vegitative, setVegitative] = useState([
    {
      summary: "week 2",
      details: (
        <InputTable2
          vegitative
          week2only
          detailIndex={detailIndex}
          DetailsData={DetailsData[0]}
          summary="week 2"
          h1="h1"
          lotsId={GerminationData ? GerminationData[CurrentTableData].id : ""}
          status={
            GerminationData[CurrentTableData]
              ? GerminationData[CurrentTableData].status
              : "Germination"
          }
          // GerminationData[CurrentTableData].status
        />
      ),
    },
    {
      summary: "week 3",
      details: (
        <InputTable2
          vegitative
          detailIndex={detailIndex}
          DetailsData={DetailsData[1]}
          summary="week 3"
          lotsId={GerminationData ? GerminationData[CurrentTableData].id : ""}
          status={
            GerminationData[CurrentTableData]
              ? GerminationData[CurrentTableData].status
              : "Germination"
          }
        />
      ),
    },
    {
      summary: "week 4",
      details: (
        <InputTable2
          vegitative
          detailIndex={detailIndex}
          DetailsData={DetailsData[2]}
          summary="week 4"
          lotsId={GerminationData ? GerminationData[CurrentTableData].id : ""}
          status={
            GerminationData[CurrentTableData]
              ? GerminationData[CurrentTableData].status
              : "Germination"
          }
        />
      ),
    },
    {
      summary: "week 5",
      details: (
        <InputTable2
          vegitative
          detailIndex={detailIndex}
          DetailsData={DetailsData[3]}
          summary="week 5"
          lotsId={GerminationData ? GerminationData[CurrentTableData].id : ""}
          status={
            GerminationData[CurrentTableData]
              ? GerminationData[CurrentTableData].status
              : "Germination"
          }
        />
      ),
    },
    {
      summary: "week 6",
      details: (
        <InputTable2
          vegitative
          detailIndex={detailIndex}
          DetailsData={DetailsData[4]}
          summary="week 6"
          lotsId={GerminationData ? GerminationData[CurrentTableData].id : ""}
          status={
            GerminationData[CurrentTableData]
              ? GerminationData[CurrentTableData].status
              : "Germination"
          }
        />
      ),
    },
  ]);
  const classes = useStyles();
  const [Germination, setGermination] = useState([
    {
      // open: "true",
      summary: "week 01",
      details: (
        <InputTable
          GerminationData={GerminationData[CurrentTableData]}
          GerminationDatasInfos={GerminationDatasInfos}
          GerminationDatesInfos={GerminationDatesInfos}
          detailIndex={detailIndex}
          lotsId={GerminationData ? GerminationData[CurrentTableData].id : ""}
        />
      ),
    },
  ]);
  const [NestStateOpen, setNestStateOpen] = useState(false);

  const [expanded, setExpanded] = React.useState("panel1");

  const [NestVegStateOpen, setNestVegStateOpen] = useState(false);
  const [NestFlowerStateOpen, setNestFlowerStateOpen] = useState(false);
  const [NestHarvestStateOpen, setHarvestNestStateOpen] = useState(false);
  const [NestCuringStateOpen, setNestCuringStateOpen] = useState(false);
  const [NestSummaryStateOpen, setNestSummaryStateOpen] = useState(false);

  const [DeNestVegStateOpen, setDeNestVegStateOpen] = useState(true);
  const [DeNestFlowerStateOpen, setDeNestFlowerStateOpen] = useState(false);
  const [DeNestHarvestStateOpen, setDeHarvestNestStateOpen] = useState(false);
  const [DeNestCuringStateOpen, setDeNestCuringStateOpen] = useState(false);
  const [DeNestSummaryStateOpen, setDeNestSummaryStateOpen] = useState(false);

  useEffect(() => {
    if (GerminationData) {
      if (GerminationData[CurrentTableData].status === "Germination") {
        setNestVegStateOpen(false);
        setNestFlowerStateOpen(true);
        setHarvestNestStateOpen(true);
        setNestCuringStateOpen(true);
        setNestSummaryStateOpen(true);
      }
      if (GerminationData[CurrentTableData].status === "Vegitative") {
        setNestVegStateOpen(false);
        setNestFlowerStateOpen(false);
        setHarvestNestStateOpen(true);
        setNestCuringStateOpen(true);
        setNestSummaryStateOpen(true);
      }
      if (GerminationData[CurrentTableData].status === "Flower") {
        setNestVegStateOpen(false);
        setNestFlowerStateOpen(false);
        setHarvestNestStateOpen(false);
        setNestCuringStateOpen(true);
        setNestSummaryStateOpen(true);
      }
      if (GerminationData[CurrentTableData].status === "Harvest") {
        setNestVegStateOpen(false);
        setNestFlowerStateOpen(false);
        setHarvestNestStateOpen(false);
        setNestCuringStateOpen(false);
        setNestSummaryStateOpen(true);
      }
      if (GerminationData[CurrentTableData].status === "Curing") {
        setNestVegStateOpen(false);
        setNestFlowerStateOpen(false);
        setHarvestNestStateOpen(false);
        setNestCuringStateOpen(false);
        setNestSummaryStateOpen(false);
      }
      if (GerminationData[CurrentTableData].status === "Summary") {
        setNestVegStateOpen(false);
        setNestFlowerStateOpen(false);
        setHarvestNestStateOpen(false);
        setNestCuringStateOpen(false);
        setNestSummaryStateOpen(false);
      }
    }
  }, [GerminationData[CurrentTableData]]);

  const Flower = [
    {
      summary: "week 7",
      details: (
        <InputTable2
          floweronly
          detailIndex={detailIndex}
          DetailsData={FloweringData[0]}
          lotsId={GerminationData ? GerminationData[CurrentTableData].id : ""}
          status={
            GerminationData[CurrentTableData]
              ? GerminationData[CurrentTableData].status
              : "Germination"
          }
        />
      ),
    },
    {
      summary: "week 8",
      details: (
        <InputTable2
          floweronly
          detailIndex={detailIndex}
          DetailsData={FloweringData[1]}
          lotsId={GerminationData ? GerminationData[CurrentTableData].id : ""}
          status={
            GerminationData[CurrentTableData]
              ? GerminationData[CurrentTableData].status
              : "Germination"
          }
        />
      ),
    },
    {
      summary: "week 9",
      details: (
        <InputTable2
          floweronly
          detailIndex={detailIndex}
          DetailsData={FloweringData[2]}
          lotsId={GerminationData ? GerminationData[CurrentTableData].id : ""}
          status={
            GerminationData[CurrentTableData]
              ? GerminationData[CurrentTableData].status
              : "Germination"
          }
        />
      ),
    },
    {
      summary: "week 10",
      details: (
        <InputTable2
          floweronly
          detailIndex={detailIndex}
          DetailsData={FloweringData[3]}
          lotsId={GerminationData ? GerminationData[CurrentTableData].id : ""}
          status={
            GerminationData[CurrentTableData]
              ? GerminationData[CurrentTableData].status
              : "Germination"
          }
        />
      ),
    },
    {
      summary: "week 11",
      details: (
        <InputTable2
          floweronly
          detailIndex={detailIndex}
          DetailsData={FloweringData[4]}
          lotsId={GerminationData ? GerminationData[CurrentTableData].id : ""}
          status={
            GerminationData[CurrentTableData]
              ? GerminationData[CurrentTableData].status
              : "Germination"
          }
        />
      ),
    },
    {
      summary: "week 12",
      details: (
        <InputTable2
          floweronly
          detailIndex={detailIndex}
          DetailsData={FloweringData[5]}
          lotsId={GerminationData ? GerminationData[CurrentTableData].id : ""}
          status={
            GerminationData[CurrentTableData]
              ? GerminationData[CurrentTableData].status
              : "Germination"
          }
        />
      ),
    },
  ];
  const Harvest = [
    {
      summary: "week 13",
      details: (
        <InputHarvestTable
          harvest
          detailIndex={detailIndex}
          DetailsData={HarvestData[0]}
          lotsId={GerminationData ? GerminationData[CurrentTableData].id : ""}
          status={
            GerminationData[CurrentTableData]
              ? GerminationData[CurrentTableData].status
              : "Germination"
          }
        />
      ),
    },
  ];
  const Curing = [
    {
      summary: "week 14",
      details: (
        <InputTable3
          DetailsData={CuringData[0]}
          detailIndex={detailIndex}
          lotsId={GerminationData ? GerminationData[CurrentTableData].id : ""}
          status={
            GerminationData[CurrentTableData]
              ? GerminationData[CurrentTableData].status
              : "Germination"
          }
        />
      ),
    },
  ];
  // const Package = [
  //   {
  //     summary: "week ",
  //     details: <InputTable2 />,
  //   },
  // ];
  // const Shipping = [
  //   {
  //     summary: "week ",
  //     details: <InputTable2 />,
  //   },
  // ];
  // const Retailer = [
  //   {
  //     summary: "week",
  //     details: <InputTable2 />,
  //   },
  // ];
  // const Consumer = [
  //   {
  //     summary: "week 2",
  //     details: <InputTable2 />,
  //   },
  //   {
  //     summary: "week 3",
  //     details: <InputTable2 />,
  //   },
  // ];
  const AddAnotherGrowth1 = () => {
    setGermination((prevState) => [
      ...prevState,
      {
        summary: "week 1",
        details: <InputTable />,
      },
    ]);
  };
  const AddAnotherGrowth2 = () => {
    setVegitative((prevState) => [
      ...prevState,
      {
        summary: "new Week",
        details: <InputTable2 />,
      },
    ]);
  };
  // const DetailsOfXLR = [
  //   {
  //     name: "Josef",
  //     amount: 450500,
  //     sex: "M",
  //     is_married: true,
  //   },
  // ];
  const Summary = [
    // {
    //   summary: "Analyte",
    //   details: (
    //     <Summary1
    //       detailIndex={detailIndex}
    //       DetailsData={AnalyteSum[0]}
    //       lotsId={GerminationData ? GerminationData[CurrentTableData].id : ""}
    //     />
    //   ),
    // },
    {
      summary: "Potency",
      details: (
        <Summary2
          detailIndex={detailIndex}
          DetailsData={PotancySum[0]}
          lotsId={GerminationData ? GerminationData[CurrentTableData].id : ""}
        />
      ),
    },
    // {
    //   summary: "Terpene",
    //   details: (
    //     <Summary3
    //       detailIndex={detailIndex}
    //       DetailsData={TerpeneSum}
    //       DetailsData2={TerpenePercentageSum}
    //     />
    //   ),
    // },
  ];
  // const dataSet = [
  //   {
  //     user: { name: "Mehmet", surname: "Yılmaz" },
  //     period: "Eylül - Ekim",
  //   },

  //   {
  //     user: { name: "Semih", surname: "Yavuz" },
  //     period: "Haziran - Temmuz",
  //   },
  // ];
  // const Analyte = [
  //   {
  //     ana: [
  //       {
  //         name: "Johson",
  //         mg: "ok",
  //         percentage: "ok",
  //       },
  //       {
  //         name: "Johson2ok",
  //         mg: "ok",
  //         percentage: "ok",
  //       },
  //       {
  //         name: "Johson3ok",
  //         mg: "ok",
  //         percentage: "ok",
  //       },
  //     ],
  //   },
  // ];
  const Analyte2 = [
    {
      name: "Delta-9 THC",
      mg: "AnalyteSum.Delta_9THC_mg",
      percentage: AnalyteSum.Delta_9THC_percentage,
      na: "23",
    },
    {
      name: "CBD",
      mg: `${AnalyteSum.CBD_mg}`,
      na: "23",
      percentage: `${AnalyteSum.CBD_percentage}`,
    },
    {
      name: "CBG",
      mg: `${AnalyteSum.CBG_mg}`,
      na: "23",
      percentage: `${AnalyteSum.CBG_percentage}`,
    },
    {
      name: "CBDA",
      mg: `${AnalyteSum.CBDA_mg}`,
      na: "23",
      percentage: `${AnalyteSum.CBDA_percentage}`,
    },
    // 2
    {
      name: "CBC",
      mg: "AnalyteSum.CBC_mg",
      percentage: AnalyteSum.CBC_percentage,
    },
    {
      name: "THCV",
      mg: AnalyteSum.THCV_mg,
      percentage: AnalyteSum.THCV_percentage,
    },
    {
      name: "Delta-8 THC",
      mg: AnalyteSum.Delta_8THC_mg,
      percentage: AnalyteSum.Delta_8THC_percentage,
    },
    {
      name: "CBN",
      mg: AnalyteSum.CBN_mg,
      percentage: AnalyteSum.CBN_percentage,
    },
    //3
    {
      name: "CBGA",
      mg: AnalyteSum.CBGA_mg,
      percentage: AnalyteSum.CBGA_percentage,
    },
    {
      name: "CBDV",
      mg: AnalyteSum.CBDV_mg,
      percentage: AnalyteSum.CBDV_percentage,
    },
    {
      name: "THCA-A",
      mg: AnalyteSum.THCA_A_mg,
      percentage: AnalyteSum.THCA_A_percentage,
    },
  ];

  console.log(GerminationData[CurrentTableData], "csv data");
  const GerminationDataCSV = [
    {
      name: "Lot ID",
      mg: GerminationData[CurrentTableData].lotId,
    },
    {
      name: "Batch ID",
      mg: GerminationData[CurrentTableData].batchId,
    },
    {
      name: "Grower",
      mg: GerminationData[CurrentTableData].grower,
    },
    {
      name: "Mother Plant",
      mg: GerminationDatasInfos[0].motherPlantId,
    },
    {
      name: "Number of Clones",
      mg: GerminationDatasInfos[0].cloneNumbers,
    },
    {
      name: "Seed",
      mg: GerminationDatasInfos[0].seed,
    },
    {
      name: "Number of Seeds",
      mg: GerminationDatasInfos[0].seedNumbers,
    },
    {
      name: "Type",
      mg: GerminationDatasInfos[0].seedType,
    },
    {
      name: "Strain",
      mg: GerminationData[CurrentTableData].strainName,
    },
    // dates
    {
      name: "Growing Method",
      mg: GerminationDatasInfos[0].growingMethod,
    },
    {
      name: "Base Material",
      mg: GerminationDatasInfos[0].organicNutrition,
    },
    {
      name: "Actual Amount",
      mg: GerminationData[CurrentTableData].amount,
    },
    {
      name: "Actual Veg Date",
      mg: moment(GerminationDatasInfos[0].vegDate).format("YYYY-MM-DD"),
    },
    {
      name: "Actual Flower Date",
      mg: moment(GerminationDatasInfos[0].flowerDate).format("YYYY-MM-DD"),
    },
    {
      name: "Actual Harvest Date",
      mg: moment(GerminationDatasInfos[0].harvestDate).format("YYYY-MM-DD"),
    },
    {
      name: "Actual Curing Date",
      mg: moment(GerminationDatasInfos[0].curingDate).format("YYYY-MM-DD"),
    },
  ];

  const HarvestCSV = [
    {
      name: "Are You On Track ?",
      value: HarvestData[0]
        ? HarvestData[0].onSchedule === 1
          ? "yes"
          : "no"
        : "",
    },
    {
      name: "Is expected Weight Correct ?",
      value: HarvestData[0]
        ? HarvestData[0].correctAmount === 1
          ? "yes"
          : "no"
        : "",
    },
    {
      name: "Harvest Type",
      value: HarvestData[0] ? HarvestData[0].harvestType : "",
    },
    {
      name: "How much Biomass is removed ",
      value: HarvestData[0] ? HarvestData[0].biomassDestroy : "",
    },
    {
      name: "How much Biomass is removed Amount ",
      value: HarvestData[0] ? HarvestData[0].measure : "",
    },
    {
      name: "How did you destory biomass ?",
      value: HarvestData[0] ? HarvestData[0].biomassDestroy : "",
    },
    {
      name: "Dying Type",
      value: HarvestData[0] ? HarvestData[0].dryingType : "",
    },
    {
      name: "Temperature",
      value: HarvestData[0] ? "" : "",
    },
    {
      name: "Humidity",
      value: HarvestData[0] ? "" : "",
    },
    {
      name: "Sugar Leave Processing ?",
      value: HarvestData[0]
        ? HarvestData[0].correctAmount === 1
          ? "yes"
          : "no"
        : "",
    },
  ];

  const CuringDataCSV = [
    // CuringData[0]
    {
      name: "What is your Humidity Aid?",
      value: CuringData[0] ? CuringData[0].humidityAid : "",
    },
    {
      name: "What is your Curing Container?",
      value: CuringData[0] ? CuringData[0].curingContainer : "",
    },
    {
      name: "Finish Date",
      value: CuringData[0] ? CuringData[0].finishDate : "",
    },
  ];
  const handleChange = (panel) => (event, newExpanded) => {
    console.log("handleChange");
    setExpanded(newExpanded ? panel : false);
  };
  console.log(HarvestCSV, "csv data HarvestCSV");
  console.log(CuringDataCSV, "csv data CuringDataCSV");
  console.log(PotancySum.length, "csv data PotancySum[0]");

  // console.log(GerminationDataCSV, "csv data");
  // console.log(PotencyDataCSV, "csv data 2");
  // console.log(week02CSV, "csv data 2");
  const nextState2Change = () => {
    setExpanded("33");
  };
  const nextState3Change = () => {
    setExpanded("44");
  };
  const nextState4Change = () => {
    setExpanded("55");
  };
  const nextState5Change = () => {
    setExpanded("66");
  };
  const AllWeeks = [
    // week 02
    {
      week: "week 02",
      userName: DetailsData[0] ? DetailsData[0].userName : "",
      onTrack: DetailsData[0]
        ? DetailsData[0].onSchedule === 0
          ? "No"
          : "Yes"
        : "",
      why: DetailsData[0] ? DetailsData[0].scheduleReason : "",
      isCorrectWeight: DetailsData[0]
        ? DetailsData[0].correctAmount === 0
          ? "No"
          : "Yes"
        : "",
      newWeight: "",
      rootHealth: DetailsData[0] ? DetailsData[0].rootColor : "",
      Stalk: DetailsData[0] ? DetailsData[0].healthOfStem : "",
      mildew: DetailsData[0]
        ? DetailsData[0].mildew === 0
          ? "No"
          : "Yes"
        : "",
      leafColor: DetailsData[0] ? DetailsData[0].leavesColor : "",
      phWater: DetailsData[0] ? DetailsData[0].phWater : "",
      soil: DetailsData[0] ? DetailsData[0].soil : "",
      sop: DetailsData[0] ? (DetailsData[0].sop === 0 ? "No" : "Yes") : "",
      whySop: DetailsData[0] ? DetailsData[0].sopReason : "",
    },
    // week03
    {
      week: "week 03",
      userName: DetailsData[1] ? DetailsData[1].userName : "",
      onTrack: DetailsData[1]
        ? DetailsData[1].onSchedule === 0
          ? "No"
          : "Yes"
        : "",
      why: DetailsData[1] ? DetailsData[1].scheduleReason : "",
      isCorrectWeight: DetailsData[1]
        ? DetailsData[1].correctAmount === 0
          ? "No"
          : "Yes"
        : "",
      newWeight: "",
      rootHealth: DetailsData[1] ? DetailsData[1].rootColor : "",
      Stalk: DetailsData[1] ? DetailsData[1].healthOfStem : "",
      mildew: DetailsData[1]
        ? DetailsData[1].mildew === 0
          ? "No"
          : "Yes"
        : "",
      leafColor: DetailsData[1] ? DetailsData[1].leavesColor : "",
      phWater: DetailsData[1] ? DetailsData[1].phWater : "",
      soil: DetailsData[1] ? DetailsData[1].soil : "",
      sop: DetailsData[1] ? (DetailsData[1].sop === 0 ? "No" : "Yes") : "",
      whySop: DetailsData[1] ? DetailsData[1].sopReason : "",
    },
    // week 04
    {
      week: "week 04",
      userName: DetailsData[2] ? DetailsData[2].userName : "",
      onTrack: DetailsData[2]
        ? DetailsData[2].onSchedule === 0
          ? "No"
          : "Yes"
        : "",
      why: DetailsData[2] ? DetailsData[2].scheduleReason : "",
      isCorrectWeight: DetailsData[2]
        ? DetailsData[2].correctAmount === 0
          ? "No"
          : "Yes"
        : "",
      newWeight: "",
      rootHealth: DetailsData[2] ? DetailsData[2].rootColor : "",
      Stalk: DetailsData[2] ? DetailsData[2].healthOfStem : "",
      mildew: DetailsData[2]
        ? DetailsData[2].mildew === 0
          ? "No"
          : "Yes"
        : "",
      leafColor: DetailsData[2] ? DetailsData[2].leavesColor : "",
      phWater: DetailsData[2] ? DetailsData[2].phWater : "",
      soil: DetailsData[2] ? DetailsData[2].soil : "",
      sop: DetailsData[2] ? (DetailsData[2].sop === 0 ? "No" : "Yes") : "",
      whySop: DetailsData[2] ? DetailsData[2].sopReason : "",
    },
    // week 05

    {
      week: "week 05",
      userName: DetailsData[3] ? DetailsData[3].userName : "",
      onTrack: DetailsData[3]
        ? DetailsData[3].onSchedule === 0
          ? "No"
          : "Yes"
        : "",
      why: DetailsData[3] ? DetailsData[3].scheduleReason : "",
      isCorrectWeight: DetailsData[3]
        ? DetailsData[3].correctAmount === 0
          ? "No"
          : "Yes"
        : "",
      newWeight: "",
      rootHealth: DetailsData[3] ? DetailsData[3].rootColor : "",
      Stalk: DetailsData[3] ? DetailsData[3].healthOfStem : "",
      mildew: DetailsData[3]
        ? DetailsData[3].mildew === 0
          ? "No"
          : "Yes"
        : "",
      leafColor: DetailsData[3] ? DetailsData[3].leavesColor : "",
      phWater: DetailsData[3] ? DetailsData[3].phWater : "",
      soil: DetailsData[3] ? DetailsData[3].soil : "",
      sop: DetailsData[3] ? (DetailsData[3].sop === 0 ? "No" : "Yes") : "",
      whySop: DetailsData[3] ? DetailsData[3].sopReason : "",
    },
    // week6
    {
      week: "week 06",
      userName: DetailsData[4] ? DetailsData[4].userName : "",
      onTrack: DetailsData[4]
        ? DetailsData[4].onSchedule === 0
          ? "No"
          : "Yes"
        : "",
      why: DetailsData[4] ? DetailsData[4].scheduleReason : "",
      isCorrectWeight: DetailsData[4]
        ? DetailsData[4].correctAmount === 0
          ? "No"
          : "Yes"
        : "",
      newWeight: "",
      rootHealth: DetailsData[4] ? DetailsData[4].rootColor : "",
      Stalk: DetailsData[4] ? DetailsData[4].healthOfStem : "",
      mildew: DetailsData[4]
        ? DetailsData[4].mildew === 0
          ? "No"
          : "Yes"
        : "",
      leafColor: DetailsData[4] ? DetailsData[4].leavesColor : "",
      phWater: DetailsData[4] ? DetailsData[4].phWater : "",
      soil: DetailsData[4] ? DetailsData[4].soil : "",
      sop: DetailsData[4] ? (DetailsData[4].sop === 0 ? "No" : "Yes") : "",
      whySop: DetailsData[4] ? DetailsData[4].sopReason : "",
    },
    // flower week 07 FloweringData
    // week 07
    {
      week: "week 07",
      userName: FloweringData[0] ? FloweringData[0].userName : "",
      onTrack: FloweringData[0]
        ? FloweringData[0].onSchedule === 0
          ? "No"
          : "Yes"
        : "",
      why: FloweringData[0] ? FloweringData[0].scheduleReason : "",
      isCorrectWeight: FloweringData[0]
        ? FloweringData[0].correctAmount === 0
          ? "No"
          : "Yes"
        : "",
      newWeight: "",
      rootHealth: FloweringData[0] ? FloweringData[0].rootColor : "",
      Stalk: FloweringData[0] ? FloweringData[0].healthOfStem : "",
      mildew: FloweringData[0]
        ? FloweringData[0].mildew === 0
          ? "No"
          : "Yes"
        : "",
      leafColor: FloweringData[0] ? FloweringData[0].leavesColor : "",
      phWater: FloweringData[0] ? FloweringData[0].phWater : "",
      soil: FloweringData[0] ? FloweringData[0].soil : "",
      sop: FloweringData[0] ? (FloweringData[0].sop === 0 ? "No" : "Yes") : "",
      whySop: FloweringData[0] ? FloweringData[0].sopReason : "",
    },
    // week08
    {
      week: "week 08",
      userName: FloweringData[1] ? FloweringData[1].userName : "",
      onTrack: FloweringData[1]
        ? FloweringData[1].onSchedule === 0
          ? "No"
          : "Yes"
        : "",
      why: FloweringData[1] ? FloweringData[1].scheduleReason : "",
      isCorrectWeight: FloweringData[1]
        ? FloweringData[1].correctAmount === 0
          ? "No"
          : "Yes"
        : "",
      newWeight: "",
      rootHealth: FloweringData[1] ? FloweringData[1].rootColor : "",
      Stalk: FloweringData[1] ? FloweringData[1].healthOfStem : "",
      mildew: FloweringData[1]
        ? FloweringData[1].mildew === 0
          ? "No"
          : "Yes"
        : "",
      leafColor: FloweringData[1] ? FloweringData[1].leavesColor : "",
      phWater: FloweringData[1] ? FloweringData[1].phWater : "",
      soil: FloweringData[1] ? FloweringData[1].soil : "",
      sop: FloweringData[1] ? (FloweringData[1].sop === 0 ? "No" : "Yes") : "",
      whySop: FloweringData[1] ? FloweringData[1].sopReason : "",
    },
    // week 09
    {
      week: "week 09",
      userName: FloweringData[2] ? FloweringData[2].userName : "",
      onTrack: FloweringData[2]
        ? FloweringData[2].onSchedule === 0
          ? "No"
          : "Yes"
        : "",
      why: FloweringData[2] ? FloweringData[2].scheduleReason : "",
      isCorrectWeight: FloweringData[2]
        ? FloweringData[2].correctAmount === 0
          ? "No"
          : "Yes"
        : "",
      newWeight: "",
      rootHealth: FloweringData[2] ? FloweringData[2].rootColor : "",
      Stalk: FloweringData[2] ? FloweringData[2].healthOfStem : "",
      mildew: FloweringData[2]
        ? FloweringData[2].mildew === 0
          ? "No"
          : "Yes"
        : "",
      leafColor: FloweringData[2] ? FloweringData[2].leavesColor : "",
      phWater: FloweringData[2] ? FloweringData[2].phWater : "",
      soil: FloweringData[2] ? FloweringData[2].soil : "",
      sop: FloweringData[2] ? (FloweringData[2].sop === 0 ? "No" : "Yes") : "",
      whySop: FloweringData[2] ? FloweringData[2].sopReason : "",
    },
    // week 10

    {
      week: "week 10",
      userName: FloweringData[3] ? FloweringData[3].userName : "",
      onTrack: FloweringData[3]
        ? FloweringData[3].onSchedule === 0
          ? "No"
          : "Yes"
        : "",
      why: FloweringData[3] ? FloweringData[3].scheduleReason : "",
      isCorrectWeight: FloweringData[3]
        ? FloweringData[3].correctAmount === 0
          ? "No"
          : "Yes"
        : "",
      newWeight: "",
      rootHealth: FloweringData[3] ? FloweringData[3].rootColor : "",
      Stalk: FloweringData[3] ? FloweringData[3].healthOfStem : "",
      mildew: FloweringData[3]
        ? FloweringData[3].mildew === 0
          ? "No"
          : "Yes"
        : "",
      leafColor: FloweringData[3] ? FloweringData[3].leavesColor : "",
      phWater: FloweringData[3] ? FloweringData[3].phWater : "",
      soil: FloweringData[3] ? FloweringData[3].soil : "",
      sop: FloweringData[3] ? (FloweringData[3].sop === 0 ? "No" : "Yes") : "",
      whySop: FloweringData[3] ? FloweringData[3].sopReason : "",
    },
    // week 11
    {
      week: "week 11",
      userName: FloweringData[4] ? FloweringData[4].userName : "",
      onTrack: FloweringData[4]
        ? FloweringData[4].onSchedule === 0
          ? "No"
          : "Yes"
        : "",
      why: FloweringData[4] ? FloweringData[4].scheduleReason : "",
      isCorrectWeight: FloweringData[4]
        ? FloweringData[4].correctAmount === 0
          ? "No"
          : "Yes"
        : "",
      newWeight: "",
      rootHealth: FloweringData[4] ? FloweringData[4].rootColor : "",
      Stalk: FloweringData[4] ? FloweringData[4].healthOfStem : "",
      mildew: FloweringData[4]
        ? FloweringData[4].mildew === 0
          ? "No"
          : "Yes"
        : "",
      leafColor: FloweringData[4] ? FloweringData[4].leavesColor : "",
      phWater: FloweringData[4] ? FloweringData[4].phWater : "",
      soil: FloweringData[4] ? FloweringData[4].soil : "",
      sop: FloweringData[4] ? (FloweringData[4].sop === 0 ? "No" : "Yes") : "",
      whySop: FloweringData[4] ? FloweringData[4].sopReason : "",
    },
    // week 12
    {
      week: "week 12",
      userName: FloweringData[5] ? FloweringData[5].userName : "",
      onTrack: FloweringData[5]
        ? FloweringData[5].onSchedule === 0
          ? "No"
          : "Yes"
        : "",
      why: FloweringData[5] ? FloweringData[5].scheduleReason : "",
      isCorrectWeight: FloweringData[5]
        ? FloweringData[5].correctAmount === 0
          ? "No"
          : "Yes"
        : "",
      newWeight: "",
      rootHealth: FloweringData[5] ? FloweringData[5].rootColor : "",
      Stalk: FloweringData[5] ? FloweringData[5].healthOfStem : "",
      mildew: FloweringData[5]
        ? FloweringData[5].mildew === 0
          ? "No"
          : "Yes"
        : "",
      leafColor: FloweringData[5] ? FloweringData[5].leavesColor : "",
      phWater: FloweringData[5] ? FloweringData[5].phWater : "",
      soil: FloweringData[5] ? FloweringData[5].soil : "",
      sop: FloweringData[5] ? (FloweringData[5].sop === 0 ? "No" : "Yes") : "",
      whySop: FloweringData[5] ? FloweringData[5].sopReason : "",
    },
  ];
  const HarvestCuringReport = [
    // week 13 1 times
    {
      week: "week 13",
      userName: HarvestData[0] ? HarvestData[0].userName : "",
      harvestType: HarvestData[0] ? HarvestData[0].harvestType : "",
      biomassAmountRemoved: HarvestData[0] ? HarvestData[0].biomassMeasure : "",
      HowDidyouDestroy: HarvestData[0] ? HarvestData[0].biomassDestroy : "",
      dryingType: HarvestData[0] ? HarvestData[0].dryingType : "",
      Temprature: HarvestData[0] ? "" : "",
      Humidity: HarvestData[0] ? "" : "",
      sugarLeaveProcessing: HarvestData[0]
        ? HarvestData[0].sugarLeavingProcess
        : "",
      humidityAid: "",
      curingContainer: "",
    },
    {
      week: "week 13",
      userName: HarvestData[0] ? HarvestData[0].userName : "",
      harvestType: HarvestData[0] ? HarvestData[0].harvestType : "",
      biomassAmountRemoved: HarvestData[0] ? HarvestData[0].biomassMeasure : "",
      HowDidyouDestroy: HarvestData[0] ? HarvestData[0].biomassDestroy : "",
      dryingType: HarvestData[0] ? HarvestData[0].dryingType : "",
      Temprature: HarvestData[0] ? "" : "",
      Humidity: HarvestData[0] ? "" : "",
      sugarLeaveProcessing: HarvestData[0]
        ? HarvestData[0].sugarLeavingProcess
        : "",
      humidityAid: "",
      curingContainer: "",
    },
    {
      week: "week 13",
      userName: HarvestData[0] ? HarvestData[0].userName : "",
      harvestType: HarvestData[0] ? HarvestData[0].harvestType : "",
      biomassAmountRemoved: HarvestData[0] ? HarvestData[0].biomassMeasure : "",
      HowDidyouDestroy: HarvestData[0] ? HarvestData[0].biomassDestroy : "",
      dryingType: HarvestData[0] ? HarvestData[0].dryingType : "",
      Temprature: HarvestData[0] ? "" : "",
      Humidity: HarvestData[0] ? "" : "",
      sugarLeaveProcessing: HarvestData[0]
        ? HarvestData[0].sugarLeavingProcess
        : "",
      humidityAid: "",
      curingContainer: "",
    },
    {
      week: "week 13",
      userName: HarvestData[0] ? HarvestData[0].userName : "",
      harvestType: HarvestData[0] ? HarvestData[0].harvestType : "",
      biomassAmountRemoved: HarvestData[0] ? HarvestData[0].biomassMeasure : "",
      HowDidyouDestroy: HarvestData[0] ? HarvestData[0].biomassDestroy : "",
      dryingType: HarvestData[0] ? HarvestData[0].dryingType : "",
      Temprature: HarvestData[0] ? "" : "",
      Humidity: HarvestData[0] ? "" : "",
      sugarLeaveProcessing: HarvestData[0]
        ? HarvestData[0].sugarLeavingProcess
        : "",
      humidityAid: "",
      curingContainer: "",
    },
    {
      week: "week 13",
      userName: HarvestData[0] ? HarvestData[0].userName : "",
      harvestType: HarvestData[0] ? HarvestData[0].harvestType : "",
      biomassAmountRemoved: HarvestData[0] ? HarvestData[0].biomassMeasure : "",
      HowDidyouDestroy: HarvestData[0] ? HarvestData[0].biomassDestroy : "",
      dryingType: HarvestData[0] ? HarvestData[0].dryingType : "",
      Temprature: HarvestData[0] ? "" : "",
      Humidity: HarvestData[0] ? "" : "",
      sugarLeaveProcessing: HarvestData[0]
        ? HarvestData[0].sugarLeavingProcess
        : "",
      humidityAid: "",
      curingContainer: "",
    },
    {
      week: "week 13",
      userName: HarvestData[0] ? HarvestData[0].userName : "",
      harvestType: HarvestData[0] ? HarvestData[0].harvestType : "",
      biomassAmountRemoved: HarvestData[0] ? HarvestData[0].biomassMeasure : "",
      HowDidyouDestroy: HarvestData[0] ? HarvestData[0].biomassDestroy : "",
      dryingType: HarvestData[0] ? HarvestData[0].dryingType : "",
      Temprature: HarvestData[0] ? "" : "",
      Humidity: HarvestData[0] ? "" : "",
      sugarLeaveProcessing: HarvestData[0]
        ? HarvestData[0].sugarLeavingProcess
        : "",
      humidityAid: "",
      curingContainer: "",
    },
    {
      week: "week 13",
      userName: HarvestData[0] ? HarvestData[0].userName : "",
      harvestType: HarvestData[0] ? HarvestData[0].harvestType : "",
      biomassAmountRemoved: HarvestData[0] ? HarvestData[0].biomassMeasure : "",
      HowDidyouDestroy: HarvestData[0] ? HarvestData[0].biomassDestroy : "",
      dryingType: HarvestData[0] ? HarvestData[0].dryingType : "",
      Temprature: HarvestData[0] ? "" : "",
      Humidity: HarvestData[0] ? "" : "",
      sugarLeaveProcessing: HarvestData[0]
        ? HarvestData[0].sugarLeavingProcess
        : "",
      humidityAid: "",
      curingContainer: "",
    },
    // week 14
    {
      week: "week 14",
      humidityAid: CuringData[0] ? CuringData[0].humidityAid : "",
      curingContainer: CuringData[0] ? CuringData[0].curingContainer : "",
    },
  ];

  const Nutrientreport = [
    {
      week: "week 02",
      name: "",
      description: "",
      amount: "",
      time: "",
      date: "",
    },
    {
      week: "week 02",
      name: "",
      description: "",
      amount: "",
      time: "",
      date: "",
    },
    {
      week: "week 02",
      name: "",
      description: "",
      amount: "",
      time: "",
      date: "",
    },
    {
      week: "week 02",
      name: "",
      description: "",
      amount: "",
      time: "",
      date: "",
    },
  ];
  // const sample = [
  //   {
  //     name: "name",
  //     description: "description",
  //     time: "time",
  //     amount: "",
  //     date: "",
  //   },
  //   {
  //     name: "name",
  //     description: "description",
  //     time: "time",
  //     amount: "",
  //     date: "",
  //   },
  // ];
  const sample2 = VegitativeNutArray2;
  const sample3 = VegitativeNutArray3;
  const sample4 = VegitativeNutArray4;
  const sample5 = VegitativeNutArray5;
  const sample6 = VegitativeNutArray6;
  // const arrToObjects2 = Object.entries(sample2).map(([key, item]) => {
  //   return {
  //     ["week"]: `week 2`,
  //     ["name"]: item.name,
  //     ["description"]: item.description,
  //     ["time"]: item.time,
  //     ["amount"]: item.amount,
  //     ["date"]: item.date,
  //   };
  // });
  sample2.forEach((element) => {
    element.week = "week 2";
  });
  sample3.forEach((element) => {
    element.week = "week 3";
  });
  sample4.forEach((element) => {
    element.week = "week 4";
  });
  sample5.forEach((element) => {
    element.week = "week 5";
  });
  sample6.forEach((element) => {
    element.week = "week 6";
  });
  var final = [];
  final = final.concat(sample2, sample3, sample4, sample5, sample6);

  console.log(JSON.stringify(final), "sample final array3");
  const imageData = [
    {
      week: "week2",
      pdf: DetailsData[0]
        ? DetailsData[0].filenames
          ? DetailsData[0].filenames
          : "no pdf"
        : "",
      image: DetailsData[0]
        ? DetailsData[0].image
          ? DetailsData[0].image
          : "no image"
        : "",
    },
    {
      week: "week3",
      pdf: DetailsData[1]
        ? DetailsData[1].filenames
          ? DetailsData[1].filenames
          : "no pdf"
        : "",
      image: DetailsData[1]
        ? DetailsData[1].image
          ? DetailsData[1].image
          : "no image"
        : "",
    },
    {
      week: "week4",
      pdf: DetailsData[2]
        ? DetailsData[2].filenames
          ? DetailsData[2].filenames
          : "no pdf"
        : "",
      image: DetailsData[2]
        ? DetailsData[2].image
          ? DetailsData[2].image
          : "no image"
        : "",
    },
    {
      week: "week6",
      pdf: DetailsData[3]
        ? DetailsData[3].filenames
          ? DetailsData[3].filenames
          : "no pdf"
        : "",
      image: DetailsData[3]
        ? DetailsData[3].image
          ? DetailsData[3].image
          : "no image"
        : "",
    },
  ];

  // console.log(arrToObjects2);
  console.log(imageData, "AllWeek");
  return (
    <>
      {DetailsDataLoading && (
        <DialogComponenet
          classes={{ root: classes.AccordionRoot }}
          handleClose={DeatilsOpenHandleClose}
          title={`${
            GerminationData[CurrentTableData]
              ? GerminationData[CurrentTableData].lotId
                ? `Lot ID : ${GerminationData[CurrentTableData].lotId}`
                : "All Details"
              : "New Creation"
          }`}
        >
          {PotancySum[0] && PotancySum.length > 0 && (
            <ExcelFile
              filename={"ExcelExportExample"}
              element={
                <Button
                  variant="contained"
                  startIcon={<Icon style={{ fill: "#ffff" }} />}
                  sx={{
                    backgroundColor: "#3A36DB",
                    marginRight: "1rem",
                    height: "52px !important",
                    borderRadius: "16px",
                  }}
                  // onClick={PdfOPen}
                >
                  Dowload Now
                </Button>
              }
            >
              <ExcelSheet data={GerminationDataCSV} name="Germination Data">
                <ExcelColumn label="Name" value="name" />
                <ExcelColumn label="value" value="mg" />
              </ExcelSheet>
              <ExcelSheet data={final} name="Nutrient Summary Data">
                <ExcelColumn label="Weeks" value="week" />
                <ExcelColumn label="Name" value="name" />
                <ExcelColumn label="Value" value="description" />
                <ExcelColumn label="Time" value="time" />
                <ExcelColumn label="Amount" value="amount" />
                <ExcelColumn label="Date" value="date" />
              </ExcelSheet>
              <ExcelSheet data={imageData} name="Documents">
                <ExcelColumn label="Weeks" value="week" />
                <ExcelColumn label="Image" value="image" />
                <ExcelColumn label="Pdf" value="pdf" />
              </ExcelSheet>
              <ExcelSheet data={AllWeeks} name="Non Nutrient Summary">
                <ExcelColumn label="weeks" value="week" />
                <ExcelColumn label="Username" value="userName" />
                <ExcelColumn label="Are You On Track" value="onTrack" />
                <ExcelColumn label="Why" value="why" />
                <ExcelColumn
                  label="Is Expected Weight Correct?"
                  value="isCorrectWeight"
                />
                <ExcelColumn label="New Weight" value="newWeight" />
                <ExcelColumn label="Root Health" value="rootHealth" />
                <ExcelColumn label="Stalk/ Stem Health" value="Stalk" />
                <ExcelColumn label="mildew" value="mildew" />
                <ExcelColumn label="Leaf Color" value="leafColor" />
                <ExcelColumn label="PH Water" value="phWater" />
                <ExcelColumn label="Soil" value="soil" />
                <ExcelColumn label="Are you deviating from SOP?" value="sop" />
                <ExcelColumn label="Why ?" value="whySop" />
              </ExcelSheet>
              <ExcelSheet
                data={HarvestCuringReport}
                name="Harvest Curing Report"
              >
                <ExcelColumn label="Week" value="week" />
                <ExcelColumn label="username" value="userName" />
                <ExcelColumn label="Harvest Type" value="harvestType" />
                <ExcelColumn
                  label="Biomass Amount Removed"
                  value="biomassAmountRemoved"
                />
                <ExcelColumn
                  label="How did you destroy"
                  value="HowDidyouDestroy"
                />
                <ExcelColumn label="Drying Type" value="dryingType" />
                <ExcelColumn label="Temprature" value="Temprature" />
                <ExcelColumn label="Humidity" value="Humidity" />
                <ExcelColumn
                  label="Sugar Leave Processing"
                  value="sugarLeaveProcessing"
                />
                <ExcelColumn label="Humidity Aid" value="humidityAid" />
                <ExcelColumn label="Curing Container" value="curingContainer" />
              </ExcelSheet>
            </ExcelFile>
          )}
          <AccordionsGrowth
            summary="Germination"
            detailData={Germination}
            AddAnotherWeek={AddAnotherGrowth1}
            noAddWeek="1stweek"
            handleChange={handleChange("11")}
            expandeds="11"
            expanded={expanded}
          />
          <>
            <AccordionsGrowth
              summary="Vegitative"
              detailData={Vegitative}
              AddAnotherWeek={AddAnotherGrowth2}
              nextStateChange={() => nextState2Change()}
              open={NestVegStateOpen}
              // deopen={DeNestVegStateOpen}
              handleChange={handleChange("22")}
              expandeds="22"
              expanded={expanded}
            />
            <AccordionsGrowth
              summary="Flower"
              detailData={Flower}
              open={NestFlowerStateOpen}
              // deopen={DeNestFlowerStateOpen}
              handleChange={handleChange("33")}
              expandeds="33"
              expanded={expanded}
              nextStateChange={() => nextState3Change()}
            />
            <AccordionsGrowth
              summary="Harvest"
              detailData={Harvest}
              open={NestHarvestStateOpen}
              // deopen={DeNestHarvestStateOpen}
              handleChange={handleChange("44")}
              expandeds="44"
              expanded={expanded}
              nextStateChange={() => nextState4Change()}
            />
            <AccordionsGrowth
              summary="Curing"
              detailData={Curing}
              open={NestCuringStateOpen}
              // deopen={DeNestCuringStateOpen}
              handleChange={handleChange("55")}
              expandeds="55"
              expanded={expanded}
              nextStateChange={() => nextState5Change()}
            />
            {/* <AccordionsGrowth summary="Package" detailData={Package} />
                  <AccordionsGrowth summary="Shipping" detailData={Shipping} />
                  <AccordionsGrowth summary="Retailer" detailData={Retailer} />
                  <AccordionsGrowth summary="Consumer" detailData={Consumer} />
             */}
            <AccordionsGrowth
              summary="Summary"
              detailData={Summary}
              noAddWeek
              open={NestSummaryStateOpen}
              // deopen={DeNestSummaryStateOpen}
              handleChange={handleChange("66")}
              expandeds="66"
              expanded={expanded}
            />
          </>

          {/* {GerminationData &&
          GerminationData[CurrentTableData].status === "Curing" ? (
            <></>
          ) : (
            <></>
          )}
          {GerminationData &&
          GerminationData[CurrentTableData].status === "Summary" ? (
            <></>
          ) : (
            <></>
          )} */}
        </DialogComponenet>
      )}
    </>
  );
}
