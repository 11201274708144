import React, { useEffect, useState, useContext } from "react";
import { makeStyles } from "@mui/styles";
import { createStyles } from "@material-ui/core";
// import InputTable from "../weekTable/week1table";
// import InputTable2 from "../weekTable/week2table";
// import DialogComponenet from "../../../../core/dialog";
import axios from "../../../../axios";
import CurrentDetailApi from "./previousDetailsApi";
import CircularProgress from "@mui/material/CircularProgress";
import { AuthContext } from "../../../../state/Auth";
// import { actionType } from "../../../../../state/Authreducer";

const useStyles = makeStyles(() =>
  createStyles({
    TableCol: {
      display: "flex",
      margin: "0 auto",
      justifyContent: "space-between",
    },
    StartLotDiv: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
    },
    StartLot: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "162px",
      height: "32px",
      background: "#55815C 0% 0% no-repeat padding-box",
      borderRadius: "8px",
      cursor: "pointer",
      color: "#fff",
    },

    StartLot1: {
      color: "#fff",

      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "162px",
      height: "32px",
      background: "#55815C96 0% 0% no-repeat padding-box;",
      borderRadius: "8px",
      cursor: "pointer",
      margin: "0 10px 0 0px",
    },

    StartLot2: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "162px",
      height: "32px",
      background: "#68B174 0% 0% no-repeat padding-box",
      borderRadius: "8px",
      color: "#fff",
      cursor: "pointer",
    },
    AccordionRoot: {
      backgroundColor: "#6CD3A0",
      position: "inherit !important",
      borderRadius: "16px !important ",
      border: "none !important",
      boxShadow: "none !important",
    },
  })
);

export default function Germination(props) {
  const { DeatilsOpenHandleClose, detailIndex, CurrentTableData } = props;
  const [user, dispatch] = useContext(AuthContext);
  // const [Length, setLength] = useState("");
  // console.log(detailIndex, "/===");
  const [DetailsData, setDetailsData] = useState([]);
  const [VegitativeNutArray2, setVegitativeNutArray2] = useState([]);
  const [VegitativeNutArray3, setVegitativeNutArray3] = useState([]);
  const [VegitativeNutArray4, setVegitativeNutArray4] = useState([]);
  const [VegitativeNutArray5, setVegitativeNutArray5] = useState([]);
  const [VegitativeNutArray6, setVegitativeNutArray6] = useState([]);
  const [GerminationData, setGerminationData] = useState([]);
  const [GerminationDatasInfos, setGerminationDatasInfos] = useState([]);
  const [GerminationDatesInfos, setGerminationDatesInfos] = useState([]);
  const [CuringData, setCuringData] = useState([]);
  const [FloweringData, setFloweringData] = useState([]);
  const [HarvestData, setHarvestData] = useState([]);

  const [PotancySum, setPotancySum] = useState([]);
  const [AnalyteSum, setAnalyteSum] = useState([]);
  const [TerpeneSum, setTerpeneSum] = useState([]);
  const [TerpenePercentageSum, setTerpenePercentageSum] = useState([]);
  const [DetailsDataLoading, setDetailsDataLoading] = useState(false);
  const [VegitativeNutArrayLoading2, setVegitativeNutArrayLoading2] =
    useState(false);
  const [VegitativeNutArrayLoading3, setVegitativeNutArrayLoading3] =
    useState(false);
  const [VegitativeNutArrayLoading4, setVegitativeNutArrayLoading4] =
    useState(false);
  const [VegitativeNutArrayLoading5, setVegitativeNutArrayLoading5] =
    useState(false);
  const [VegitativeNutArrayLoading6, setVegitativeNutArrayLoading6] =
    useState(false);

  const [GDetailsDataLoading, setGDetailsDataLoading] = useState(false);
  const [CuringDataDataLoading, setCuringDataDataLoading] = useState(false);
  const [HarvestDataLoading, setHarvestDataLoading] = useState(false);
  const [GerminationDatasInfosGet, setGerminationDatasInfosGet] =
    useState(false);

  const [PotancySumLoading, setPotancySumLoading] = useState(false);
  const [AnalyteSumLoading, setAnalyteSumLoading] = useState(false);
  const [TerpeneSumLoading, setTerpeneSumLoading] = useState(false);
  const [TerpenePercentageSumLoading, setTerpenePercentageSumLoading] =
    useState(false);

  // GerminationDatasInfos
  // const [DailyHarvestArray, setDailyHarvestArray] = useState(false);
  const [FloweringDataDataLoading, setFloweringDataDataLoading] =
    useState(false);
  useEffect(async () => {
    // console.log(detailIndex, "/indexnumber");
    const index = await CurrentTableData;

    await axios
      .get(`previousCultivations/${user.user ? user.user.id : ""}`)
      .then(async (res) => {
        setGerminationData(res.data);

        if (res.data && res.data[index]) {
          axios
            .get(`germination/${res.data[index].id}`)
            .then(async (res) => {
              setGerminationDatasInfos(res.data);
              setGerminationDatasInfosGet(true);
            })
            // .then(setDetailsDataLoading(true))
            .catch((err) => setGerminationDatasInfosGet(true));
          axios
            .get(`date/${res.data[index].id}`)
            .then((res) => {
              setGerminationDatesInfos(res.data);
              setGDetailsDataLoading(true);
            })
            // .then(setDetailsDataLoading(true))
            .catch((err) => setGDetailsDataLoading(true));
        } else {
        }
        const data = await res.data[index];
      })
      .catch((err) =>
        console.error(err.response, " previousCultivations /=error")
      );
  }, [detailIndex]);

  useEffect(() => {
    // console.log(detailIndex, "/indexnumber");
    axios
      .get(`vegitative/${user.user ? detailIndex + "/" + user.user.id : ""}`)
      .then((res) => {
        // console.log("test 01");
        const data = res.data;
        setDetailsData(res.data);
        setDetailsDataLoading(true);
        // console.log(res.data, "/indexnumber");
        // console.log(
        //   `vegitative/${detailIndex}/${user.user ? user.user.id : ""}`,
        //   data,
        //   "/=responseresponse"
        // );
        // console.log("ommmm", res.data, res.data[0].vegitativeId);
        // week 02
        // console.log("boolean11");
        if (res.data[0].vegitativeId) {
          // console.log("boolean22");

          axios
            .get(
              `nutritions/${
                res.data[0] ? res.data[0].vegitativeId : "novegitativeId"
              }`
            )
            .then((responce) => {
              const data = responce.data;
              // console.log(responce.data, "boolean11");
              setVegitativeNutArray2(responce.data);
              setVegitativeNutArrayLoading2(true);
            })
            .catch((err) =>
              setVegitativeNutArrayLoading2(true)
              // console.log(err, "boolean11")
            );
        } else {
          setVegitativeNutArrayLoading2(true);
        }
        // week 03
        if (res.data[1] && res.data[1].vegitativeId) {
          axios
            .get(
              `nutritions/${
                res.data[1] ? res.data[1].vegitativeId : "novegitativeId"
              }`
            )
            .then((responce) => {
              const data = responce.data;
              // console.log(responce.data, "okokokok33");
              setVegitativeNutArray3(responce.data);
              setVegitativeNutArrayLoading3(true);
            })
            .catch((err) => setVegitativeNutArrayLoading3(true));
        } else {
          setVegitativeNutArrayLoading3(true);
        }
        // week 04
        if (res.data[2] && res.data[2].vegitativeId) {
          axios
            .get(
              `nutritions/${
                res.data[2] ? res.data[2].vegitativeId : "novegitativeId"
              }`
            )
            .then((responce) => {
              const data = responce.data;
              setVegitativeNutArray4(responce.data);
              setVegitativeNutArrayLoading4(true);
            })
            .catch((err) => setVegitativeNutArrayLoading4(true));
        } else {
          setVegitativeNutArrayLoading4(true);
        }
        // week 05
        if (res.data[3] && res.data[3].vegitativeId) {
          axios
            .get(
              `nutritions/${
                res.data[3] ? res.data[3].vegitativeId : "novegitativeId"
              }`
            )
            .then((responce) => {
              const data = responce.data;
              setVegitativeNutArray5(responce.data);
              setVegitativeNutArrayLoading5(true);
            })
            .catch((err) => setVegitativeNutArrayLoading5(true));
        } else {
          setVegitativeNutArrayLoading5(true);
        }
        // week 06
        if (res.data[4] && res.data[4].vegitativeId) {
          axios
            .get(
              `nutritions/${
                res.data[4] ? res.data[4].vegitativeId : "novegitativeId"
              }`
            )
            .then((responce) => {
              const data = responce.data;
              setVegitativeNutArray6(responce.data);
              setVegitativeNutArrayLoading6(true);
            })
            .catch((err) => setVegitativeNutArrayLoading6(true));
        } else {
          setVegitativeNutArrayLoading6(true);
        }
      })
      // .then(setDetailsDataLoading(true))
      .catch((error) => {
        setVegitativeNutArrayLoading2(true);
        setVegitativeNutArrayLoading3(true);
        setVegitativeNutArrayLoading4(true);
        setVegitativeNutArrayLoading5(true);
        setVegitativeNutArrayLoading6(true);
        if (error.response) {
          if (error.response.data) {
            setDetailsDataLoading(true);
          }
        }
      });
  }, [detailIndex]);
  //curing
  useEffect(() => {
    axios
      .get(`curing/${user.user ? detailIndex + "/" + user.user.id : ""}`)
      // .get(`curing/${detailIndex}/${user.user ? user.user.id : ""}`)
      .then((res) => {
        const data = res.data;
        setCuringData(res.data);
        setCuringDataDataLoading(true);
        // console.log(res.data, "/curing");
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data) {
            setCuringDataDataLoading(true);
            // console.log("/=curingcuring");
          }
        }
      });
  }, [detailIndex]);
  //flowering
  useEffect(() => {
    axios
      .get(`flower/${user.user ? detailIndex + "/" + user.user.id : ""}`)
      // .get(`curing/${detailIndex}/${user.user ? user.user.id : ""}`)
      .then((res) => {
        const data = res.data;
        setFloweringData(res.data);
        setFloweringDataDataLoading(true);
      })
      .catch((error) => {
        if (error.response) {
          // console.log(error.response.data);
          if (error.response.data) {
            setFloweringDataDataLoading(true);
            // console.log("/=flowerflower");
          }
        }
      });
  }, [detailIndex]);
  // summary
  useEffect(() => {
    // analytes
    axios
      .get(`analytes/${user.user ? detailIndex : ""}`)
      // .get(`curing/${detailIndex}/${user.user ? user.user.id : ""}`)
      .then((res) => {
        const data = res.data;
        setAnalyteSum(res.data);
        setAnalyteSumLoading(true);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data) {
            setAnalyteSumLoading(true);
            // console.log("/=flowerflower");
          }
        }
      });
    // potencySummary
    axios
      .get(`potencySummary/${user.user ? detailIndex : ""}`)
      // .get(`curing/${detailIndex}/${user.user ? user.user.id : ""}`)
      .then((res) => {
        const data = res.data;
        setPotancySum(res.data);
        setPotancySumLoading(true);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data) {
            setPotancySumLoading(true);
            // console.log("/=flowerflower");
          }
        }
      });
    // terpene_mgs
    axios
      .get(`terpene_mgs/${user.user ? detailIndex : ""}`)
      // .get(`curing/${detailIndex}/${user.user ? user.user.id : ""}`)
      .then((res) => {
        const data = res.data;
        setTerpeneSum(res.data);
        setTerpeneSumLoading(true);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data) {
            setTerpeneSumLoading(true);
            // console.log("/=flowerflower");
          }
        }
      });
    // TerpenePercentageSum
    axios
      .get(`terpene_percentages/${user.user ? detailIndex : ""}`)
      // .get(`curing/${detailIndex}/${user.user ? user.user.id : ""}`)
      .then((res) => {
        const data = res.data;
        setTerpenePercentageSum(res.data);
        setTerpenePercentageSumLoading(true);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data) {
            setTerpenePercentageSumLoading(true);
            // console.log("/=flowerflower");
          }
        }
      });
  }, [detailIndex]);

  //harvest
  useEffect(() => {
    axios
      .get(`harvest/${user.user ? detailIndex + "/" + user.user.id : ""}`)
      // .get(`curing/${detailIndex}/${user.user ? user.user.id : ""}`)
      .then((res) => {
        const data = res.data;
        setHarvestData(res.data);
        setHarvestDataLoading(true);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data) {
            setHarvestDataLoading(true);
          }
        }
      });
  }, [detailIndex]);

  // console.log(DetailsDataLoading, "/=data about DetailsDataLoading");
  const classes = useStyles();
  // console.log(
  //   DetailsDataLoading,
  //   GDetailsDataLoading,
  //   VegitativeNutArrayLoading2,
  //   VegitativeNutArrayLoading3,
  //   VegitativeNutArrayLoading4,
  //   VegitativeNutArrayLoading5,
  //   VegitativeNutArrayLoading6,
  //   CuringDataDataLoading,
  //   GerminationDatasInfosGet,
  //   HarvestDataLoading,
  //   PotancySumLoading,
  //   AnalyteSumLoading,
  //   TerpeneSumLoading,
  //   TerpenePercentageSumLoading,
  //   FloweringDataDataLoading,
  //   "boolean"
  // );

  return (
    <>
      {DetailsDataLoading &&
      GDetailsDataLoading &&
      VegitativeNutArrayLoading2 &&
      VegitativeNutArrayLoading3 &&
      VegitativeNutArrayLoading4 &&
      VegitativeNutArrayLoading5 &&
      VegitativeNutArrayLoading6 &&
      CuringDataDataLoading &&
      GerminationDatasInfosGet &&
      HarvestDataLoading &&
      PotancySumLoading &&
      AnalyteSumLoading &&
      TerpeneSumLoading &&
      TerpenePercentageSumLoading &&
      FloweringDataDataLoading ? (
        <CurrentDetailApi
          detailIndex={detailIndex}
          DeatilsOpenHandleClose={DeatilsOpenHandleClose}
          DetailsData={DetailsData}
          DetailsDataLoading={DetailsDataLoading}
          GDetailsDataLoading={GDetailsDataLoading}
          GerminationData={GerminationData}
          CuringData={CuringData}
          FloweringData={FloweringData}
          HarvestData={HarvestData}
          CurrentTableData={CurrentTableData}
          GerminationDatasInfos={GerminationDatasInfos}
          GerminationDatesInfos={GerminationDatesInfos}
          PotancySum={PotancySum}
          AnalyteSum={AnalyteSum}
          TerpeneSum={TerpeneSum}
          TerpenePercentageSum={TerpenePercentageSum}
          VegitativeNutArray2={VegitativeNutArray2}
          VegitativeNutArray3={VegitativeNutArray3}
          VegitativeNutArray4={VegitativeNutArray4}
          VegitativeNutArray5={VegitativeNutArray5}
          VegitativeNutArray6={VegitativeNutArray6}
        />
      ) : (
        <div
          style={{
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress color="success" />
        </div>
      )}
    </>
  );
}
